import React from 'react';
import { graphql } from 'react-apollo';
import compose from 'lodash.flowright';
import { withRouter } from '../withRouter';
import { Navigate } from 'react-router';

// import Cookie from 'js-cookie';
// import moment from 'moment';

import SessionLoginMutation from '../../graphql/mutations/Session/Login';

import Login from '../../components/Login';

import LoadingPane from '../../components/Shared/LoadingPane';

class LoginContainer extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      email: '',
      error: null,
      loading: false,
      mobile: window.innerWidth < 1000,
      onload: true,
      password: '',
      authedUser: null,
    };
  }

  componentDidMount() {
    const { navigate, context, location, sessionLoginMutation } = this.props;
    if (context.isAuth()) {
      const user = context.getUser();
      if (user !== this.state.authedUser) {
        this.setState({ authedUser: user });
      }
    }

    const searchParams = new URLSearchParams(location.search);

    if (searchParams.has('e') && searchParams.has('p')) {
      const email = decodeURI(searchParams.get('e'));
      const password = searchParams.get('p');

      sessionLoginMutation({
        variables: {
          input: {
            email,
            password,
          },
        },
      }).then((response) => {
        const { user } = response.data.sessionLogin;

        if (user) {
          navigate(`/getting_started${location.search}`);
        } else {
          navigate(location.pathname);

          this.handleChange('onload', false);
        }
      });
    } else {
      this.handleChange('onload', false);

      window.addEventListener('resize', this.handleResize);
      window.addEventListener('keypress', this.handleKeyPress);
    }
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.handleResize);
    window.removeEventListener('keypress', this.handleKeyPress);
  }

  goToCreateAccount = () => {
    this.props.navigate('/register');
  };

  handleChange = (key, value) => {
    this.setState({
      [key]: value,
    });
  };

  handleLogin = () => {
    this.setState(
      {
        loading: true,
      },
      async () => this.login(),
    );
  };

  handleKeyPress = (e) => {
    if (e.keyCode === 13) this.handleLogin();
  };

  handleResize = () => {
    this.handleChange('mobile', window.innerWidth < 1000);
  };

  login = async () => {
    const { location, navigate, context } = this.props;
    const { email, password } = this.state;
    const { signin } = context;
    let from = location.state?.from?.pathname || '/';

    const sucCB = (user) => {
      if (from === '/') {
        from = `/organizations/${user.roles[0].org.id}/roster`;
      }
      navigate(from, { replace: true, state: { previous: location.pathname } });
    };

    const failCB = (loginMessage) => {
      this.setState({ error: loginMessage });
    };

    await signin(email, password, sucCB, failCB);
    this.setState({ loading: false });
  };

  render() {
    const { location } = this.props;
    const { authedUser } = this.state;
    const navigateTo = authedUser
      ? `/organizations/${authedUser.roles[0].org.id}/roster`
      : '';
    return this.state.onload ? (
      <LoadingPane />
    ) : authedUser ? (
      <Navigate to={navigateTo} state={{ from: location }} replace />
    ) : (
      <Login
        goToCreateAccount={this.goToCreateAccount}
        handleChange={this.handleChange}
        handleLogin={this.handleLogin}
        state={this.state}
      />
    );
  }
}

export default compose(
  graphql(SessionLoginMutation, {
    name: 'sessionLoginMutation',
  }),
)(withRouter(LoginContainer));
