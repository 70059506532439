import React from 'react';
import { graphql } from 'react-apollo';
import compose from 'lodash.flowright';

import _ from 'lodash';
import axios from 'axios';
import Cookie from 'js-cookie';

import UserQuery from '../../graphql/queries/User';
import UsersQuery from '../../graphql/queries/Users';
import UserFindOrCreateMutation from '../../graphql/mutations/User/FindOrCreate';
import UserUpdateMutation from '../../graphql/mutations/User/Update';
import UserRoleAddMutation from '../../graphql/mutations/User/Role/Add';
import UserRoleRemoveMutation from '../../graphql/mutations/User/Role/Remove';
import AttachmentCreateMutation from '../../graphql/mutations/Attachment/Create';
import AttachmentUpdateMutation from '../../graphql/mutations/Attachment/Update';

import User from '../../components/User';

import LoadingPane from '../../components/Shared/LoadingPane';
import { withRouter } from '../withRouter';

class UserContainer extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      create: false,
      invite: false,
      loading: false,
      onload: props.params.id !== 'create',
      roles: [],
    };
  }

  formatPhoneNumber = (str) => {
    if (str) {
      let cleaned = ('' + str).replace(/\D/g, ''),
        match = cleaned.match(/^(1|)?(\d{3})(\d{3})(\d{4})$/);

      if (match) {
        return ['(', match[2], ') ', match[3], '-', match[4]].join('');
      }
    }
    return null;
  };

  componentDidMount() {
    const {
      userQuery: { loading, refetch },
    } = this.props;
    const { onload } = this.state;

    if (onload && !loading) {
      refetch().then(() => this.setup());
    } else {
      this.setup();
    }
  }

  componentDidUpdate() {
    this.setup();
  }

  setup = () => {
    const {
      params,
      userQuery: { loading, user },
    } = this.props;
    const { onload } = this.state;

    if (onload && !loading) {
      this.setState({
        ...user,
        attachment: user ? user.attachment : null,
        create: !(user && user.id),
        onload: false,
        orgRole: this.props.context.user
          ? this.props.context.user.roles.find((r) => r.org.id === params.id)
          : null,
        role: user ? user.roles.find((r) => r.org.id === params.id) : null,
        phone: user ? this.formatPhoneNumber(user.phoneNumber) : null,
      });
    }
  };

  addRole = (user, success) => {
    const { userRoleAddMutation } = this.props;
    const { role } = this.state;

    userRoleAddMutation({
      variables: {
        input: {
          id: user.id,
          role: role.name,
          orgId: role.org.id,
        },
      },
    }).then((response) => {
      const {
        data: {
          userRoleAdd: { errors },
        },
      } = response;

      if (errors) {
        window.alert(errors[0].message);
      } else if (success) {
        success();
      }
    });
  };

  removeRole = (user, success) => {
    const { userRoleRemoveMutation } = this.props;
    const { orgRole: role } = this.state;

    userRoleRemoveMutation({
      variables: {
        input: {
          id: user.id,
          role: role.name,
          orgId: role.org.id,
        },
      },
    }).then((response) => {
      const {
        data: {
          userRoleRemove: { errors },
        },
      } = response;

      if (errors) {
        window.alert(errors[0].message);
      } else if (success) {
        success();
      }
    });
  };

  goToUsersRoute = () => {
    const { location, navigate, params } = this.props;

    if (location.state) {
      navigate(location.state.previous);
    } else {
      navigate(`/organizations/${params.id}/roster`);
    }
  };

  goToUserInviteRoute = () => {
    const { navigate, params } = this.props;

    navigate(`/organizations/${params.id}/roster/${this.state.id}/invite`);
  };

  handleChange = (key, value) => {
    this.setState({
      [key]: value,
    });
  };

  handleRole = (e) => {
    const {
      params: { id },
    } = this.props;

    const role = e.target.value;
    const defaultRole = {
      name: role,
      org: { id },
    };

    if (this.state.role) {
      this.setState(_.set(this.state, 'role.name', role));
    } else {
      this.handleChange('role', defaultRole);
    }
  };

  handleRedirect = () => {
    const { create } = this.state;

    if (create) {
      this.sendEmail();
    } else {
      this.goToUsersRoute();
    }
  };

  validateForm = (create) => {
    const { email, phone } = this.state;

    const emailRegex = new RegExp(
      /^([+\w-]+(?:\.[+\w-]+)*)@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$/i,
    );
    const phoneRegex = /^(\+\d{1,2}\s)?\(?\d{3}\)?[\s.-]\d{3}[\s.-]\d{4}$/i;

    let required = [];

    required = ['first name', 'last name', 'email', 'role'];

    const missing = required.filter((key) => !this.state[_.camelCase(key)]);

    const validEmail = emailRegex.test(email);
    const validPhone = phone ? phoneRegex.test(phone) : true;

    if (missing.length === 0 && validPhone && validEmail) {
      return true;
    } else {
      if (missing.length > 0) {
        window.alert(
          `Missing required ${
            missing.length === 1 ? 'field' : 'fields'
          }: ${_.upperFirst(missing.toString().replace(/,/g, ', '))}.`,
        );
      } else if (!validEmail) {
        window.alert('Email is not valid.');
      } else if (!validPhone) {
        window.alert('Phone number is not valid.');
      }
      return false;
    }
  };

  save = (invite) => {
    const { userFindOrCreateMutation, userUpdateMutation } = this.props;
    const {
      bio,
      create,
      email,
      file,
      firstName,
      homeStreet1,
      homeStreet2,
      homeCity,
      homeState,
      homeZip,
      id,
      lastName,
      phone,
      roles,
      role,
    } = this.state;

    if (this.validateForm(create)) {
      const data = {
        bio,
        email,
        firstName,
        lastName,
        homeStreet1,
        homeStreet2,
        homeCity,
        homeState,
        homeZip,
        phoneNumber: phone,
      };

      if (!id) {
        data.password = 'Welcome2020';
      }

      this.setState(
        {
          loading: true,
        },
        () => {
          if (create) {
            userFindOrCreateMutation({
              variables: {
                input: data,
              },
            }).then((response) => {
              const key = Object.keys(response.data)[0];
              const { errors, result, success } = response.data[key];

              if (success) {
                userUpdateMutation({
                  variables: {
                    input: {
                      id: result.id,
                      roles: _.uniqBy([...result.roles, role], 'org.id').map(
                        (r) => ({ role: r.name, orgId: r.org.id }),
                      ),
                    },
                  },
                }).then((response) => {
                  const { errors, result } = response.data.userUpdate;

                  if (result) {
                    if (file) {
                      this.saveAttachment(result, result.id, invite);
                    } else {
                      this.setState(
                        {
                          id: result.id,
                          invite,
                        },
                        () => this.handleRedirect(),
                      );
                    }
                  } else {
                    this.handleChange('loading', false);

                    window.alert(errors[0].message);
                  }
                });
              } else {
                this.handleChange('loading', false);

                window.alert(errors[0].message);
              }
            });
          } else {
            userUpdateMutation({
              variables: {
                input: {
                  id,
                  email,
                  firstName,
                  lastName,
                  phoneNumber: phone,
                  roles: _.uniqBy([...roles, role], 'org.id').map((r) => ({
                    role: r.name,
                    orgId: r.org.id,
                  })),
                },
              },
            }).then((response) => {
              const { errors, result } = response.data.userUpdate;

              if (result) {
                if (file) {
                  this.saveAttachment(result, id, invite);
                } else {
                  this.setState(
                    {
                      id: result.id,
                      invite,
                    },
                    () => this.handleRedirect(),
                  );
                }
              } else {
                this.handleChange('loading', false);

                window.alert(errors[0].message);
              }
            });
          }
        },
      );
    }
  };
  saveAttachment = (result, userId, invite) => {
    const { attachmentCreateMutation, attachmentUpdateMutation } = this.props;
    const { attachment, file } = this.state;

    const xhr = new XMLHttpRequest();
    const fileName = file.name;
    const config = {
      type: `image/${fileName.split('.')[1]}`,
    };
    const f = new File([file.base64], fileName, config);
    const formData = new FormData();

    formData.append('file', f);

    xhr.open('POST', process.env.REACT_APP_ATTACHMENT_URL);

    xhr.onreadystatechange = () => {
      if (xhr.readyState === 4) {
        const response = JSON.parse(xhr.response);
        const profileImage = attachment;
        const mutation = profileImage
          ? attachmentUpdateMutation
          : attachmentCreateMutation;
        const data = profileImage
          ? {
              id: profileImage.id,
              attachment: response,
              label: 'avatar',
            }
          : {
              attachment: response,
              userId,
              label: 'avatar',
            };

        mutation({
          variables: {
            input: data,
          },
        }).then((res) => {
          const key = Object.keys(res.data)[0];
          const response = res.data[key];

          if (response.success) {
            this.setState(
              {
                id: result.id,
                invite,
              },
              () => this.handleRedirect(),
            );
          } else {
            window.alert(response.errors[0].message);
            this.setState({
              loading: false,
            });
          }
        });
      }
    };

    xhr.send(formData);
  };

  saveRole = (user) => {
    const { orgRole, role } = this.state;

    if (role && !orgRole) {
      this.addRole(user, () => this.handleRedirect());
    } else if (orgRole && orgRole.name !== role.name) {
      this.addRole(user, () => {
        this.removeRole(user, () => this.handleRedirect());
      });
    } else {
      this.handleRedirect();
    }
  };

  sendEmail = () => {
    const { email, invite, orgRole, role } = this.state;
    const { attachment, brandColor } = orgRole.org;

    if (role.name !== 'nurse') {
      const url = `${process.env.REACT_APP_PROXY_URL}/send`;
      const defaultLogo = 'https://res.cloudinary.com/inewton/image/upload/v1/una-app/cache/e80cad79f0f6e17fd3c8254c763c2b8d.png';
      const defaultColor = '#FF5A5F';
      const data = {
        dynamicTemplateData: {
          email,
          password: 'Welcome2020',
          name: 'UNA Admin',
          url: `${
            process.env.REACT_APP_UNA_ADMIN_URL
          }/getting_started${`?e=${encodeURIComponent(email)}&p=Welcome2020`}`,
          color: attachment ? brandColor : defaultColor,
          logo: attachment ? attachment.url : defaultLogo,
        },
        environment: 'production',
        recipient: email,
        templateId: 'd-e98525431b064e7c9149e1d882dfe0e3',
      };
      const headers = {
        Authorization: Cookie.get(`${global.COOKIE_NAME}-token`),
      };

      axios.post(url, data, { headers }).then((response) => {
        if (response.status === 200) {
          if (invite) {
            this.goToUserInviteRoute();
          } else {
            this.goToUsersRoute();
          }
        } else {
          window.alert('Email invite unsuccessful.');
        }
      });
    } else if (invite) {
      this.goToUserInviteRoute();
    } else {
      this.goToUsersRoute();
    }
  };

  render() {
    return this.state.onload ? (
      <LoadingPane />
    ) : (
      <User
        goToUsersRoute={this.goToUsersRoute}
        handleChange={this.handleChange}
        handleRole={this.handleRole}
        save={this.save}
        state={this.state}
        user={this.props.context.user}
      />
    );
  }
}

export default compose(
  withRouter,
  graphql(UserQuery, {
    name: 'userQuery',
    options: (props) => ({
      variables: {
        id: props.params.user,
      },
    }),
  }),
  graphql(UsersQuery, { name: 'usersQuery' }),
  graphql(UserFindOrCreateMutation, {
    name: 'userFindOrCreateMutation',
  }),
  graphql(AttachmentCreateMutation, { name: 'attachmentCreateMutation' }),
  graphql(AttachmentUpdateMutation, { name: 'attachmentUpdateMutation' }),
  graphql(UserUpdateMutation, { name: 'userUpdateMutation' }),
  graphql(UserRoleAddMutation, {
    name: 'userRoleAddMutation',
  }),
  graphql(UserRoleRemoveMutation, { name: 'userRoleRemoveMutation' }),
)(UserContainer);
