import { gql } from 'apollo-boost';

const TestCategoryRemoveMutation = gql`
  mutation TestCategoryRemoveMutation($input: IDInput) {
    testCategoryRemove(input: $input) {
      errors {
        code
        message
      }
    }
  }
`;

export default TestCategoryRemoveMutation;
