import React from 'react';
import { OverlayTrigger, Popover } from 'react-bootstrap';

import Checkbox from '../Shared/Checkbox';
import LoadingPane from '../Shared/LoadingPane';
import Switch from '../Shared/Switch';
import Toolbar from '../Shared/Toolbar';

const QuestionChapter = (props) => {
  const {
    addOption,
    deleteOption,
    editOption,
    goToChapterRoute,
    handleChange,
    handleOption,
    handleOptionAction,
    save,
    state,
  } = props;
  const {
    answer,
    chapter,
    course,
    id,
    isRandomized,
    loading,
    options,
    rank,
    title,
  } = state;

  return (
    <div className="question-view full-screen-view">
      {loading ? (
        <LoadingPane style={{ background: 'rgba(0,0,0,0.1)' }} />
      ) : null}

      <Toolbar
        cancel={goToChapterRoute}
        title={id ? `Question ${rank || 'Create'}` : 'Create Question'}
        subTitle={`Course - ${course.title} | Chapter - ${chapter.title}`}
        save={save}
      />

      <div className="content-frame">
        <div className="centered-frame">
          <div className="section-frame">
            <div className="section-header-frame">
              <div className="section-header black">Question Details</div>
            </div>

            <div className="question-title-frame">
              <div className="header black">Question Title</div>

              <textarea
                autoFocus
                className="question-title"
                defaultValue={title}
                onFocus={(e) => {
                  e.target.value = '';
                  e.target.value = title;
                }}
                onChange={(e) => handleChange('title', e.target.value)}
                placeholder="Enter question title"
              />
            </div>

            {/* <div className='form-input-frame'>
                            <div className='input-title'>Linked Chapter for Reference</div>

                            <SelectMenu
                                className='form-menu'
                                defaultValue={chapter}
                                isClearable={true}
                                onChange={e => handleChange("chapter", e ? e.value : '')}
                                options={course.chapters.map(c => ({
                                    value: c.id,
                                    label: c.title
                                }))}
                                placeholder='Select Chapter'
                            />
                        </div> */}
          </div>

          <div className="section-frame">
            <div className="section-header-frame">
              <div className="section-header black">{`Answer Options (${options.length})`}</div>

              <div className="answer-options-frame">
                <div className="randomize-frame">
                  <Switch
                    defaultValue={isRandomized}
                    onChange={(checked) =>
                      handleChange('isRandomized', checked)
                    }
                  />

                  <div className="switch-label black">Randomize Answers</div>
                </div>

                <button className="add-option-button" onClick={addOption}>
                  + Add Option
                </button>
              </div>
            </div>

            {options.length > 0 ? (
              <div className="options-table-view">
                <div className="table-headers">
                  <div className="table-header option">Answer Option</div>
                  <div className="table-header name">Correct Answer</div>
                </div>

                <ul className="options-list-view">
                  {options.map((option, i) => {
                    const { edit, title } = option;

                    return edit ? (
                      <li className="option-cell edit" key={i}>
                        <input
                          autoFocus
                          className="option-input"
                          defaultValue={title}
                          onChange={(e) =>
                            handleOption('title', e.target.value, i)
                          }
                          placeholder="Enter answer option"
                        />

                        <div className="button-frame">
                          <button
                            className="cancel-button"
                            onClick={() => handleOptionAction(false, i)}
                          >
                            Cancel
                          </button>
                          <button
                            className={
                              title ? 'save-button' : 'save-button hint'
                            }
                            onClick={() =>
                              title ? handleOptionAction(true, i) : null
                            }
                          >
                            Save
                          </button>
                        </div>
                      </li>
                    ) : (
                      <li className="option-cell" key={i}>
                        <div className="option-label">{option.title}</div>

                        <Checkbox
                          checked={title === answer}
                          onChange={(checked) => {
                            if (checked) handleChange('answer', title);
                            else handleChange('answer', null);
                          }}
                        />

                        <OverlayTrigger
                          trigger="click"
                          placement="bottom"
                          rootClose
                          overlay={
                            <Popover
                              id="menu-pane"
                              className="menu-pane question"
                            >
                              <div
                                className="menu-content-view"
                                style={{ padding: '5px 0px' }}
                              >
                                <button
                                  className="menu-button teal"
                                  onClick={() => editOption(i)}
                                >
                                  Edit Title
                                </button>
                                <button
                                  className="menu-button red"
                                  onClick={() => deleteOption(i)}
                                >
                                  Delete
                                </button>
                              </div>
                            </Popover>
                          }
                        >
                          <div className="expander-icon">
                            <img
                              src={require('../../theme/images/expander-icon-grey-2x.png')}
                              alt="expander-icon"
                            />
                          </div>
                        </OverlayTrigger>
                      </li>
                    );
                  })}
                </ul>
              </div>
            ) : (
              <div className="empty-hint hint">No options have been added.</div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default QuestionChapter;
