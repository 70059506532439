import { gql } from 'apollo-boost';

const TestSummaryUpdateMutation = gql`
  mutation TestSummaryUpdateMutation($input: TestSummaryUpdateUpdateInput) {
    testSummaryUpdate(input: $input) {
      errors {
        code
        message
      }
    }
  }
`;

export default TestSummaryUpdateMutation;
