import { gql } from 'apollo-boost';

const TestSummaryCreateMutation = gql`
  mutation TestSummaryCreateMutation($input: TestSummaryCreateCreateInput) {
    testSummaryCreate(input: $input) {
      errors {
        code
        message
      }
      result {
        id
      }
    }
  }
`;

export default TestSummaryCreateMutation;
