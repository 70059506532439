import React from 'react';
import { OverlayTrigger, Popover } from 'react-bootstrap';

import _ from 'lodash';

import FormSelect from '../Shared/Form/Select';
import Toolbar from '../Shared/Toolbar';

import { roles as defaultRoles } from '../../utils/Constants';

const roleHash = {
  0: 'recruiter',
  1: 'nurse',
  2: 'admin',
  3: 'super_admin',
  4: 'corporate',
  recruiter: 0,
  nurse: 1,
  admin: 2,
  super_admin: 3,
  corporate: 4,
};

const ProfileEdit = (props) => {
  const {
    deleteRole,
    handleChange,
    save,
    saveRole,
    saveRoleEdit,
    resetUser,
    state,
  } = props;
  const {
    create,
    edit,
    email,
    firstName,
    lastName,
    orgs,
    orgId,
    phoneNumber,
    roles,
    role,
  } = state;

  const arr = roles.map((r) => roleHash[r.name]);
  const roleName = roleHash[Math.max(...arr)];

  return (
    <div className="profile-edit-view">
      <Toolbar
        cancel={() => {
          handleChange('isEdit', false);
          resetUser();
        }}
        title="Edit Profile"
        save={save}
      />

      <div className="content-frame scroll">
        <div className="centered-frame">
          <div className="section-header-frame">
            <div className="section-header">Account Details</div>
          </div>

          <div className="form-frame">
            <div className="form-input-frame">
              <div className="input-title">First Name</div>

              <input
                className="form-input"
                defaultValue={firstName}
                onChange={(e) => handleChange('firstName', e.target.value)}
                placeholder="First Name"
              />
            </div>

            <div className="form-input-frame middle">
              <div className="input-title">Last Name</div>

              <input
                className="form-input"
                defaultValue={lastName}
                onChange={(e) => handleChange('lastName', e.target.value)}
                placeholder="Last Name"
              />
            </div>

            <div className="form-input-frame">
              <div className="input-title">Email</div>

              <input
                className="form-input"
                defaultValue={email}
                onChange={(e) => handleChange('email', e.target.value)}
                placeholder="Email"
              />
            </div>

            <div className="form-input-frame">
              <div className="input-title">Phone</div>

              <input
                className="form-input"
                defaultValue={phoneNumber}
                onChange={(e) => {
                  const formatNumber = (num) => {
                    const cleaned = `${num}`.replace(/\D/g, '');
                    const match = cleaned.match(/^(\d{3})(\d{3})(\d{4})$/);

                    if (match) {
                      return `(${match[1]}) ${match[2]}-${match[3]}`;
                    }

                    return null;
                  };

                  const value = formatNumber(e.target.value);

                  if (value) {
                    e.target.value = value;
                  }

                  handleChange('phoneNumber', value || e.target.value);
                }}
                placeholder="Phone"
              />
            </div>
          </div>

          {roleName.includes('corporate') ||
          roleName.includes('super_admin') ? (
            <div className="roles-frame">
              <div className="flex-frame">
                <div className="section-header">{`Roles (${roles.length})`}</div>
                <button
                  className="add-role-button blue"
                  onClick={() => handleChange('create', true)}
                >
                  + Add Role
                </button>
              </div>

              <ul className="roles-list-view">
                {_.orderBy(roles, ['name', 'org.title'], ['asc', 'asc']).map(
                  (role) => {
                    const { id, name, org } = role;

                    const isSelected = edit ? edit.id === id : false;

                    return isSelected ? (
                      <li className="role-cell selected" key={id}>
                        <div className="menu-frame">
                          <FormSelect
                            defaultValue={edit.name}
                            header="Role"
                            options={defaultRoles(roleName)}
                            onChange={(e) =>
                              handleChange('edit.name', e.target.value)
                            }
                            placeholder="Select Role"
                          />

                          <FormSelect
                            className={role ? 'org-menu' : 'hide'}
                            defaultValue={edit.org.id}
                            header="Org"
                            options={orgs.nodes.map((o) => ({
                              value: o.id,
                              name: o.title,
                            }))}
                            onChange={(e) =>
                              handleChange('edit.org.id', e.target.value)
                            }
                            placeholder="Select Organization"
                          />
                        </div>

                        <div className="menu-frame">
                          <button
                            className="save-role-button red"
                            onClick={() => handleChange('edit', null)}
                          >
                            Cancel
                          </button>
                          <button
                            className="save-role-button blue"
                            onClick={saveRoleEdit}
                          >
                            Save
                          </button>
                        </div>
                      </li>
                    ) : (
                      <li className="role-cell" key={id}>
                        <div className="role-frame">
                          <div className="cell-label">{org.title}</div>
                          <div className="cell-label red">
                            {_.startCase(name)}
                          </div>
                        </div>

                        <OverlayTrigger
                          trigger="click"
                          placement="bottom"
                          rootClose
                          overlay={
                            <Popover id="menu-pane" className="menu-pane">
                              <div
                                className="menu-content-view"
                                style={{ padding: '5px 10px' }}
                              >
                                <button
                                  className="menu-button teal"
                                  onClick={() =>
                                    handleChange('edit', { ...role })
                                  }
                                >
                                  Edit
                                </button>
                                <button
                                  className="menu-button red"
                                  onClick={() => deleteRole(role)}
                                >
                                  Delete
                                </button>
                              </div>
                            </Popover>
                          }
                        >
                          <div className="expander-icon">
                            <img
                              src={require('../../theme/images/expander-icon-grey-2x.png')}
                              alt="expander-icon"
                            />
                          </div>
                        </OverlayTrigger>
                      </li>
                    );
                  },
                )}
              </ul>
              {create && (
                <div className={create ? 'create-role-frame' : 'hide'}>
                  <div className="menu-frame">
                    <FormSelect
                      header="Role"
                      options={defaultRoles(roleName)}
                      onChange={(e) => handleChange('role', e.target.value)}
                      placeholder="Select Role"
                    />

                    <FormSelect
                      className={role ? 'org-menu' : 'hide'}
                      header="Org"
                      options={orgs.nodes.map((o) => ({
                        value: o.id,
                        name: o.title,
                      }))}
                      onChange={(e) => handleChange('orgId', e.target.value)}
                      placeholder="Select Organization"
                    />
                  </div>

                  <div className="menu-frame">
                    <button
                      className="save-role-button red"
                      onClick={() => {
                        handleChange('create', false);
                        handleChange('orgId', null);
                        handleChange('role', null);
                      }}
                    >
                      Cancel
                    </button>

                    <button
                      className={
                        role && orgId ? 'save-role-button blue' : 'hide'
                      }
                      onClick={saveRole}
                    >
                      Save
                    </button>
                  </div>
                </div>
              )}
            </div>
          ) : null}
        </div>
      </div>
    </div>
  );
};

export default ProfileEdit;
