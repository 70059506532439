import { gql } from 'apollo-boost';

const OrgContentRemoveMutation = gql`
  mutation OrgContentRemoveMutation($input: OrgContentRemoveInput) {
    orgContentRemove(input: $input) {
      errors {
        code
        message
      }
      success
    }
  }
`;

export default OrgContentRemoveMutation;
