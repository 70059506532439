import gql from 'graphql-tag';

const SignatureDocumentQuery = gql`
  query signatureDocQuery {
    signatureDocuments {
      edges {
        node {
          id
          status
          title
          annualExpiration
          description
          updatedAt
          url
          __typename
        }
      }
      pageInfo {
        hasNextPage
        endCursor
      }
    }
  }
`;

export default SignatureDocumentQuery;
