import React from 'react';
import { graphql } from 'react-apollo';
import { flowRight as compose } from 'lodash';

import UserQuery from '../../../graphql/queries/Document/Reference';
import AttendanceQuery from '../../../graphql/queries/Document/Reference/Attendance';
import Reference from '../../../components/Documents/Reference';

import LoadingPane from '../../../components/Shared/LoadingPane';
import { withRouter } from '../../withRouter';
class ReferenceDocumentContainer extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      mobile: props.mobile,
      onload: true,
    };
  }

  componentDidMount() {
    this.setup();
  }

  componentDidUpdate(prevProps) {
    const { mobile } = this.props;

    this.setup(prevProps.mobile !== mobile);
  }

  setup = (mobileDidChange) => {
    const {
      mobile,
      userQuery: { loading, user },
      referenceAttendanceQuery: {
        loading: attLoading,
        referenceAttendancePunctualities,
      },
    } = this.props;
    const { onload } = this.state;

    if (onload && !loading && !attLoading) {
      this.setState({
        ...user.reference,
        attendance: referenceAttendancePunctualities.reduce((a, v) => ({
          ...a,
          [v.optionValue]: v.optionText
        }), {}),
        current: !user.reference.endDate,
        mobile: mobileDidChange ? mobile : this.state.mobile,
        onload: false,
      });
    } else if (mobileDidChange) {
      this.handleChange('mobile', mobile);
    }
  };

  back = () => {
    const { params, navigate, location } = this.props;

    if (!location.state || location.state?.previous === '/login') {
      navigate(`/organizations/${params.id}/roster/${params.roster}`);
      return;
    }
    navigate(-1);
  };

  render() {
    return this.state.onload ? (
      <LoadingPane />
    ) : (
      <Reference
        {...this.props}
        back={this.back}
        save={this.save}
        state={this.state}
      />
    );
  }
}

export default compose(
  withRouter,
  graphql(UserQuery, {
    name: 'userQuery',
    options: ({ params: { roster, item } }) => ({
      variables: {
        id: roster,
        reference: item,
      },
    }),
  }),
  graphql(AttendanceQuery, { name: "referenceAttendanceQuery" }),
)(ReferenceDocumentContainer);
