import React from 'react';

class ModalPane extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      animate: false,
    };
  }

  componentDidUpdate(prevProps) {
    const that = this;
    const { show } = this.props;

    if (prevProps.show !== show) {
      if (show) {
        setTimeout(() => that.animateUp(), 100);
      } else {
        setTimeout(() => that.animateDown(), 100);
      }
    }
  }

  animateUp = () => {
    this.setState({ animate: true });
  };

  animateDown = () => {
    this.setState({ animate: false });
  };

  handleContentClick = (e) => {
    e.preventDefault();
    e.stopPropagation();
  };

  hide = () => {
    this.animateDown();

    setTimeout(() => this.props.hide(), 300);
  };

  render() {
    const { className, content, show } = this.props;
    const { animate } = this.state;

    if (show) {
      return (
        <div
          className={`modal-pane ${className ? className : ''}`}
          onClick={() => this.hide()}
        >
          <div
            className={`modal-pane-content layout-center ${
              animate ? 'show' : ''
            }`}
            onClick={(e) => this.handleContentClick(e)}
          >
            {content(this.hide)}
          </div>
        </div>
      );
    } else {
      return null;
    }
  }
}

export default ModalPane;
