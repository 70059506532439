import React from 'react';
import { graphql } from 'react-apollo';
import compose from 'lodash.flowright';

import _ from 'lodash';

import UserQuery from '../../graphql/queries/Roster/Test';

import RosterTest from '../../components/Roster/Test';

import LoadingPane from '../../components/Shared/LoadingPane';
import { withRouter } from '../withRouter';

class RosterTestContainer extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      animations: {
        categories: true,
      },
      onload: true,
    };
  }

  componentDidMount() {
    this.setup();
  }

  componentDidUpdate() {
    this.setup();
  }

  setup = () => {
    const {
      navigate,
      params: { id },

      userQuery: { loading, user, org },
    } = this.props;
    const { onload } = this.state;

    if (onload && !loading) {
      if (user) {
        this.setState(
          {
            ...user,
            attachment: org ? org.attachments[0] : null,
            onload: false,
          },
          () =>
            setTimeout(
              () => this.handleChange('animations.categories', false),
              300,
            ),
        );
      } else {
        navigate(`/organizations/${id}/roster`);
      }
    }
  };

  back = () => {
    const {
      navigate,
      params: { id, roster },
    } = this.props;

    navigate(`/organizations/${id}/roster/${roster}`);
  };

  handleAnimation = (key) => {
    this.handleChange(`animations.${key}`, !this.state.animations[key]);
  };

  handleChange = (key, value) => {
    this.setState(_.set(this.state, key, value));
  };

  render() {
    return this.state.onload ? (
      <LoadingPane />
    ) : (
      <RosterTest
        back={this.back}
        handleAnimation={this.handleAnimation}
        state={this.state}
        user={this.props.context.user}
        orgId={this.props.params.id}
      />
    );
  }
}

export default compose(
  withRouter,
  graphql(UserQuery, {
    name: 'userQuery',
    options: ({ params: { id, roster, item } }) => ({
      variables: {
        id: roster,
        test: item,
        org: id,
      },
    }),
  }),
)(RosterTestContainer);
