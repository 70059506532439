import { gql } from 'apollo-boost';

const RosterTestQuery = gql`
  query RosterTestQuery($id: ID, $test: ID, $org: ID!) {
    user(id: $id) {
      id
      firstName
      lastName
      specialties {
        id
        isPrimary
        name
      }
      testTaken(id: $test) {
        id
        answers {
          id
          isCorrect
          question {
            id
            rank
            title
          }
          value
        }
        startedAt
        endedAt
        timeSpentMins
        timeSpentSecs
        passed
        score
        status
        test {
          id
          categories {
            id
            rank
            title
            questions {
              id
              rank
              title
            }
          }
          desc
          kind
          passingScore
          title
        }
      }
    }
    org(id: $org) {
      id
      attachments(labels: ["pdf_logo"]) {
        id
        label
        url
      }
    }
  }
`;

export default RosterTestQuery;
