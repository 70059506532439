import { gql } from 'apollo-boost';

const ChecklistQuestionCreateMutation = gql`
  mutation ChecklistQuestionCreateMutation(
    $input: ChecklistQuestionCreateInput
  ) {
    checklistQuestionCreate(input: $input) {
      errors {
        message
      }
      result {
        id
        rank
        title
        updatedAt
      }
    }
  }
`;

export default ChecklistQuestionCreateMutation;
