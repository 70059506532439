import React from 'react';

const LoginLogo = () => {
  return (
    <div className="login-logo">
      <img
        className="logo"
        src={require('../../theme/images/una-logo-2x.png')}
        alt="una-logo"
        style={{ height: 36 }}
      />
    </div>
  );
};

export default LoginLogo;
