import React from 'react';

import NotFound from '../../components/NotFound';
import { withRouter } from '../withRouter';
class NotFoundContainer extends React.Component {
  goToBaseRoute = () => {
    const { location, navigate, user } = this.props;

    if (user) {
      const role = user.roles.length > 0 ? user.roles[0] : null;
      const routeParts = location.pathname.split('/');
      const searchParams = new URLSearchParams(location.search);

      navigate(
        role
          ? `/organizations/${
              searchParams.has('org') ? searchParams.get('org') : role.org.id
            }/${routeParts[3] || 'tests'}`
          : '/',
      );
    } else {
      navigate('/');
    }
  };

  render() {
    return <NotFound goToBaseRoute={this.goToBaseRoute} />;
  }
}

export default withRouter(NotFoundContainer);
