import React from 'react';

import _ from 'lodash';
import moment from 'moment';

import LoadingPane from '../../../Shared/LoadingPane';
import ModalPane from '../../../Shared/ModalPane';
import DocumentDisplay from '../../../Control/DocumentDisplay';

import STRINGS from '../../../../localization';

const Other = (props) => {
  const { back, documentRemove, handleChange, state } = props;
  const {
    expiresOn,
    loading,
    isModal,
    imageUrl,
    imageBackUrl,
    issuedOn,
    mobile,
    title,
    user,
  } = state;

  return (
    <div className="document-read-view">
      {loading ? (
        <LoadingPane style={{ background: 'rgba(0,0,0,0.1)' }} />
      ) : null}

      <div className="toolbar">
        {mobile ? (
          <div className="mobile-button bg-grey-2" onClick={back}>
            <div className="mobile-button-icon">
              <img
                src={require('../../../../theme/images/buttons/button-x-icon-2x.png')}
                alt="button-x-icon"
              />
            </div>
          </div>
        ) : (
          <button className="back-button bg-grey-2" onClick={back}>
            {STRINGS.cancel}
          </button>
        )}

        <div className="page-title">{`${STRINGS.view} ${_.startCase(
          state.kind,
        )}`}</div>
      </div>

      <div className="content-frame scroll">
        <div className={mobile ? 'document-frame mobile' : 'document-frame'}>
          <div className="read-value-frame">
            <div className="value-label capitalize">{STRINGS.title}</div>
            <div className="value-label capitalize bold">{title}</div>
          </div>

          <div className="read-value-frame">
            <div className="value-label capitalize">{STRINGS.dateReceived}</div>
            <div className="value-label capitalize bold">
              {moment(issuedOn).format('MMM DD, YYYY')}
            </div>
          </div>

          <div className={expiresOn ? 'read-value-frame' : 'hide'}>
            <div className="value-label capitalize">
              {STRINGS.issueExpireDate}
            </div>
            <div className="value-label capitalize bold">
              {moment(expiresOn).format('MMM DD, YYYY')}
            </div>
          </div>

          <div className="document-images-frame">
            <DocumentDisplay
              docUrl={imageUrl}
              link={true}
              title={`${user.firstName}_${user.lastName}_${title}`}
            />
            <DocumentDisplay
              docUrl={imageBackUrl}
              link={true}
              title={`${user.firstName}_${user.lastName}_${title} back`}
            />
          </div>
        </div>
      </div>

      <ModalPane
        className="document-delete-pane"
        content={(hide) => (
          <div>
            <div className="modal-header">
              {STRINGS.documentDeletePaneMessage}
            </div>

            <div className="modal-button-frame">
              <button className="modal-button bg-grey-2" onClick={hide}>
                {STRINGS.cancel}
              </button>
              <button
                className="modal-button bg-una"
                onClick={() => documentRemove(hide)}
              >
                {STRINGS.delete}
              </button>
            </div>
          </div>
        )}
        show={isModal}
        hide={() => handleChange('isModal', false)}
      />
    </div>
  );
};

export default Other;
