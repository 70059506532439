import gql from 'graphql-tag';

const TestQuery = gql`
  query TestQuery($id: ID) {
    test(id: $id) {
      id
      categories {
        id
        questions {
          id
          answers
          isRandomized
          rank
          title
          correctAnswerIndex
        }
        rank
        title
        subtopic
      }
      color
      durationMins
      desc
      kind
      passingScore
      status
      title
      testtype
      test_type
    }
  }
`;

export default TestQuery;
