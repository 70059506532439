import React from 'react';
import { graphql } from 'react-apollo';
import compose from 'lodash.flowright';

import _, { uniq } from 'lodash';

import OrgQuery from '../../graphql/queries/Roster/Upload';
import UsersQuery from '../../graphql/queries/Roster/Upload/Users';
import UserFindOrCreateMutation from '../../graphql/mutations/User/FindOrCreate';
import UserUpdateMutation from '../../graphql/mutations/User/Update';
import UserChecklistCreateMutation from '../../graphql/mutations/User/Checklist/Create';
import UserCourseCreateMutation from '../../graphql/mutations/User/Course/Create';
import UserTestCreateMutation from '../../graphql/mutations/User/Test/Create';

import RosterUpload from '../../components/Roster/Upload';

import LoadingPane from '../../components/Shared/LoadingPane';
import { withRouter } from '../withRouter';

const refs = {};
class RosterUploadContainer extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      expandA: false,
      expandB: false,
      expandC: false,
      expandD: false,
      file: null,
      invalid: [],
      onload: true,
      pct: 0,
      selectedA: [],
      selectedB: [],
      selectedC: [],
      selectedD: [],
      valid: [],
    };
  }

  componentDidMount() {
    this.setup();
  }

  componentDidUpdate() {
    this.setup();
  }

  setup = () => {
    const {
      orgQuery: { loading, org },
    } = this.props;
    const { onload } = this.state;

    if (onload && !loading) {
      this.setState(
        {
          onload: false,
          org,
        },
        () => {
          setTimeout(() => {
            this.setState({
              expandA: true,
              expandB: true,
              expandC: true,
              expandD: true,
            });
          }, 250);
        },
      );
    }
  };

  goToRosterRoute = () => {
    const { navigate, params } = this.props;

    navigate(`/organizations/${params.id}/roster`);
  };

  handleChange = (key, value) => {
    this.setState({
      [key]: value,
    });
  };

  handleCSVUpload = (arr, file) => {
    const valid = [];
    const invalid = [];

    arr.forEach((obj) => {
      const { email, firstName, lastName, role } = obj;
      const emailRegex =
        /^([A-Za-z0-9_\-.+])+@([A-Za-z0-9_\-.])+\.([A-Za-z]{2,})$/;

      const supportedRoles = ['admin', 'recruiter', 'nurse'];
      const isRole = supportedRoles.includes(role);
      const isEmail = emailRegex.test(email);
      const isName =
        firstName && firstName.length > 0 && lastName && lastName.length > 0;
      const validEntry = isName && isEmail && isRole;
      const data = {
        ...obj,
        isEmail,
        isRole,
        valid: validEntry,
      };

      if (validEntry) valid.push(data);
      else invalid.push(data);
    });

    this.setState({
      file,
      invalid,
      valid,
    });
  };

  handleSelectAll = (arr, key) => {
    const isAll = arr.length === this.state[key].length;

    this.handleChange(key, isAll ? [] : arr.map((o) => o.id));
  };

  handleSelection = (e, key, value, bundle) => {
    let i;
    const arr = this.state[key];
    const checkbox = e.target.parentNode.className === 'checkbox';
    const node = checkbox
      ? e.target.parentNode
      : e.target.parentNode.firstChild;
    const checked = checkbox ? !arr.includes(value) : !node.firstChild.checked;

    if (checked) {
      arr.concat(checked);
    } else {
      i = arr.findIndex((idx) => idx === value);
    }

    if (bundle) {
      const { selectedA, selectedB, selectedC } = this.state;
      const { checklists, courses, tests } = bundle;

      this.setState({
        [key]: checked
          ? [...arr, value]
          : [...arr.slice(0, i), ...arr.slice(i + 1, arr.length)],
        selectedA: checked
          ? uniq([...selectedA, ...tests.map((o) => o.id)])
          : selectedA.filter((id) => !tests.map((o) => o.id).includes(id)),
        selectedB: checked
          ? uniq([...selectedB, ...checklists.map((o) => o.id)])
          : selectedB.filter((id) => !checklists.map((o) => o.id).includes(id)),
        selectedC: checked
          ? uniq([...selectedC, ...courses.map((o) => o.id)])
          : selectedC.filter((id) => !courses.map((o) => o.id).includes(id)),
      });
    } else {
      this.handleChange(
        key,
        checked
          ? [...arr, value]
          : [...arr.slice(0, i), ...arr.slice(i + 1, arr.length)],
      );
    }
  };

  save = () => {
    const { invalid, valid } = this.state;

    if (invalid.length === 0 && valid.length > 0) {
      this.setState(
        {
          loading: true,
        },
        () => this.saveUser(valid),
      );
    }
  };

  saveUser = (users) => {
    const { usersQuery } = this.props;

    if (users.length === 0) {
      this.goToRosterRoute();
    } else {
      const email = users[0].email;

      usersQuery.refetch({ search: email }).then((response) => {
        const arr = response.data.users;

        if (arr.nodes.length > 0) {
          const existing = arr.nodes.find((o) => o.email === email);

          this.updateUser(existing, users);
        } else {
          this.createUser(users);
        }
      });
    }
  };

  saveChecklists = (arr, users, user) => {
    if (arr.length === 0) {
      this.saveCourses(this.state.selectedC, users, user);
    } else {
      const { userChecklistCreateMutation } = this.props;

      userChecklistCreateMutation({
        variables: {
          input: {
            checklistId: arr[0],
            orgId: this.props.params.id,
            userId: user.id,
            notifyEmail: this.state.active
              ? this.props.context.user.email
              : null,
          },
        },
      }).then((response) => {
        this.saveChecklists(arr.slice(1, arr.length), users, user);
      });
    }
  };

  saveCourses = (arr, users, user) => {
    if (arr.length === 0) {
      this.saveTests(this.state.selectedA, users, user);
    } else {
      const { userCourseCreateMutation } = this.props;

      userCourseCreateMutation({
        variables: {
          input: {
            courseId: arr[0],
            orgId: this.props.params.id,
            userId: user.id,
            notifyEmail: this.state.active
              ? this.props.context.user.email
              : null,
          },
        },
      }).then((response) => {
        this.saveCourses(arr.slice(1, arr.length), users, user);
      });
    }
  };

  saveTests = (arr, users, user) => {
    if (arr.length === 0) {
      this.saveUser(users.slice(1, users.length, user));
    } else {
      const { userTestCreateMutation } = this.props;

      userTestCreateMutation({
        variables: {
          input: {
            testId: arr[0],
            orgId: this.props.params.id,
            userId: user.id,
            notifyEmail: this.state.active
              ? this.props.context.user.email
              : null,
          },
        },
      }).then((response) => {
        this.saveTests(arr.slice(1, arr.length), users, user);
      });
    }
  };

  createUser = (users) => {
    const { params, userFindOrCreateMutation } = this.props;

    const { email, firstName, lastName, role } = users[0];

    userFindOrCreateMutation({
      variables: {
        input: {
          email,
          firstName: _.capitalize(firstName),
          lastName: _.capitalize(lastName),
          password: 'Welcome2020',
          roles: [{ role, orgId: params.id }],
        },
      },
    }).then((response) => {
      // this.saveUser(users.slice(1, users.length))
      this.saveChecklists(
        this.state.selectedB,
        users,
        response.data.userFindOrCreate.result,
      );
    });
  };

  updateUser = (existing, users) => {
    const { params, userUpdateMutation } = this.props;

    const { firstName, lastName, role } = users[0];

    const roles = _.uniqBy(
      [...existing.roles, { role, orgId: params.id }],
      'orgId',
    );

    userUpdateMutation({
      variables: {
        input: {
          id: existing.id,
          firstName: _.capitalize(firstName),
          lastName: _.capitalize(lastName),
          roles: roles.map(({ role, orgId }) => ({ role, orgId })),
        },
      },
    }).then((response) => {
      // this.saveUser(users.slice(1, users.length))
      this.saveChecklists(
        this.state.selectedB,
        users,
        response.data.userUpdate.result,
      );
    });
  };

  render() {
    return this.state.onload ? (
      <LoadingPane />
    ) : (
      <RosterUpload
        goToRosterRoute={this.goToRosterRoute}
        handleChange={this.handleChange}
        handleCSVUpload={this.handleCSVUpload}
        handleSelectAll={this.handleSelectAll}
        handleSelection={this.handleSelection}
        refs={refs}
        save={this.save}
        state={this.state}
      />
    );
  }
}

export default compose(
  withRouter,
  graphql(OrgQuery, {
    name: 'orgQuery',
    options: (props) => ({
      variables: {
        id: props.params.id,
      },
    }),
  }),
  graphql(UsersQuery, { name: 'usersQuery' }),
  graphql(UserFindOrCreateMutation, {
    name: 'userFindOrCreateMutation',
  }),
  graphql(UserUpdateMutation, { name: 'userUpdateMutation' }),
  graphql(UserCourseCreateMutation, { name: 'userCourseCreateMutation' }),
  graphql(UserChecklistCreateMutation, { name: 'userChecklistCreateMutation' }),
  graphql(UserTestCreateMutation, { name: 'userTestCreateMutation' }),
)(RosterUploadContainer);
