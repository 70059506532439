import { gql } from 'apollo-boost';

const RosterUploadQuery = gql`
  query RosterUploadQuery($id: ID!, $order: String) {
    org(id: $id) {
      id
      bundles {
        id
        checklists {
          id
        }
        courses {
          id
        }
        tests {
          id
        }
        title
      }
      courses(order: $order, status: ["active"]) {
        nodes {
          id
          kind
          title
        }
      }
      checklists(order: $order, status: ["active"]) {
        nodes {
          id
          kind
          title
        }
      }
      tests(order: $order, status: ["active"]) {
        nodes {
          id
          kind
          title
        }
      }
    }
  }
`;

export default RosterUploadQuery;
