import React from 'react';

import Award from '../../../containers/Documents/Document/Award';
import DriversLicense from '../../../containers/Documents/Document/DriversLicense';
import LetterOfRecommendation from '../../../containers/Documents/Document/LetterOfRecommendation';
import Other from '../../../containers/Documents/Document/Other';
import Passport from '../../../containers/Documents/Document/Passport';
import PhysicalHealthStatement from '../../../containers/Documents/Document/PhysicalHealthStatement';
import PPD from '../../../containers/Documents/Document/PPD';
import Resume from '../../../containers/Documents/Document/Resume';
import Signature from '../../../containers/Documents/Document/Signature';
import Titer from '../../../containers/Documents/Document/Titer';
import Vaccination from '../../../containers/Documents/Document/Vaccination';
import XRay from '../../../containers/Documents/Document/X-Ray';

import STRINGS from '../../../localization';

const Document = (props) => {
  const { goToUserRoute, state } = props;
  const { kind } = state;

  const sigDocs = [
    'annual_tb_attestation',
    'annual_education_attestation',
    'flu_declination',
    'hep_b_declination',
    'preliminary_background_release',
    'varicella_declination',
  ];

  if (kind === 'award') {
    return <Award {...props} />;
  } else if (sigDocs.includes(kind)) {
    return <Signature {...props} />;
  } else if (kind === 'drivers_license') {
    return <DriversLicense {...props} />;
  } else if (kind === 'electromagnetic_radiation' || kind === 'x_ray') {
    return <XRay {...props} />;
  } else if (kind === 'letter_of_recommendation') {
    return <LetterOfRecommendation {...props} />;
  } else if (kind === 'other') {
    return <Other {...props} />;
  } else if (kind === 'passport') {
    return <Passport {...props} />;
  } else if (kind === 'physical_health_statement') {
    return <PhysicalHealthStatement {...props} />;
  } else if (kind === 'ppd') {
    return <PPD {...props} />;
  } else if (kind === 'resume' || kind === 'social_security_card') {
    return <Resume {...props} />;
  } else if (kind === 'titer' || kind === 'immunization') {
    return <Titer {...props} />;
  } else if (kind === 'vaccination') {
    return <Vaccination {...props} />;
  } else {
    return (
      <div className="document-view full-screen-view">
        <div className="layout-center">
          <div className="una">{STRINGS.nonSupportedDocumentMessage(kind)}</div>

          <button
            className="back-button bg-una capitalize"
            onClick={goToUserRoute}
          >
            {STRINGS.backToUser}
          </button>
        </div>
      </div>
    );
  }
};

export default Document;
