import React from 'react';
import { graphql } from 'react-apollo';
import compose from 'lodash.flowright';

import _ from 'lodash';

import ChecklistQuery from '../../graphql/queries/Checklist';
import ChecklistQuestionCreateMutation from '../../graphql/mutations/Checklists/Question/Create';
import ChecklistQuestionRemoveMutation from '../../graphql/mutations/Checklists/Question/Remove';
import ChecklistQuestionUpdateMutation from '../../graphql/mutations/Checklists/Question/Update';

import ChecklistSectionCreateMutation from '../../graphql/mutations/Checklists/Sections/Create';
import ChecklistSectionRemoveMutation from '../../graphql/mutations/Checklists/Sections/Remove';
import ChecklistSectionUpdateMutation from '../../graphql/mutations/Checklists/Sections/Update';

import CategoryChecklist from '../../components/Category/Checklist';

import LoadingPane from '../../components/Shared/LoadingPane';
import { withRouter } from '../withRouter';

class CategoryChecklistContainer extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      question: {},
      questions: [],
      section: {},
      loading: false,
      onload: true,
    };
  }

  componentDidMount() {
    const {
      checklistQuery: { loading, refetch },
    } = this.props;
    const { onload } = this.state;

    if (onload && !loading) {
      refetch().then(() => this.setup());
    } else {
      this.setup();
    }
  }

  componentDidUpdate() {
    this.setup();
  }

  componentWillUnmount() {
    window.removeEventListener('keypress', this.handleKeyPress);
  }

  setup = () => {
    const {
      checklistQuery: { loading, checklist },
      location,
      navigate,
      params,
      context,
    } = this.props;
    const { user } = context;
    const { onload } = this.state;

    if (onload && !loading) {
      const category = checklist.categories.find(
        (c) => c.id === params.category,
      );
      const org = location.state ? location.state.org : null;
      const role = user.roles.find((r) => r.org.id === org);
      const admin = role ? role.name === 'corporate' : false;

      if (checklist && checklist.kind === 'global' && !admin) {
        navigate(`/error${org ? `?org=${org}` : ''}`);
      } else {
        category.sections.forEach((section) => {
          section.questions = _.orderBy(section.questions, ['rank']);
        });

        this.setState({
          ...category,
          checklist,
          onload: false,
          sections: _.sortBy(category.sections, ['rank']),
        });

        window.addEventListener('keypress', this.handleKeyPress);
      }
    }
  };

  addQuestion = (i) => {
    const arr = this.state.sections;
    const section = arr[i];
    const questions = section.questions;
    const index = questions.length;
    const question = {
      id: `create-${index}`,
    };

    if (!questions.find((q) => q.id.includes('create'))) {
      questions.push(question);

      this.setState({
        i,
        question,
        sections: [
          ...arr.slice(0, i),
          section,
          ...arr.slice(i + 1, arr.length),
        ],
      });
    }
  };

  addSection = () => {
    const sections = this.state.sections;
    const index = sections.length + 1;
    const section = {
      id: `create-${index}`,
      questions: [],
      rank: index,
    };

    if (!this.state.section.id) {
      this.setState({
        section,
        sections: [...sections, section],
      });
    }
  };

  cancelQuestion = () => {
    const {
      i,
      question: { id },
      sections,
    } = this.state;

    const section = sections[i];
    const questions = section.questions;
    const index = questions.findIndex((c) => c.id === id);

    if (id.includes('create')) {
      section.questions = [
        ...questions.slice(0, index),
        ...questions.slice(index + 1, questions.length),
      ];

      this.setState({
        question: {},
        sections: [
          ...sections.slice(0, i),
          section,
          ...sections.slice(i + 1, sections.length),
        ],
      });
    } else {
      this.handleChange('question', {});
    }
  };

  cancelSection = () => {
    const {
      sections: arr,
      section: { id },
    } = this.state;

    const i = arr.findIndex((c) => c.id === id);

    if (id.includes('create')) {
      this.setState({
        section: {},
        sections: [...arr.slice(0, i), ...arr.slice(i + 1, arr.length)],
      });
    } else {
      this.handleChange('section', {});
    }
  };

  deleteQuestion = (section, id) => {
    const { checklistQuestionRemoveMutation } = this.props;
    const { sections } = this.state;

    document.body.click();

    this.setState(
      {
        loading: true,
      },
      () => {
        checklistQuestionRemoveMutation({
          variables: {
            input: {
              id,
            },
          },
        }).then((response) => {
          const {
            data: {
              checklistQuestionRemove: { errors },
            },
          } = response;

          if (errors) {
            window.alert(errors[0].message);
            this.setState({
              loading: false,
            });
          } else {
            const arr = section.questions;
            const sectionIndex = sections.findIndex((s) => s.id === section.id);
            const questionIndex = section.questions.findIndex(
              (q) => q.id === id,
            );

            section.questions = [
              ...arr.slice(0, questionIndex),
              ...arr.slice(questionIndex + 1, arr.length),
            ];

            this.setState({
              loading: false,
              sections: [
                ...sections.slice(0, sectionIndex),
                section,
                ...sections.slice(sectionIndex + 1, sections.length),
              ],
            });
          }
        });
      },
    );
  };

  deleteSection = (i) => {
    const { checklistSectionRemoveMutation } = this.props;
    const arr = this.state.sections;

    checklistSectionRemoveMutation({
      variables: {
        input: {
          id: arr[i].id,
        },
      },
    }).then((response) => {
      const {
        data: {
          checklistSectionRemove: { errors },
        },
      } = response;

      if (errors) {
        window.alert(errors[0].message);
      } else {
        this.handleChange('sections', [
          ...arr.slice(0, i),
          ...arr.slice(i + 1, arr.length),
        ]);
      }
    });
  };

  duplicateQuestion = (section, question) => {
    const { checklistQuestionCreateMutation } = this.props;
    const { title } = question;

    document.body.click();

    this.setState(
      {
        loading: true,
      },
      () => {
        checklistQuestionCreateMutation({
          variables: {
            input: {
              sectionId: section.id,
              title: `${title} Copy`,
              rank: this.state.questions.length + 1,
            },
          },
        }).then((response) => {
          const {
            data: {
              checklistQuestionCreate: { errors, result: question },
            },
          } = response;

          if (errors) {
            window.alert(errors[0].message);
          } else {
            const arr = this.state.sections;
            const i = arr.findIndex((s) => s.id === section.id);

            section.questions.push(question);

            this.setState({
              loading: false,
              sections: [
                ...arr.slice(0, i),
                section,
                ...arr.slice(i + 1, arr.length),
              ],
            });
          }
        });
      },
    );
  };

  goToChecklistRoute = () => {
    const { location, navigate, params } = this.props;
    const org = location.state.org;

    navigate(`/checklists/${params.id}`, {
      state: {
        previous: `/organizations/${org}/checklists`,
        org,
      },
    });
  };

  handleChange = (key, value) => {
    this.setState(_.set(this.state, key, value));
  };

  handleKeyPress = (e) => {
    const { i, question, sections } = this.state;

    const that = this;
    const index = parseInt(e.code.split('Digit')[1], 10) - 1;
    const section = sections[index];

    if (e.shiftKey && e.keyCode === 13 && i !== undefined) {
      // shift + enter
      this.saveQuestion(sections[i], i, sections[i].questions.length - 1, () =>
        this.addQuestion(i),
      );
    } else if (e.shiftKey && section && !question.id) {
      // shift + section exists
      const questions = section.questions;
      const question = {
        id: `create-${questions.length}`,
      };

      section.questions = [...questions, question];

      setTimeout(() => {
        that.setState({
          i: index,
          question,
          sections: [
            ...sections.slice(0, index),
            section,
            ...sections.slice(index + 1, sections.length),
          ],
        });
      }, 100);
    }
  };

  handleScroll = () => {};

  handleSectionChange = (i, questions) => {
    const arr = this.state.sections;
    const section = arr[i];

    section.questions = questions;

    this.setState({
      sections: [...arr.slice(0, i), section, ...arr.slice(i + 1, arr.length)],
    });
  };

  save = () => {
    this.handleChange('loading', true);
    this.updateSections(this.state.sections, 0);
  };

  saveQuestion = (section, i, qIndex, success) => {
    const { checklistQuestionCreateMutation, checklistQuestionUpdateMutation } =
      this.props;
    const {
      question: { title, id },
      sections,
    } = this.state;

    if (title) {
      const isCreate = id.includes('create');
      const mutation = isCreate
        ? checklistQuestionCreateMutation
        : checklistQuestionUpdateMutation;
      const data = {
        title,
        scoreValues: [1, 2, 3, 4],
        scoreLabels: global.scoreValues,
      };

      if (isCreate) data.sectionId = section.id;
      else data.id = id;

      this.setState(
        {
          loading: true,
        },
        () => {
          mutation({
            variables: {
              input: data,
            },
          }).then((res) => {
            const key = Object.keys(res.data)[0];
            const response = res.data[key];

            if (response.errors) {
              window.alert(response.errors[0].message);

              this.handleChange('loading', false);
            } else {
              section.questions = [
                ...section.questions.slice(0, qIndex),
                response.result,
                ...section.questions.slice(
                  qIndex + 1,
                  section.questions.length,
                ),
              ];

              this.setState(
                {
                  loading: false,
                  question: {},
                  sections: [
                    ...sections.slice(0, i),
                    section,
                    ...sections.slice(i + 1, sections.length),
                  ],
                },
                () => {
                  if (success) success();
                },
              );
            }
          });
        },
      );
    } else {
      window.alert('Question requires a title.');
    }
  };

  saveSection = () => {
    const {
      checklistSectionCreateMutation,
      checklistSectionUpdateMutation,
      params,
    } = this.props;
    const {
      sections,
      section: { id, rank, title },
    } = this.state;

    if (title) {
      const isCreate = id.includes('create');
      const mutation = isCreate
        ? checklistSectionCreateMutation
        : checklistSectionUpdateMutation;
      const data = {
        categoryId: params.category,
        title,
      };

      if (!isCreate) data.id = id;

      this.setState(
        {
          loading: true,
        },
        () => {
          mutation({
            variables: {
              input: data,
            },
          }).then((response) => {
            const key = Object.keys(response.data);
            const res = response.data[key];

            if (res.errors) {
              window.alert(res.errors[0].message);
            } else {
              this.setState({
                loading: false,
                section: {},
                sections: [
                  ...sections.slice(0, rank - 1),
                  res.result,
                  ...sections.slice(rank, sections.length),
                ],
              });
            }
          });
        },
      );
    }
  };

  updateQuestions = (arr, i, sections, sectionIndex) => {
    const { checklistQuestionUpdateMutation } = this.props;

    if (i === arr.length) {
      this.updateSections(sections, sectionIndex + 1);
    } else {
      checklistQuestionUpdateMutation({
        variables: {
          input: {
            id: arr[i].id,
            rank: i + 1,
            scoreValues: [1, 2, 3, 4],
            scoreLabels: global.scoreValues,
          },
        },
      }).then(() => this.updateQuestions(arr, i + 1, sections, sectionIndex));
    }
  };

  updateSections = (arr, i) => {
    if (i === arr.length) {
      this.goToChecklistRoute();
    } else {
      this.updateQuestions(arr[i].questions, 0, arr, i);
    }
  };

  render() {
    return this.state.onload ? (
      <LoadingPane />
    ) : (
      <CategoryChecklist
        {...this.props}
        addQuestion={this.addQuestion}
        addSection={this.addSection}
        cancelQuestion={this.cancelQuestion}
        cancelSection={this.cancelSection}
        deleteQuestion={this.deleteQuestion}
        deleteSection={this.deleteSection}
        duplicateQuestion={this.duplicateQuestion}
        handleChange={this.handleChange}
        handleSectionChange={this.handleSectionChange}
        handleScroll={this.handleScroll}
        goToChecklistRoute={this.goToChecklistRoute}
        save={this.save}
        saveQuestion={this.saveQuestion}
        saveSection={this.saveSection}
        state={this.state}
      />
    );
  }
}

export default compose(
  withRouter,
  graphql(ChecklistQuery, {
    name: 'checklistQuery',
    options: (props) => ({
      variables: {
        id: props.params.id,
      },
    }),
  }),
  graphql(ChecklistQuestionCreateMutation, {
    name: 'checklistQuestionCreateMutation',
  }),
  graphql(ChecklistQuestionRemoveMutation, {
    name: 'checklistQuestionRemoveMutation',
  }),
  graphql(ChecklistQuestionUpdateMutation, {
    name: 'checklistQuestionUpdateMutation',
  }),
  graphql(ChecklistSectionCreateMutation, {
    name: 'checklistSectionCreateMutation',
  }),
  graphql(ChecklistSectionRemoveMutation, {
    name: 'checklistSectionRemoveMutation',
  }),
  graphql(ChecklistSectionUpdateMutation, {
    name: 'checklistSectionUpdateMutation',
  }),
)(CategoryChecklistContainer);
