import React from 'react';
import { graphql } from 'react-apollo';
import axios from 'axios';
import Cookie from 'js-cookie';

import { camelCase, flowRight as compose, set, cloneDeep } from 'lodash';

import UserQuery from '../../graphql/queries/Roster';
import UserChecklistRemoveMutation from '../../graphql/mutations/User/Checklist/Remove';
import UserCourseRemoveMutation from '../../graphql/mutations/User/Course/Remove';
import UserTestRemoveMutation from '../../graphql/mutations/User/Test/Remove';
import { withRouter } from '../withRouter';

import Roster from '../../components/Roster';

import LoadingPane from '../../components/Shared/LoadingPane';
import ChecklistScore from '../../components/Shared/Checklist/ChecklistScore';

class RosterContainer extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      credentials: {
        certification: [],
        education: [],
        license: [],
        misc: [],
      },
      expand: {
        certifications: false,
        documents: false,
        education: false,
        licenses: false,
        references: false,
        workExperiences: false,
      },
      onload: true,
      loading: false,
    };
  }

  componentDidMount() {
    const {
      userQuery: { loading, refetch },
    } = this.props;
    const { onload } = this.state;

    if (onload && !loading) {
      refetch().then(() => this.setup());
    } else {
      this.setup();
    }
  }

  componentDidUpdate() {
    this.setup();
  }

  setup = () => {
    const {
      userQuery: { loading, org, user },
      context,
    } = this.props;
    const { user: me } = context;
    const { onload } = this.state;

    if (onload && !loading) {
      this.setState({
        ...user,
        attachment: user.attachment,
        checklistsTaken: user.checklistsTaken.map((c) => {
          const checklist = cloneDeep(c);
          return this.setupChecklist(checklist);
        }),
        documents: this.setupDocuments(user.documents),
        me,
        onload: false,
        org,
        specialty: user.specialties.find((s) => s.isPrimary),
      });
    }
  };

  setupChecklist = (checklistTaken) => {
    const answers = {};
    const checklist = checklistTaken.checklist;

    checklistTaken.answers.forEach((a) => (answers[a.question.id] = a.value));

    checklistTaken.score = ChecklistScore.calculateAvgChecklistScore(
      checklist,
      answers,
    ).avgQuestionScore;

    return checklistTaken;
  };

  setupDocuments = (documents) => {
    const credentials = this.state.credentials;

    documents.nodes.forEach((document) => {
      const kind = camelCase(document.kind);
      const collection = credentials[kind];

      if (collection) collection.push(document);
      else credentials.misc.push(document);
    });

    return credentials;
  };

  goToDocumentRoute = () => {
    const {
      navigate,

      params: { id, roster },
    } = this.props;

    navigate(`/organizations/${id}/roster/${roster}/documents/resume/create`);
  };

  goToInviteRoute = () => {
    const {
      navigate,

      params: { id, roster },
    } = this.props;

    navigate(`/organizations/${id}/roster/${roster}/invite`);
  };

  goToItemRoute = (itemType, item, tab) => {
    const {
      navigate,

      params: { id, roster },
    } = this.props;

    if (tab) {
      window.open(
        `/organizations/${id}/roster/${roster}/${itemType}/${item}`,
        '_blank',
      );

      document.body.click();
    } else {
      navigate(`/organizations/${id}/roster/${roster}/${itemType}/${item}`);
    }
  };

  goToPacketRoute = () => {
    const {
      navigate,

      params: { id, roster },
    } = this.props;

    navigate(`/organizations/${id}/roster/${roster}/packet`);
  };

  goToRosterRoute = () => {
    const {
      navigate,

      params: { id },
    } = this.props;

    navigate(`/organizations/${id}/roster`);
  };

  goToUserRoute = (id) => {
    const { location, navigate, params } = this.props;

    navigate(`/organizations/${params.id}/users/${id}`, {
      state: {
        previous: location.pathname,
      },
    });
  };

  handleChange = (key, value) => {
    this.setState(set(this.state, key, value));
  };

  removeItem = (result, key, type) => {
    const {
      userChecklistRemoveMutation,
      userCourseRemoveMutation,
      userTestRemoveMutation,
    } = this.props;

    let mutation;
    const arr = this.state[key];
    const i = arr.findIndex((o) => o.id === result.id);

    document.body.click();

    this.setState(
      {
        loading: true,
      },
      () => {
        if (type === 'test') {
          mutation = userTestRemoveMutation;
        } else if (type === 'checklist') {
          mutation = userChecklistRemoveMutation;
        } else {
          mutation = userCourseRemoveMutation;
        }

        mutation({
          variables: {
            input: {
              id: result.id,
            },
          },
        }).then((response) => {
          const mutationName = Object.keys(response.data)[0];
          const { errors, success } = response.data[mutationName];

          if (success) {
            this.setState({
              loading: false,
              [key]: [arr.slice(0, i), arr.slice(i + 1, arr.length)].flat(),
            });
          } else {
            this.setState({ loading: false });

            window.alert(errors[0].message);
          }
        });
      },
    );
  };

  sendReminder = (sendAll, assessment, type) => {
    const { org, email, firstName, coursesTaken, checklistsTaken, testsTaken } =
      this.state;
    const { attachment, brandColor } = org;

    let tests = [];
    let checklists = [];
    let courses = [];

    if (sendAll) {
      courses.push(
        coursesTaken
          .filter((course) => course.status !== 'finished')
          .map((c) => ({
            title: `${c.title}<br>`,
          })),
      );
      checklists.push(
        checklistsTaken
          .filter((checklist) => checklist.status !== 'finished')
          .map((c) => ({
            title: `${c.title}<br>`,
          })),
      );
      tests.push(
        testsTaken
          .filter((test) => test.status !== 'finished')
          .map((t) => ({
            title: `${t.title}<br>`,
          })),
      );
    } else {
      if (type === 'test') {
        tests.push(assessment);
      } else if (type === 'checklist') {
        checklists.push(assessment);
      } else if (type === 'course') {
        courses.push(assessment);
      }
    }

    const defaultLogo = 'https://res.cloudinary.com/inewton/image/upload/v1/una-app/cache/e80cad79f0f6e17fd3c8254c763c2b8d.png';
    const defaultColor = '#FF5A5F';
    const url = `${process.env.REACT_APP_PROXY_URL}/send`;
    const data = {
      dynamicTemplateData: {
        courses,
        checklists,
        tests,
        logo: attachment ? attachment.url : defaultLogo,
        color: brandColor || defaultColor,
        COLOR: brandColor || defaultColor, //added due to sendgrid template issue
        name: firstName,
        url: `${process.env.REACT_APP_UNA_TEST_URL}`,
      },
      environment: 'production',
      recipient: email,
      templateId: 'd-ed64237a1bbc498ab2c25e92be127dec',
    };

    const headers = {
      Authorization: Cookie.get(`${global.COOKIE_NAME}-token`),
    };

    axios
      .post(url, data, { headers })
      .then(() => {
        window.alert('Reminder sent successfully.');

        this.handleChange('loading', false);
        document.body.click();
      })
      .catch(() => window.alert('Reminder unsuccessful.'));
  };

  render() {
    return this.state.onload ? (
      <LoadingPane />
    ) : (
      <Roster
        goToDocumentRoute={this.goToDocumentRoute}
        goToInviteRoute={this.goToInviteRoute}
        goToItemRoute={this.goToItemRoute}
        goToPacketRoute={this.goToPacketRoute}
        goToRosterRoute={this.goToRosterRoute}
        goToUserRoute={this.goToUserRoute}
        handleChange={this.handleChange}
        params={this.props.params}
        removeItem={this.removeItem}
        sendReminder={this.sendReminder}
        state={this.state}
      />
    );
  }
}

export default compose(
  withRouter,
  graphql(UserQuery, {
    name: 'userQuery',
    options: (props) => ({
      variables: {
        id: props.params.roster,
        org: props.params.id,
      },
    }),
  }),
  graphql(UserChecklistRemoveMutation, { name: 'userChecklistRemoveMutation' }),
  graphql(UserCourseRemoveMutation, { name: 'userCourseRemoveMutation' }),
  graphql(UserTestRemoveMutation, { name: 'userTestRemoveMutation' }),
)(RosterContainer);
