import React from 'react';

import _ from 'lodash';
import moment from 'moment';

import DatePicker from '../../../Shared/DatePicker';
import LoadingPane from '../../../Shared/LoadingPane';
import ModalPane from '../../../Shared/ModalPane';
import FileInput from '../../../Control/FileInput';
import DocumentDisplay from '../../../Control/DocumentDisplay';

import STRINGS from '../../../../localization';

const Passport = (props) => {
  return props.state.edit ? <Edit {...props} /> : <Read {...props} />;
};

const Read = (props) => {
  const { back, documentRemove, handleChange, state } = props;
  const {
    documentNumber,
    expiresOn,
    loading,
    isModal,
    imageUrl,
    imageBackUrl,
    issuedOn,
    mobile,
    user,
    title
  } = state;

  return (
    <div className="document-read-view">
      {loading ? (
        <LoadingPane style={{ background: 'rgba(0,0,0,0.1)' }} />
      ) : null}

      <div className="toolbar">
        {mobile ? (
          <div className="mobile-button bg-grey-2" onClick={back}>
            <div className="mobile-button-icon">
              <img
                src={require('../../../../theme/images/buttons/button-x-icon-2x.png')}
                alt="button-x-icon"
              />
            </div>
          </div>
        ) : (
          <button className="back-button bg-grey-2" onClick={back}>
            {STRINGS.back}
          </button>
        )}

        <div className="page-title">{`${STRINGS.view} ${_.startCase(
          state.kind,
        )}`}</div>
      </div>

      <div className="content-frame scroll">
        <div className={mobile ? 'document-frame mobile' : 'document-frame'}>
          <div className="read-value-frame">
            <div className="value-label capitalize">{`${STRINGS.passport} #`}</div>
            <div className="value-label capitalize bold">{documentNumber}</div>
          </div>

          <div className="read-value-frame">
            <div className="value-label capitalize">{STRINGS.issueDate}</div>
            <div className="value-label capitalize bold">
              {moment(issuedOn).format('MMM DD, YYYY')}
            </div>
          </div>

          <div className="read-value-frame">
            <div className="value-label capitalize">
              {STRINGS.issueExpireDate}
            </div>
            <div className="value-label capitalize bold">
              {moment(expiresOn).format('MMM DD, YYYY')}
            </div>
          </div>

          <div className="document-images-frame">
            <DocumentDisplay
              docUrl={imageUrl}
              link={true}
              title={`${user.firstName}_${user.lastName}_${title}`}
            />
            <DocumentDisplay
              docUrl={imageBackUrl}
              link={true}
              title={`${user.firstName}_${user.lastName}_${title} back`}
            />
          </div>
        </div>
      </div>

      <ModalPane
        className="document-delete-pane"
        content={(hide) => (
          <div>
            <div className="modal-header">
              {STRINGS.documentDeletePaneMessage}
            </div>

            <div className="modal-button-frame">
              <button className="modal-button bg-grey-2" onClick={hide}>
                {STRINGS.back}
              </button>
              <button
                className="modal-button bg-una"
                onClick={() => documentRemove(hide)}
              >
                {STRINGS.save}
              </button>
            </div>
          </div>
        )}
        show={isModal}
        hide={() => handleChange('isModal', false)}
      />
    </div>
  );
};

const Edit = (props) => {
  const { back, handleChange, params, save, state } = props;
  const {
    documentNumber,
    expiresOn,
    image,
    imageBack,
    issuedOn,
    loading,
    imageUrl,
    imageBackUrl,
    mobile,
  } = state;

  const existing = params.document !== 'create';
  const valid = documentNumber && issuedOn && expiresOn;

  return (
    <div className="document-edit-view">
      {loading ? (
        <LoadingPane style={{ background: 'rgba(0,0,0,0.1)' }} />
      ) : null}

      <div className="toolbar">
        {mobile ? (
          <div
            className="mobile-button bg-grey-2"
            onClick={() => (existing ? handleChange('edit', false) : back())}
          >
            <div className="mobile-button-icon">
              <img
                src={require('../../../../theme/images/buttons/button-x-icon-2x.png')}
                alt="button-x-icon"
              />
            </div>
          </div>
        ) : (
          <button
            className="back-button bg-grey-2"
            onClick={() => (existing ? handleChange('edit', false) : back())}
          >
            {STRINGS.back}
          </button>
        )}

        <div className="page-title">{`${
          existing ? STRINGS.edit : STRINGS.add
        } ${_.startCase(state.kind)}`}</div>

        {mobile ? (
          <div className="button-frame">
            <div
              className={`mobile-button save ${
                valid ? 'bg-aqua' : 'bg-disabled'
              }`}
              onClick={save}
            >
              <div className="mobile-button-icon">
                <img
                  src={require('../../../../theme/images/buttons/button-checkmark-icon-2x.png')}
                  alt="button-x-icon"
                />
              </div>
            </div>
          </div>
        ) : (
          <div className="button-frame">
            <button
              className={`save-button ${valid ? 'bg-aqua' : 'bg-disabled'}`}
              onClick={save}
            >
              {STRINGS.save}
            </button>
          </div>
        )}
      </div>

      <div className="content-frame">
        <div className={mobile ? 'document-frame mobile' : 'document-frame'}>
          <div className="key-value-frame">
            <div className="value-label capitalize">{`${STRINGS.passport} #`}</div>

            <input
              className="key-value-input"
              onChange={(e) => handleChange('documentNumber', e.target.value)}
              placeholder={`${STRINGS.enter} ${STRINGS.passport} #`}
              value={documentNumber || ''}
            />
          </div>

          <div className="key-value-frame">
            <div className="value-label capitalize">{STRINGS.issueDate}</div>

            <DatePicker
              defaultValue={issuedOn}
              onChange={(date) => handleChange('issuedOn', date)}
            />
          </div>

          <div className="key-value-frame">
            <div className="value-label capitalize">
              {STRINGS.issueExpireDate}
            </div>

            <DatePicker
              defaultValue={expiresOn}
              future={true}
              futureAmt={10}
              onChange={(date) => handleChange('expiresOn', date)}
            />
          </div>

          <div className="key-value-frame">
            <div className="value-label capitalize">{`${STRINGS.image} 1`}</div>
            <FileInput
              handleChange={handleChange}
              fileType={'image'}
              image={image}
              imageUrl={imageUrl}
              strings={STRINGS}
            />
          </div>

          <div className="key-value-frame">
            <div className="value-label capitalize">{`${STRINGS.image} 2`}</div>
            <FileInput
              handleChange={handleChange}
              fileType={'imageBack'}
              image={imageBack}
              imageUrl={imageBackUrl}
              strings={STRINGS}
            />
          </div>

          <div className="key-value-frame empty" />
        </div>
      </div>
    </div>
  );
};

export default Passport;
