import { gql } from 'apollo-boost';

const OrgReportingQuery = gql`
  query OrgReportingQuery($id: ID!, $first: Int, $after: String) {
    org(id: $id) {
      id
      brandColor
      title
      users(first: $first, after: $after) {
        nodes {
          id
          checklistsTaken(state: "finished") {
            id
            endedAt
            timeSpentSecs
            title
          }
          coursesTaken(state: "finished") {
            id
            endedAt
            timeSpentSecs
            title
          }
          createdAt
          documents {
            nodes {
              id
              expiresOn
              kind
              title
            }
          }
          firstName
          lastName
          testsTaken(state: "finished") {
            id
            endedAt
            timeSpentSecs
            title
          }
        }
        pageInfo {
          hasNextPage
          endCursor
        }
      }
    }
  }
`;

export default OrgReportingQuery;
