import React from 'react';

import _ from 'lodash';

import PhysicalHealthStatement from '../../../../components/Documents/Document/PhysicalHealthStatement';

export default class PhysicalHealthStatementContainer extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      ...props.state,
      edit: props.params.document === 'create',
      isModal: false,
      loading: false,
      user: props.context.user,
    };
  }

  componentDidUpdate() {
    if (!_.isEqual(this.props.mobile, this.state.mobile)) {
      this.handleChange('mobile', this.props.state.mobile);
    }
  }

  documentRemove = (hide) => {
    this.setState(
      {
        loading: true,
      },
      () => this.props.documentRemove(hide),
    );
  };

  handleChange = (key, value) => {
    this.setState(_.set(this.state, key, value));
  };

  save = () => {
    const { save, saveAttachment } = this.props;
    const {
      expiresOn,
      kind,
      image,
      imageBack,
      imageUrl,
      imageBackUrl,
      issuedOn,
    } = this.state;

    const arr = [image || imageUrl, imageBack || imageBackUrl];
    const data = {
      documentNumber: `${arr.filter((o) => o).length} ${
        arr.filter((o) => o).length > 1 ? 'Photos' : 'Photo'
      }`,
      kind,
      issuedOn,
      issuingState: 'n/a',
      title: 'Physical Health Statement',
    };

    if (expiresOn) data.expiresOn = expiresOn;

    if (issuedOn) {
      this.setState(
        {
          loading: true,
        },
        async () => {
          if (image) data.image = await saveAttachment(image);

          if (imageBack) data.imageBack = await saveAttachment(imageBack);

          save(data);
        },
      );
    }
  };

  render() {
    return (
      <PhysicalHealthStatement
        {...this.props}
        documentRemove={this.documentRemove}
        handleChange={this.handleChange}
        save={this.save}
        state={this.state}
      />
    );
  }
}
