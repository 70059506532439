import { gql } from 'apollo-boost';

const OrgUpdateMutation = gql`
  mutation OrgUpdateMutation($input: OrgUpdateInput) {
    orgUpdate(input: $input) {
      errors {
        code
        message
      }
      success
      result {
        id
      }
    }
  }
`;

export default OrgUpdateMutation;
