import React from 'react';
import { graphql } from 'react-apollo';

import compose from 'lodash.flowright';

import OrgsQuery from '../../graphql/queries/Orgs';
import OrgRemoveMutation from '../../graphql/mutations/Org/Remove';

import DashboardClients from '../../components/Dashboard/Clients';

import LoadingPane from '../../components/Shared/LoadingPane';
import { withRouter } from '../withRouter';

let timeout;

class DashboardClientsContainer extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      isModal: false,
      onload: true,
      org: {},
      refreshing: false,
    };
  }

  componentDidMount() {
    const {
      orgsQuery: { loading, refetch },
    } = this.props;
    const { onload } = this.state;

    if (onload && !loading) {
      refetch().then(() => this.setup());
    } else {
      this.setup();
    }
  }

  componentDidUpdate() {
    this.setup();
  }

  setup = () => {
    const {
      navigate,
      params,
      orgsQuery: { loading, orgs, org },
      user,
    } = this.props;
    const { onload } = this.state;

    const hasPermission = user.roles.map((r) => r.name).includes('corporate');

    if (!hasPermission) {
      navigate(`/error?org=${params.id}`);
    } else if (onload && !loading) {
      this.setState({
        ...org,
        onload: false,
        orgs,
      });
    }
  };

  goToOrgRoute = (id) => {
    const { navigate, params } = this.props;

    navigate(`/organizations/${params.id}/facilities/${id || 'create'}`);
  };

  handleChange = (key, value) => {
    this.setState({
      [key]: value,
    });
  };

  handleScroll = (e) => {
    const { orgsQuery } = this.props;
    const {
      orgs: {
        nodes: arr,
        pageInfo: { hasNextPage, endCursor },
      },
      refreshing,
    } = this.state;

    const node = e.target;
    const isBottom = node.scrollTop === node.scrollHeight - node.offsetHeight;

    if (isBottom && hasNextPage && !refreshing) {
      this.setState(
        {
          refreshing: true,
        },
        async () => {
          const result = await orgsQuery.refetch({ after: endCursor });
          const orgs = result.data.orgs;

          this.setState({
            orgs: {
              ...orgs,
              nodes: [...arr, ...orgs.nodes],
            },
            refreshing: false,
          });
        },
      );
    }
  };

  handleSearch = (e) => {
    const { orgsQuery } = this.props;

    const search = e.target.value.toLowerCase();

    clearTimeout(timeout);

    timeout = setTimeout(() => {
      this.setState({
        orgs: {
          ...this.state.orgs,
          nodes: orgsQuery.orgs.nodes.filter((o) =>
            o.title.toLowerCase().includes(search),
          ),
        },
      });
    }, 500);
  };

  removeOrg = (hide) => {
    const { orgRemoveMutation, orgsQuery } = this.props;
    const { org } = this.state;

    this.setState(
      {
        loading: true,
      },
      () => {
        orgRemoveMutation({
          variables: {
            input: {
              id: org.id,
            },
          },
        }).then((response) => {
          const { success, errors } = response.data.orgRemove;

          if (success) {
            orgsQuery.refetch().then((response) => {
              this.handleChange('loading', false);

              hide();

              setTimeout(() => {
                this.setState({
                  isModal: false,
                  orgs: response.data.orgs,
                });
              }, 300);
            });
          } else {
            this.setState(
              {
                loading: false,
              },
              () => window.alert(errors[0].message),
            );
          }
        });
      },
    );
  };

  toggleModal = (org, isModal) => {
    document.body.click();

    this.setState({ org, isModal });
  };

  render() {
    return this.state.onload ? (
      <LoadingPane />
    ) : (
      <DashboardClients
        goToOrgRoute={this.goToOrgRoute}
        handleScroll={this.handleScroll}
        handleSearch={this.handleSearch}
        removeOrg={this.removeOrg}
        state={this.state}
        toggleModal={this.toggleModal}
      />
    );
  }
}

export default compose(
  withRouter,
  graphql(OrgsQuery, {
    name: 'orgsQuery',
    options: (props) => ({
      variables: {
        id: props.params.id,
        order: 'title',
        first: 50,
        after: '',
      },
    }),
  }),
  graphql(OrgRemoveMutation, { name: 'orgRemoveMutation' }),
)(DashboardClientsContainer);
