import React from 'react';
import { graphql } from 'react-apollo';
import compose from 'lodash.flowright';

import OrgQuery from '../../graphql/queries/Org/Bundles';
import BundleRemoveMutation from '../../graphql/mutations/Bundle/Remove';

import Bundles from '../../components/Dashboard/Bundles';
import { withRouter } from '../withRouter';

import LoadingPane from '../../components/Shared/LoadingPane';

class BundlesContainer extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      bundles: {
        nodes: [],
      },
      onload: true,
    };
  }

  componentDidMount() {
    const {
      orgQuery: { loading, refetch },
    } = this.props;
    const { onload } = this.state;

    if (onload && !loading) {
      refetch().then(() => this.setup());
    } else {
      this.setup();
    }
  }

  componentDidUpdate() {
    this.setup();
  }

  setup = () => {
    const {
      orgQuery: { loading, org },
    } = this.props;
    const { onload } = this.state;

    if (onload && !loading) {
      this.setState({
        ...org,
        onload: false,
      });
    }
  };

  contentRemove = (hide) => {
    const { bundleRemoveMutation } = this.props;
    const { bundles, bundle } = this.state;

    this.setState(
      {
        loading: true,
      },
      () => {
        const arr = bundles;
        const i = arr.findIndex((b) => b.id === bundle.id);
        const arrWithoutItem = [
          ...arr.slice(0, i),
          ...arr.slice(i + 1, arr.length),
        ];

        bundleRemoveMutation({
          variables: {
            input: {
              id: bundle.id,
            },
          },
        }).then((response) => {
          const { errors, success } = response.data.bundleRemove;

          if (success) {
            this.handleChange('bundles', arrWithoutItem);

            hide();

            setTimeout(() => this.toggleModal(), 350);
          } else {
            this.handleChange('loading', false);

            window.alert(errors[0].message);
          }
        });
      },
    );
  };

  goToBundleRoute = (id) => {
    const { navigate, params } = this.props;

    navigate(`/organizations/${params.id}/bundles/${id || 'create'}`);
  };

  handleChange = (key, value) => {
    this.setState({
      [key]: value,
    });
  };

  toggleModal = (bundle) => {
    document.body.click();

    this.setState({
      bundle: bundle || null,
      isModal: !!bundle,
      loading: false,
    });
  };

  render() {
    return this.state.onload ? (
      <LoadingPane />
    ) : (
      <Bundles
        contentRemove={this.contentRemove}
        goToBundleRoute={this.goToBundleRoute}
        state={this.state}
        toggleModal={this.toggleModal}
      />
    );
  }
}

export default compose(
  withRouter,
  graphql(OrgQuery, {
    name: 'orgQuery',
    options: (props) => ({
      variables: {
        id: props.params.id,
      },
    }),
  }),
  graphql(BundleRemoveMutation, { name: 'bundleRemoveMutation' }),
)(BundlesContainer);
