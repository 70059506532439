import React from 'react';

const NotFound = (props) => {
  return (
    <div className="not-found-view full-screen-view">
      <div className="content-frame layout-center">
        <div className="not-found-label">Error: 404</div>

        <div className="not-found-label">
          Resource is no longer available or invalid.
        </div>

        <button className="not-found-button" onClick={props.goToBaseRoute}>
          Home
        </button>
      </div>
    </div>
  );
};

export default NotFound;
