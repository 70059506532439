import { gql } from 'apollo-boost';

const ChecklistSectionCreateMutation = gql`
  mutation ChecklistSectionCreateMutation($input: ChecklistSectionCreateInput) {
    checklistSectionCreate(input: $input) {
      errors {
        message
      }
      result {
        id
        questions {
          id
          rank
          title
        }
        rank
        title
      }
    }
  }
`;

export default ChecklistSectionCreateMutation;
