import React from 'react';
import { graphql } from 'react-apollo';
import compose from 'lodash.flowright';

import _ from 'lodash';

import CourseQuery from '../../graphql/queries/Course';

import CourseActivateMutation from '../../graphql/mutations/Courses/Activate';
import CourseCreateMutation from '../../graphql/mutations/Courses/Create';
import CourseDeactivateMutation from '../../graphql/mutations/Courses/Deactivate';
import CourseUpdateMutation from '../../graphql/mutations/Courses/Update';
import CourseChapterCreateMutation from '../../graphql/mutations/Courses/Chapter/Create';
import CourseChapterRemoveMutation from '../../graphql/mutations/Courses/Chapter/Remove';
import CourseChapterUpdateMutation from '../../graphql/mutations/Courses/Chapter/Update';
import CourseQuestionCreateMutation from '../../graphql/mutations/Courses/Question/Create';

import Course from '../../components/Course';

import LoadingPane from '../../components/Shared/LoadingPane';

import { createNumericOptions } from '../../utils/HelperMethods';
import { withRouter } from '../withRouter';

class CourseContainer extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      active: false,
      chapter: {},
      chapters: [],
      days: createNumericOptions(0, 365, false),
      loading: false,
      onload: true,
      preview: false,
      scores: createNumericOptions(60, 100, true),
      mappingOptions: [
        { value: 'normal', label: 'Normal' },
        { value: 'ecp_job', label: 'ECP Jobs Assessment ' },

      ],
    };
    console.log("mappingOptions course",this.state.mappingOptions)
  }

  componentDidMount() {
    const {
      courseQuery: { loading, refetch },
    } = this.props;
    const { onload } = this.state;

    if (onload && !loading) {
      refetch().then(() => this.setup());
    } else {
      this.setup();
    }
  }

  componentDidUpdate() {
    this.setup();
  }

  setup = () => {
    const {
      courseQuery: { loading, course },
      location,
      navigate,
      context: { user },
    } = this.props;
    const { onload } = this.state;

    if (onload && !loading) {
      const org = location.state ? location.state.org : null;
      const role = user.roles.find((r) => r.org.id === org);
      const admin = role ? role.name === 'corporate' : false;

      if (course && course.kind === 'global' && !admin) {
        navigate(`/error${org ? `?org=${org}` : ''}`);
      } else {
        this.setState({
          ...course,
          active: course ? course.status === 'active' : false,
          admin,
          chapters: course ? _.sortBy(course.chapters, ['rank']) : [],
          onload: false,
          questions: course
            ? course.chapters.map((c) => c.questions).flat()
            : [],
        });
      }
    }
  };

  addChapter = () => {
    const chapters = this.state.chapters;
    const index = chapters.length + 1;
    const chapter = {
      id: `create-${index}`,
      questions: [],
    };

    chapters.push(chapter);

    this.setState({
      chapter,
      chapters,
    });
  };

   back = () => {
    const { location, navigate } = this.props;

    if (location.state) {
      navigate(location.state.previous);
    } else {
      this.goToRoute(`/organizations/${location.state.org}/mandatories`);
    }
  };

  cancelChapter = () => {
    const {
      chapters,
      chapter: { id },
    } = this.state;

    const i = chapters.findIndex((c) => c.id === id);

    if (id.includes('create')) {
      this.setState({
        chapter: {},
        chapters: [
          ...chapters.slice(0, i),
          ...chapters.slice(i + 1, chapters.length),
        ],
      });
    } else {
      this.handleChange('chapter', {});
    }
  };

  checkStatus = (course) => {
    const { courseActivateMutation, courseDeactivateMutation } = this.props;
    const { active, chapters } = this.state;

    let mutation;
    const status = course.status;

    if (active && status === 'inactive') {
      mutation = courseActivateMutation;
    } else if (!active && status === 'active') {
      mutation = courseDeactivateMutation;
    }

    if (mutation) {
      mutation({
        variables: {
          input: {
            id: course.id,
          },
        },
      }).then((response) => {
        const key = Object.keys(response.data);
        const errors = response.data[key].errors;

        if (errors) {
          this.handleChange('loading', false);

          window.alert(errors[0].message);
        } else {
          this.updateChapters(chapters, 0);
        }
      });
    } else {
      this.updateChapters(chapters, 0);
    }
  };

  createCourse = () => {
    const { courseCreateMutation, location } = this.props;
    const { desc, durationMins, kind, passingScore, title,courseType } = this.state;

    courseCreateMutation({
      variables: {
        input: {
          desc,
          courseType,
          // durationMins: moment.duration(durationMins, 'days').asMinutes(),
          durationMins,
          kind,
          orgId: location.state.org,
          passingScore,
          title,
        },
      },
    }).then((response) => {
      const {
        data: {
          courseCreate: { errors, result },
        },
      } = response;

      if (errors) {
        this.handleChange('loading', false);

        window.alert(errors[0].message);
      } else {
        this.checkStatus(result);
      }
    });
  };

  deleteChapter = (id, i) => {
    const { courseChapterRemoveMutation } = this.props;

    const arr = this.state.chapters;

    document.body.click();

    this.setState(
      {
        loading: true,
      },
      () => {
        courseChapterRemoveMutation({
          variables: {
            input: {
              id,
            },
          },
        }).then((response) => {
          const {
            data: {
              courseChapterRemove: { errors },
            },
          } = response;

          if (errors) {
            window.alert(errors[0].message);
          } else {
            this.setState({
              chapters: [...arr.slice(0, i), ...arr.slice(i + 1, arr.length)],
              loading: false,
            });
          }
        });
      },
    );
  };

  duplicateChapter = (chapter) => {
    const { courseChapterCreateMutation } = this.props;

    document.body.click();

    this.setState(
      {
        loading: true,
      },
      () => {
        courseChapterCreateMutation({
          variables: {
            input: {
              courseId: this.state.id,
              title: `${chapter.title} Copy`,
            },
          },
        }).then((response) => {
          const {
            data: {
              courseChapterCreate: { errors, result },
            },
          } = response;

          if (errors) {
            window.alert(errors[0].message);
          } else {
            this.duplicateQuestions(chapter.questions, 0, result);
          }
        });
      },
    );
  };

  duplicateQuestions = (arr, i, chapter) => {
    if (i === arr.length) {
      this.setState({
        loading: false,
        chapters: [...this.state.chapters, chapter],
      });
    } else {
      const { courseQuestionCreateMutation } = this.props;
      const { answers, isRandomized, rank, title } = arr[i];

      courseQuestionCreateMutation({
        variables: {
          input: {
            chapterId: chapter.id,
            answers,
            isRandomized,
            rank,
            title,
          },
        },
      }).then(() => {
        this.duplicateQuestions(arr, i + 1, chapter);
      });
    }
  };

  goToChapterRoute = (id) => {
    const { navigate, location, params } = this.props;

    navigate(`/courses/${params.id}/chapters/${id}`, {
      state: {
        previous: location.pathname,
        org: location.state.org,
      },
    });
  };

  goToQuestionRoute = (id) => {
    const { navigate, params } = this.props;

    navigate(`/courses/${params.id}/questions/${id || 'create'}`);
  };

  goToRoute = (route) => {
    this.props.navigate(route);
  };

  handleChange = (key, value) => {
    this.setState(_.set(this.state, key, value));
  };

  save = () => {
    const { artwork, id, title } = this.state;

    if (title) {
      this.setState(
        {
          loading: true,
        },
        () => {
          if (artwork) {
            this.uploadFile(artwork);
          } else if (id) this.updateCourse();
          else this.createCourse();
        },
      );
    } else {
      window.alert('Title is required.');
    }
  };

  saveChapter = (rank) => {
    const { courseChapterCreateMutation, courseChapterUpdateMutation } =
      this.props;
    const {
      chapters,
      chapter: { title, id: chapterId },
      id,
    } = this.state;

    if (title) {
      const isCreate = chapterId.includes('create');
      const mutation = isCreate
        ? courseChapterCreateMutation
        : courseChapterUpdateMutation;
      const data = {
        title,
        rank: rank + 1,
      };

      if (isCreate) data.courseId = id;
      else data.id = chapterId;

      this.setState(
        {
          loading: true,
        },
        () => {
          mutation({
            variables: {
              input: data,
            },
          }).then((res) => {
            const key = Object.keys(res.data)[0];
            const response = res.data[key];

            if (response.errors) {
              window.alert(response.errors[0].message);
            } else {
              this.setState({
                chapter: {},
                chapters: [
                  ...chapters.slice(0, rank),
                  response.result,
                  ...chapters.slice(rank + 1, chapters.length),
                ],
                loading: false,
              });
            }
          });
        },
      );
    } else {
      window.alert('Chapter requires a title.');
    }
  };

  updateChapters = (arr, index) => {
    const { courseChapterUpdateMutation } = this.props;

    if (index === arr.length) {
      this.back();
    } else {
      courseChapterUpdateMutation({
        variables: {
          input: {
            id: arr[index].id,
            rank: index + 1,
          },
        },
      }).then(() => this.updateChapters(arr, index + 1));
    }
  };

  updateCourse = () => {
    const { courseUpdateMutation, location } = this.props;
    const { artworkJSON, desc, durationMins, kind, id, passingScore, title ,courseType} =
      this.state;

    courseUpdateMutation({
      variables: {
        input: {
          id,
          desc,
          courseType,
          // durationMins: moment.duration(durationMins, 'days').asMinutes(),
          durationMins,
          kind,
          orgId: location.state.org,
          passingScore,
          title,
          artwork: artworkJSON,
        },
      },
    }).then((response) => {
      const {
        data: {
          courseUpdate: { errors },
        },
      } = response;

      if (errors) {
        this.handleChange('loading', false);

        window.alert(errors[0].message);
      } else {
        this.checkStatus(this.state);
      }
    });
  };

  uploadFile = (file) => {
    const { id } = this.state;

    const that = this;
     const xhr = new XMLHttpRequest();
    const formData = new FormData();
    const url = process.env.REACT_APP_ATTACHMENT_URL;

    formData.append('file', file);

    xhr.open('POST', url);

    xhr.onreadystatechange = () => {
      if (xhr.readyState === 4) {
        const response = JSON.parse(xhr.response);

        that.handleChange('artworkJSON', response);

        if (id) that.updateCourse();
        else that.createCourse();
      }
    };

    xhr.send(formData);
  };

  render() {
    return this.state.onload ? (
      <LoadingPane />
    ) : (
      <Course
        {...this.props}
        addChapter={this.addChapter}
        back={this.back}
        cancelChapter={this.cancelChapter}
        deleteChapter={this.deleteChapter}
        duplicateChapter={this.duplicateChapter}
        goToChapterRoute={this.goToChapterRoute}
        goToRoute={this.goToRoute}
        handleChange={this.handleChange}
        save={this.save}
        saveChapter={this.saveChapter}
        state={this.state}
      />
    );
  }
}

export default compose(
  withRouter,
  graphql(CourseQuery, {
    name: 'courseQuery',
    options: (props) => ({
      variables: {
        id: props.params.id,
      },
    }),
  }),
  graphql(CourseActivateMutation, { name: 'courseActivateMutation' }),
  graphql(CourseCreateMutation, { name: 'courseCreateMutation' }),
  graphql(CourseDeactivateMutation, { name: 'courseDeactivateMutation' }),
  graphql(CourseUpdateMutation, { name: 'courseUpdateMutation' }),
  graphql(CourseChapterCreateMutation, { name: 'courseChapterCreateMutation' }),
  graphql(CourseChapterRemoveMutation, { name: 'courseChapterRemoveMutation' }),
  graphql(CourseChapterUpdateMutation, { name: 'courseChapterUpdateMutation' }),
  graphql(CourseQuestionCreateMutation, {
    name: 'courseQuestionCreateMutation',
  }),
)(CourseContainer);
