import React from 'react';

import LoadingPane from '../../components/Shared/LoadingPane';
import ModalPane from '../../components/Shared/ModalPane';
import Toolbar from '../../components/Shared/Toolbar';
import Edit from './Edit';

const Profile = (props) => {
  const { cancel, handleChange, reset, resetPassword, state } = props;
  const { error, isEdit, isModal, loading } = state;

  return (
    <div className="profile-view">
      {loading ? (
        <LoadingPane style={{ background: 'rgba(0,0,0,0.1)' }} />
      ) : null}

      {isEdit ? (
        <Edit {...props} />
      ) : (
        <div className="profile-read-view">
          <div className="toolbar">
            <button className="cancel-button" onClick={cancel}>
              Cancel
            </button>

            <label className="page-header">My Profile</label>
          </div>

          <div className="content-frame scroll">
            <div className="centered-frame">
              <div className="section-header-frame">
                <div className="section-header">Account Details</div>

                <div className="flex-frame">
                  <button
                    className="section-button bg-teal"
                    onClick={() => handleChange('isModal', true)}
                  >
                    Change Password
                  </button>
                  <button
                    className="section-button bg-red"
                    onClick={() => handleChange('isEdit', true)}
                  >
                    Edit Account
                  </button>
                </div>
              </div>

              <div className="form-frame">
                <div className="form-input-frame">
                  <div className="form-value">First Name</div>
                  <div className="form-value bold">{state.firstName}</div>
                </div>

                <div className="form-input-frame">
                  <div className="form-value">Last Name</div>
                  <div className="form-value bold">{state.lastName}</div>
                </div>

                <div className="form-input-frame">
                  <div className="form-value">Email</div>
                  <div className="form-value bold">{state.email}</div>
                </div>

                <div className="form-input-frame">
                  <div className="form-value">Phone Number</div>

                  {state.phoneNumber ? (
                    <div className="form-value bold">{state.phoneNumber}</div>
                  ) : (
                    <div className="form-value hint">None Provided</div>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      )}

      <ModalPane
        className="reset-password-pane"
        content={(hide) => (
          <div className="flex-frame">
            <Toolbar
              cancel={hide}
              title="Change Password"
              save={() => resetPassword(hide)}
            />

            <div className="modal-content-frame">
              <div className="layout-center">
                <div className="form-input-frame margin">
                  <div className="form-input-header">Current Password</div>

                  <input
                    autoFocus
                    className="form-input"
                    onChange={(e) => handleChange('password', e.target.value)}
                    placeholder="Enter Current Password"
                    type="password"
                  />
                </div>

                <div className="form-input-frame margin">
                  <div className="form-input-header">New Password</div>

                  <input
                    className="form-input"
                    onChange={(e) => handleChange('password1', e.target.value)}
                    placeholder="Enter New Password"
                    type="password"
                  />
                </div>

                <div className="form-input-frame">
                  <div className="form-input-header">Re-Enter New Password</div>

                  <input
                    className="form-input"
                    onChange={(e) => handleChange('password2', e.target.value)}
                    placeholder="Enter New Password"
                    type="password"
                  />
                </div>

                <div className={error ? 'error-label red' : 'hide'}>
                  {error}
                </div>
              </div>
            </div>
          </div>
        )}
        hide={reset}
        show={isModal}
      />
    </div>
  );
};

export default Profile;
