import React from 'react';

import { uniqBy } from 'lodash';

import Checkbox from '../Shared/Checkbox';
import LoadingPane from '../Shared/LoadingPane';
// import SelectMenu from '../Shared/SelectMenu'
import Toolbar from '../Shared/Toolbar';

const Bundle = (props) => {
  const { goToBundlesRoute, handleChange, handleSelection, save, state } =
    props;
  const {
    bundle,
    checklists,
    courses,
    loading,
    selectedTests,
    selectedChecklists,
    selectedCourses,
    /*specialties,*/ tests,
  } = state;

  return (
    <div className="bundle-view full-screen-view">
      {loading ? (
        <LoadingPane style={{ background: 'rgba(0,0,0,0.1)' }} />
      ) : null}

      <Toolbar
        {...props}
        cancel={goToBundlesRoute}
        isToggle={false}
        save={save}
        title={bundle ? 'Edit Bundle' : 'Add New Bundle'}
      />

      <div className="content-frame scroll">
        <div className="centered-frame">
          <div className="header">Bundle Details</div>

          <div className="form-frame">
            <div className="form-input-frame">
              <div className="input-title">Title</div>

              <input
                className="form-input"
                defaultValue={bundle ? bundle.title : ''}
                onChange={(e) => handleChange('bundle.title', e.target.value)}
                placeholder="Enter Title of Bundle"
              />
            </div>

            {/* 
                        <div className='form-input-frame'>
                            <div className='input-title'>Kind</div>

                            <SelectMenu
                                className='input-menu'
                                onChange={e => handleChange('bundle.kind', e.value)}
                                options={specialties.map(specialty => ({ value: specialty.name, label: specialty.name }))}
                                placeholder='Select Kind'
                                value={bundle ? bundle.kind : 'hint'}
                            />
                        </div> 
                        */}

            <div className="form-input-frame"></div>

            <div className="form-input-frame"></div>
          </div>

          <div className={tests.nodes.length > 0 ? 'section-frame' : 'hide'}>
            <div className="header">{`Tests (${selectedTests.length})`}</div>

            <ul
              className={
                tests.nodes.length > 1
                  ? 'section-list-view columns'
                  : 'section-list-view'
              }
            >
              {uniqBy(tests.nodes, 'id').map((obj) => {
                const { id, title } = obj;

                return (
                  <li
                    className="section-cell"
                    key={id}
                    onClick={() => handleSelection('selectedTests', id)}
                  >
                    <Checkbox
                      checked={selectedTests.includes(id)}
                      readOnly={true}
                    />

                    <div className="cell-label">{title}</div>
                  </li>
                );
              })}
            </ul>
          </div>

          <div
            className={checklists.nodes.length > 0 ? 'section-frame' : 'hide'}
          >
            <div className="header">{`Skills Checklists (${selectedChecklists.length})`}</div>

            <ul
              className={
                checklists.nodes.length > 1
                  ? 'section-list-view columns'
                  : 'section-list-view'
              }
            >
              {uniqBy(checklists.nodes, 'id').map((obj) => {
                const { id, title } = obj;

                return (
                  <li
                    className="section-cell"
                    key={id}
                    onClick={() => handleSelection('selectedChecklists', id)}
                  >
                    <Checkbox
                      checked={selectedChecklists.includes(id)}
                      readOnly={true}
                    />

                    <div className="cell-label">{title}</div>
                  </li>
                );
              })}
            </ul>
          </div>

          <div
            className={courses.nodes.length > 0 ? 'section-frame' : 'hide'}
            style={{ paddingBottom: 40 }}
          >
            <div className="header-frame">
              <div className="header">{`Mandatories (${selectedCourses.length})`}</div>

              {courses.nodes.length === selectedCourses.length ? (
                <button
                  className="select-all-button"
                  onClick={() => handleChange('selectedCourses', [])}
                >
                  Unselect All
                </button>
              ) : (
                <button
                  className="select-all-button"
                  onClick={() =>
                    handleChange(
                      'selectedCourses',
                      courses.nodes.map((o) => o.id),
                    )
                  }
                >
                  Select All
                </button>
              )}
            </div>

            <ul
              className={
                courses.nodes.length > 1
                  ? 'section-list-view columns'
                  : 'section-list-view'
              }
            >
              {uniqBy(courses.nodes, 'id').map((obj) => {
                const { id, title } = obj;

                return (
                  <li
                    className="section-cell"
                    key={id}
                    onClick={() => handleSelection('selectedCourses', id)}
                  >
                    <Checkbox
                      checked={selectedCourses.includes(id)}
                      readOnly={true}
                    />

                    <div className="cell-label">{title}</div>
                  </li>
                );
              })}
            </ul>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Bundle;
