import { gql } from 'apollo-boost';

const UserCourseCreateMutation = gql`
  mutation UserCourseCreateMutation($input: CourseUserCourseCreateCreateInput) {
    userCourseCreate(input: $input) {
      errors {
        code
        message
      }
      success
    }
  }
`;

export default UserCourseCreateMutation;
