import { gql } from 'apollo-boost';

const OrgContentCreateMutation = gql`
  mutation OrgContentCreateMutation($input: OrgContentAddInput) {
    orgContentAdd(input: $input) {
      errors {
        code
        message
      }
      success
    }
  }
`;

export default OrgContentCreateMutation;
