import React from 'react';
import { OverlayTrigger, Popover } from 'react-bootstrap';

import LoadingPane from '../Shared/LoadingPane';
import Toolbar from '../Shared/Toolbar';

import DragList from '../../containers/Shared/DragList';

const Category = (props) => {
  const {
    deleteQuestion,
    duplicateQuestion,
    goToQuestionRoute,
    goToTestRoute,
    handleChange,
    save,
    state,
  } = props;
  const { loading, questions, testTitle, title } = state;

  return (
    <div className="category-view full-screen-view">
      {loading ? (
        <LoadingPane style={{ background: 'rgba(0,0,0,0.1)' }} />
      ) : null}

      <Toolbar
        cancel={goToTestRoute}
        title={title}
        subTitle={`UNA Test - ${testTitle}`}
        save={() => save(questions, 0)}
      />

      <div className="content-frame">
        <div className="centered-frame">
          <div className="header-frame">
            <div className="header black">{`Questions (${questions.length})`}</div>

            <button
              className="header-button"
              onClick={() => goToQuestionRoute()}
            >
              + Add Question
            </button>
          </div>

          {questions.length > 0 ? (
            <div className="questions-table-view">
              <div className="table-headers">
                <div className="table-header title">Question Title</div>
                <div className="table-header options">Answer Options</div>
              </div>

              <DragList
                onChange={(arr) => handleChange('questions', arr)}
                data={questions.map((q, i) => {
                  const { answers, id, title } = q;

                  const last = i === questions.length - 1;
                  const regex = /<\/?[a-z][\s\S]*>/i;
                  const isHTML = regex.test(q.title);
                  const div = document.createElement('div');

                  if (isHTML) div.innerHTML = q.title;

                  return {
                    ...q,
                    content: (
                      <div
                        className="question-cell"
                        key={id}
                        style={{ borderWidth: last ? 0 : 1 }}
                      >
                        <div className="cell-content-frame">
                          <div className="drag-icon">
                            <img
                              draggable={false}
                              src={require('../../theme/images/hashes.png')}
                              alt="hashes-icon"
                            />
                          </div>

                          <div className="cell-label title">
                            {isHTML ? div.textContent || div.innerText : title}
                          </div>

                          <div className="cell-label options">
                            {answers.length}
                          </div>
                        </div>

                        <OverlayTrigger
                          trigger="click"
                          placement="bottom"
                          rootClose
                          overlay={
                            <Popover
                              id="menu-pane"
                              className="menu-pane category"
                            >
                              <div
                                className="menu-content-view"
                                style={{ padding: '5px 0px' }}
                              >
                                <button
                                  className="menu-button teal"
                                  onClick={() => goToQuestionRoute(id)}
                                >
                                  View Question Details
                                </button>
                                <button
                                  className="menu-button teal"
                                  onClick={() => duplicateQuestion(q)}
                                >
                                  Duplicate
                                </button>
                                <button
                                  className="menu-button red"
                                  onClick={() => deleteQuestion(id, i)}
                                >
                                  Delete
                                </button>
                              </div>
                            </Popover>
                          }
                        >
                          <div className="expander-icon">
                            <img
                              src={require('../../theme/images/expander-icon-grey-2x.png')}
                              alt="expander-icon"
                            />
                          </div>
                        </OverlayTrigger>
                      </div>
                    ),
                  };
                })}
              />
            </div>
          ) : (
            <div className="empty-hint hint">No questions have been added.</div>
          )}
        </div>
      </div>
    </div>
  );
};

export default Category;
