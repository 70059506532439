import { gql } from 'apollo-boost';

const UserChecklistRemoveMutation = gql`
  mutation UserChecklistRemoveMutation($input: IDInput) {
    userChecklistRemove(input: $input) {
      errors {
        code
        message
      }
      success
    }
  }
`;

export default UserChecklistRemoveMutation;
