import React, { useEffect, useState } from "react";
import { useQuery } from "react-apollo";
import JobPostQuery from "../../graphql/queries/JobPost";
import { useNavigate, useParams } from "react-router";
import Toolbar from "../Shared/Toolbar";

const JobPost = () => {
  const [jobPost, setJobPost] = useState();
  const { id, job } = useParams()
  const navigate = useNavigate();

  const { loading, data } = useQuery(JobPostQuery, {
    variables: { id: job }
  });


  useEffect(() => {
    if (!loading) {
      setJobPost(data?.jobPost);
    }
  }, [loading, data]);

  const goToJobPostsRoute = () => {
    navigate(`/organizations/${id}/jobposts`);
  };

  return (
    <>
      <div className="job-post-view">
        <Toolbar
          back={goToJobPostsRoute}
          isToggle={false}
          // save={save}
          title={'Job Post'}
        />
        {jobPost && (
          <div className="content-frame scroll">
            <div className="centered-frame">
              <div className="main-content-frame">
                <div className="title">{jobPost.title}</div>
                <div className="content">
                  <span className="field-title">Address:</span> {jobPost.locationAddress}
                </div>
                <div className="content">
                  <span className="field-title">Post Expiry Date:</span> {jobPost.jobExpire}
                </div>
              </div>

              <div className="job-details-view">
                <div className="left-section inner-frame">
                  <div className="job-detail-grid">
                    <div className="block-small border">
                      <div className="field-title">Job ID:</div>
                      <div>{jobPost.ecpJobId}</div>
                    </div>
                    <div className="block-small border">
                      <div className="field-title">Type:</div>
                      <div>{jobPost.jobType}</div>
                    </div>
                    <div className="block-small border">
                      <div className="field-title">Salary:</div>
                      <div>{jobPost.salaryType}</div>
                    </div>
                    <div className="block-small border">
                      <div className="field-title">Position:</div>
                      <div>{jobPost.position}</div>
                    </div>
                    <div className="block-small border">
                      <div className="field-title">Experience:</div>
                      <div>{jobPost.experience}</div>
                    </div>
                    <div className="block-small border">
                      <div className="field-title">Location:</div>
                      <div>{jobPost.locationType}</div>
                    </div>
                    <div className="block-small border">
                      <div className="field-title">Benefits:</div>
                      {/* <div dangerouslySetInnerHTML={{ __html: jobPost.benefits }} /> */}
                      <div>{jobPost.benefits}</div>
                    </div>
                  </div>
                  <div className="description-frame">
                    <div className="field-title">Job Description </div>
                    <div dangerouslySetInnerHTML={{ __html: jobPost.jobDescription }} />
                  </div>
                  <div className="description-frame">
                    <div className="field-title">Office</div>
                    <div dangerouslySetInnerHTML={{ __html: jobPost.officeDescription }} />
                  </div>
                  <div className="description-frame">
                    <div className="field-title">Candidate Description </div>
                    <div dangerouslySetInnerHTML={{ __html: jobPost.candidateDescription }} />
                  </div>
                  <div className="description-frame ">
                    <div className="field-title">Candidate Requirements </div>
                    <div dangerouslySetInnerHTML={{ __html: jobPost.candidateRequirements }} />
                  </div>
                </div>
                <div className="right-section inner-frame">
                  <div className="description-frame" style={{ marginTop: 0 }}>
                    <div className="field-title">Hiring Manager </div>
                    <div> {jobPost.hiringManagerName} - {jobPost.hiringManagerTitle} </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}
      </div >
    </>
  )
}

export default JobPost;