import React, { useEffect, useState } from 'react';

import Checkbox from '../Shared/Checkbox';
import LoadingPane from '../Shared/LoadingPane';
import Switch from '../Shared/Switch';
import { useNavigate, useParams } from 'react-router-dom';
import { useStripe, useElements } from '@stripe/react-stripe-js';

const headerHeight = 53;

const RosterInvite = (props) => {
  const {
    goToRosterRoute,
    handleChange,
    handleSelectAll,
    handleSelection,
    refs,
    save,
    state,
  } = props;
  const {
    active,
    bundles,
    courses,
    checklists,
    documents,
    expandTests,
    expandChecklists,
    expandCourses,
    expandBundles,
    expandDocuments,
    loading,
    tests,
    user,
  } = state;

  const attachment = user.attachment;
  const userSubscription = user.userSubscription;
  const navigate = useNavigate();
  const { id , roster } = useParams();
  const stripe = useStripe();
  const [Alert, setAlert] = useState('');

  const total =
    tests.nodes.length +
    checklists.nodes.length +
    courses.nodes.length +
    documents.nodes.length;

  const handleNavigation = () => {
    navigate(`/organizations/${id}/roster/${user.id}/subscribe`);
  };

  useEffect(() => {
    if (!stripe) {
      return;
    }

    const clientSecret = new URLSearchParams(window.location.search).get(
      'payment_intent_client_secret',
    );

    if (!clientSecret) {
      return;
    }
    stripe.retrievePaymentIntent(clientSecret).then(({ paymentIntent }) => {
      switch (paymentIntent.status) {
        case 'succeeded':
          setAlert('Payment succeeded!');
          break;
        case 'processing':
          setAlert('Your payment is processing.');
          break;
        case 'requires_payment_method':
          setAlert('Your payment was not successful, please try again.');
          break;
        default:
          setAlert('Something went wrong.');
          break;
      }
    });
  }, [stripe]);

  const onCloseAlert = () => {
    setAlert('');
    navigate(`/organizations/${id}/roster/${roster}/invite`);
  };

  return (
    <div className="roster-invite-view full-screen-view">
      {loading ? (
        <LoadingPane style={{ background: 'rgba(0,0,0,0.1)' }} />
      ) : null}

      {Alert && (
        <div className="modaloverlay">
          <div className="modal" style={{ width: '20%' }}>
            <h2>Alert</h2>
            <div
              className="model-body"
              dangerouslySetInnerHTML={{ __html: Alert }}
              style={{ display: 'inline' }}
            >
              {/* {Alert} */}
            </div>
            <div>
              <button
                type="submit"
                style={{ width: '20%' }}
                className="model-submit-button"
                onClick={onCloseAlert}
              >
                OK
              </button>
            </div>
          </div>
        </div>
      )}

      <div className="toolbar">
        <button className="cancel-button" onClick={goToRosterRoute}>
          Back
        </button>

        <div className="header-frame">
          <div className="header-label bold">Create Invite - </div>

          <div className="avatar">
            <img
              src={
                attachment
                  ? attachment.url
                  : require('../../theme/images/profile-icon-2x.png')
              }
              alt="profile-icon"
            />
          </div>

          <div className="header-label">{`${user.firstName} ${user.lastName}`}</div>
        </div>

        <div className="switch-frame">
          <Switch
            defaultValue={active}
            onChange={(enabled) => handleChange('active', enabled)}
          />

          <div className={`switch-label ${active ? 'black' : 'hint'}`}>
            Notify Me
          </div>
        </div>

        <button className="save-button" onClick={save}>
          Send
        </button>
      </div>

      <div className="content-frame scroll">
        {total > 0 ? (
          <div className="centered-frame">
            <div
              className={bundles.length > 0 ? 'section-frame' : 'hide'}
              style={{
                height: expandBundles
                  ? headerHeight +
                    7.5 +
                    refs.expandBundles.getBoundingClientRect().height
                  : headerHeight,
              }}
            >
              <div className="section-header-frame">
                <div className="section-header">{`Bundles`}</div>

                <div className="flex-frame">
                  <button
                    className="resize-button bold blue"
                    onClick={() =>
                      handleChange('expandBundles', !expandBundles)
                    }
                  >
                    {expandBundles ? 'Collapse' : 'Expand'}
                  </button>
                </div>
              </div>

              <ul
                className="section-list-view"
                ref={(ref) => (refs.expandBundles = ref)}
              >
                {bundles.map((o) => {
                  const { id, title } = o;

                  return (
                    <li
                      className="section-cell"
                      key={id}
                      onClick={(e) =>
                        handleSelection(e, 'selectedBundles', id, o)
                      }
                    >
                      <Checkbox
                        checked={state.selectedBundles.includes(id)}
                        readOnly={true}
                      />

                      <div className="section-label">{title}</div>
                    </li>
                  );
                })}
              </ul>
            </div>

            <div
              className={tests.nodes.length > 0 ? 'section-frame' : 'hide'}
              style={{
                height: expandTests
                  ? headerHeight +
                    7.5 +
                    refs.expandTests.getBoundingClientRect().height
                  : headerHeight,
              }}
            >
              <div className="section-header-frame">
                <div className="section-header">{`Tests (${state.selectedTests.length})`}</div>

                <div className="flex-frame">
                  <button
                    className="select-all-button orange"
                    onClick={() =>
                      handleSelectAll(tests.nodes, 'selectedTests')
                    }
                  >
                    {`${
                      tests.nodes.length === state.selectedTests.length
                        ? 'Deselect'
                        : 'Select'
                    } All`}
                  </button>

                  <button
                    className="resize-button bold blue"
                    onClick={() => handleChange('expandTests', !expandTests)}
                  >
                    {expandTests ? 'Collapse' : 'Expand'}
                  </button>
                </div>
              </div>

              <ul
                className="section-list-view"
                ref={(ref) => (refs.expandTests = ref)}
              >
                {tests.nodes.map((o) => {
                  const { id, kind, title } = o;
                  const isGlobal = kind
                    ? kind.toLowerCase() === 'global'
                    : false;

                  const activeSubscription = userSubscription.find(
                    (sub) => sub.status === 'active',
                  );
                  const balance =
                    activeSubscription?.availedCredit -
                    activeSubscription?.usedCredit;
                  return (
                    <li
                      className="section-cell"
                      key={id}
                      onClick={(e) => handleSelection(e, 'selectedTests', id)}
                    >
                      <Checkbox
                        checked={state.selectedTests.includes(id)}
                        readOnly={true}
                      />
                      <div className="section-label">{title}</div>
                      {title === 'Color Vision' ? (
                        <div style={{ fontSize: 11 }}>
                          <span
                            style={{ color: 'red' }}
                            onClick={(e) => {
                              e.stopPropagation();
                            }}
                          >
                            &nbsp;
                            {balance ? balance : 0}
                            &nbsp; Balance
                          </span>
                          <span
                            style={{ color: '#ec3bec' }}
                            onClick={(e) => {
                              e.stopPropagation();
                              handleNavigation();
                            }}
                          >
                            &nbsp; + Purchase More
                          </span>
                        </div>
                      ) : (
                        ''
                      )}

                      {/* <div className={isGlobal ? 'section-label red' : 'hide'}>
                        (UNA)
                      </div> */}
                    </li>
                  );
                })}
              </ul>
            </div>
            <div
              className={
                !expandTests && state.selectedTests.length !== 0
                  ? 'selected'
                  : 'hidden'
              }
            >
              <div>Selected:</div>
              <ul
                className="selected-list"
                ref={(ref) => (refs.selectedTests = ref)}
              >
                {state.selectedTests.map((test) => {
                  return (
                    <li key={test} className="selected-item">
                      {tests.nodes.find((element) => element.id === test).title}
                    </li>
                  );
                })}
              </ul>
            </div>

            <div
              className={checklists.nodes.length > 0 ? 'section-frame' : 'hide'}
              style={{
                height: expandChecklists
                  ? headerHeight +
                    7.5 +
                    refs.expandChecklists.getBoundingClientRect().height
                  : headerHeight,
              }}
            >
              <div className="section-header-frame">
                <div className="section-header">{`Skills Checklists (${state.selectedChecklists.length})`}</div>

                <div className="flex-frame">
                  <button
                    className="select-all-button orange"
                    onClick={() =>
                      handleSelectAll(checklists.nodes, 'selectedChecklists')
                    }
                  >
                    {`${
                      checklists.nodes.length ===
                      state.selectedChecklists.length
                        ? 'Deselect'
                        : 'Select'
                    } All`}
                  </button>

                  <button
                    className="resize-button bold blue"
                    onClick={() =>
                      handleChange('expandChecklists', !expandChecklists)
                    }
                  >
                    {expandChecklists ? 'Collapse' : 'Expand'}
                  </button>
                </div>
              </div>

              <ul
                className="section-list-view"
                ref={(ref) => (refs.expandChecklists = ref)}
              >
                {checklists.nodes.map((o) => {
                  const { id, kind, title } = o;
                  const isGlobal = kind
                    ? kind.toLowerCase() === 'global'
                    : false;

                  return (
                    <li
                      className="section-cell"
                      key={id}
                      onClick={(e) =>
                        handleSelection(e, 'selectedChecklists', id)
                      }
                    >
                      <Checkbox
                        checked={state.selectedChecklists.includes(id)}
                        readOnly={true}
                      />

                      <div className="section-label">{title}</div>

                      <div className={isGlobal ? 'section-label red' : 'hide'}>
                        (UNA)
                      </div>
                    </li>
                  );
                })}
              </ul>
            </div>
            <div
              className={
                !expandChecklists && state.selectedChecklists.length !== 0
                  ? 'selected'
                  : 'hidden'
              }
            >
              <span>Selected:</span>
              <ul
                className="selected-list"
                ref={(ref) => (refs.selectedChecklists = ref)}
              >
                {state.selectedChecklists.map((checklist) => {
                  return (
                    <li className="selected-item">
                      {
                        checklists.nodes.find(
                          (element) => element.id === checklist,
                        ).title
                      }
                    </li>
                  );
                })}
              </ul>
            </div>

            <div
              className={courses.nodes.length > 0 ? 'section-frame' : 'hide'}
              style={{
                height: expandCourses
                  ? headerHeight +
                    7.5 +
                    refs.expandCourses.getBoundingClientRect().height
                  : headerHeight,
              }}
            >
              <div className="section-header-frame">
                <div className="section-header">{`Mandatories (${state.selectedCourses.length})`}</div>

                <div className="flex-frame">
                  <button
                    className="select-all-button orange"
                    onClick={() =>
                      handleSelectAll(courses.nodes, 'selectedCourses')
                    }
                  >
                    {`${
                      courses.nodes.length === state.selectedCourses.length
                        ? 'Deselect'
                        : 'Select'
                    } All`}
                  </button>

                  <button
                    className="resize-button bold blue"
                    onClick={() =>
                      handleChange('expandCourses', !expandCourses)
                    }
                  >
                    {expandCourses ? 'Collapse' : 'Expand'}
                  </button>
                </div>
              </div>

              <ul
                className="section-list-view"
                ref={(ref) => (refs.expandCourses = ref)}
              >
                {courses.nodes.map((o) => {
                  const { id, kind, title } = o;
                  const isGlobal = kind
                    ? kind.toLowerCase() === 'global'
                    : false;

                  return (
                    <li
                      className="section-cell"
                      key={id}
                      onClick={(e) => handleSelection(e, 'selectedCourses', id)}
                    >
                      <Checkbox
                        checked={state.selectedCourses.includes(id)}
                        readOnly={true}
                      />

                      <div className="section-label">{title}</div>

                      <div className={isGlobal ? 'section-label red' : 'hide'}>
                        (UNA)
                      </div>
                    </li>
                  );
                })}
              </ul>
            </div>
            <div
              className={
                !expandCourses && state.selectedCourses.length !== 0
                  ? 'selected'
                  : 'hidden'
              }
            >
              <span>Selected:</span>
              <ul
                className="selected-list"
                ref={(ref) => (refs.selectedCourses = ref)}
              >
                {state.selectedCourses.map((course) => {
                  return (
                    <li className="selected-item">
                      {
                        courses.nodes.find((element) => element.id === course)
                          .title
                      }
                    </li>
                  );
                })}
              </ul>
            </div>

            <div
              className={documents.nodes.length > 0 ? 'section-frame' : 'hide'}
              style={{
                height: expandDocuments
                  ? headerHeight +
                    7.5 +
                    refs.expandDocuments.getBoundingClientRect().height
                  : headerHeight,
              }}
            >
              <div className="section-header-frame">
                <div className="section-header">{`Documents (${state.selectedDocuments.length})`}</div>

                <div className="flex-frame">
                  <button
                    className="select-all-button orange"
                    onClick={() =>
                      handleSelectAll(documents.nodes, 'selectedDocuments')
                    }
                  >
                    {`${
                      documents.nodes.length === state.selectedDocuments.length
                        ? 'Deselect'
                        : 'Select'
                    } All`}
                  </button>

                  <button
                    className="resize-button bold blue"
                    onClick={() =>
                      handleChange('expandDocuments', !expandDocuments)
                    }
                  >
                    {expandDocuments ? 'Collapse' : 'Expand'}
                  </button>
                </div>
              </div>
              <ul
                className="section-list-view"
                ref={(ref) => (refs.expandDocuments = ref)}
              >
                {documents.nodes.map((d) => {
                  const { id, title } = d;

                  return (
                    <li
                      className="section-cell"
                      key={id}
                      onClick={(e) =>
                        handleSelection(e, 'selectedDocuments', id)
                      }
                    >
                      <Checkbox
                        checked={state.selectedDocuments.includes(id)}
                        readOnly={true}
                      />

                      <div className="section-label">{title}</div>
                    </li>
                  );
                })}
              </ul>
            </div>
            <div
              className={
                !expandDocuments && state.selectedDocuments.length !== 0
                  ? 'selected'
                  : 'hidden'
              }
            >
              <span>Selected:</span>
              <ul
                className="selected-list"
                ref={(ref) => (refs.selectedDocuments = ref)}
              >
                {state.selectedDocuments.map((document) => {
                  return (
                    <li className="selected-item">
                      {
                        documents.nodes.find(
                          (element) => element.id === document,
                        ).title
                      }
                    </li>
                  );
                })}
              </ul>
            </div>
          </div>
        ) : (
          <div className="layout-center">
            <div className="empty-label hint">
              This organization does not have any content to display.
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default RosterInvite;
