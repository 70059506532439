import React from 'react';
import { OverlayTrigger, Popover } from 'react-bootstrap';

import _ from 'lodash';

import LoadingPane from '../Shared/LoadingPane';

import SearchField from '../Shared/SearchField';
import DeletePane from './Panes/Delete';

const DashboardClients = (props) => {
  const {
    goToOrgRoute,
    handleSearch,
    handleScroll,
    removeOrg,
    state,
    toggleModal,
  } = props;
  const { brandColor, loading, isModal, orgs, org, secondaryColor } = state;

  return (
    <div className="dashboard-clients-view full-screen-view">
      {loading ? (
        <LoadingPane style={{ background: 'rgba(0,0,0,0.1)' }} />
      ) : null}

      <div className="stats-frame">
        <div className="centered-frame">
          <div className="stat-frame">
            <div className="header">TOTAL</div>
            <div className="stat-label black">{orgs.nodes.length}</div>
          </div>

          <div className="stat-frame middle">
            <div className="header">ACTIVE</div>

            <div
              className="stat-label"
              style={{
                color:
                  orgs.nodes.length > 0
                    ? secondaryColor
                      ? secondaryColor
                      : '#008489'
                    : '#919191',
              }}
            >
              {orgs.nodes.filter((o) => o.status === 'active').length}
            </div>
          </div>

          <div className="stat-frame">
            <div className="header">INACTIVE</div>
            <div className="stat-label">
              {orgs.nodes.filter((o) => o.status !== 'active').length}
            </div>
          </div>
        </div>
      </div>

      <div className="secondary-toolbar-frame">
        <div className="centered-frame">
          <SearchField onChange={handleSearch} placeholder="Search Clients" />

          <button
            className="create-button"
            style={{ color: brandColor || '#FF5A5F' }}
            onClick={() => goToOrgRoute()}
          >
            + Create New Client
          </button>
        </div>
      </div>

      <div className="dashboard-table-view">
        <div className="table-headers">
          <div className="centered-frame">
            <div className="table-header title">Title</div>
            <div className="table-header type">Type</div>
            <div className="table-header content">Tests</div>
            <div className="table-header content">Checklists</div>
            <div className="table-header content">Mandatories</div>
            {/* <div className='table-header plan'>Plan</div> */}
            {/* <div className='table-header frequency'>Billing Frequency</div> */}
            <div className="table-header status">Status</div>
          </div>
        </div>

        {orgs.nodes.length > 0 ? (
          <ul className="dashboard-list-view scroll" onScroll={handleScroll}>
            {orgs.nodes
              .filter((o) => !o.parentId)
              .map((org) => {
                const {
                  attachment,
                  checklists,
                  courses,
                  id,
                  kind,
                  status,
                  tests,
                  title,
                } = org;

                const active = status === 'active';

                return (
                  <li className="dashboard-cell" key={id}>
                    {attachment ? (
                      <img
                        className="org-logo"
                        src={attachment.url}
                        alt="org-logo"
                      />
                    ) : null}

                    <div className="centered-frame">
                      <div
                        className="cell-content-frame"
                        onClick={() => goToOrgRoute(id)}
                      >
                        <div className="cell-label bold title">{title}</div>
                        <div className="cell-label type">
                          {_.startCase(kind)}
                        </div>
                        {/* <div className='cell-label plan'>-</div> */}
                        {/* <div className='cell-label frequency'>-</div> */}
                        <div className="cell-label content">
                          {tests.nodes.length}
                        </div>
                        <div className="cell-label content">
                          {checklists.nodes.length}
                        </div>
                        <div className="cell-label content">
                          {courses.nodes.length}
                        </div>
                        <div
                          className="cell-label status"
                          style={{ color: active ? secondaryColor : '#919191' }}
                        >
                          {active ? 'Active' : 'Inactive'}
                        </div>
                      </div>

                      <OverlayTrigger
                        trigger="click"
                        placement="top"
                        rootClose
                        overlay={
                          <Popover id="menu-pane" className="menu-pane clients">
                            <div
                              className="menu-content-view"
                              style={{ padding: '5px 0px' }}
                            >
                              <button
                                className="menu-button teal"
                                onClick={() => goToOrgRoute(id)}
                              >
                                View Client Details
                              </button>
                              <button
                                className="menu-button red"
                                onClick={() => toggleModal(org, true)}
                              >
                                Delete Client
                              </button>
                            </div>
                          </Popover>
                        }
                      >
                        <div className="expander-icon">
                          <img
                            src={require('../../theme/images/expander-icon-grey-2x.png')}
                            alt="expander-icon"
                          />
                        </div>
                      </OverlayTrigger>
                    </div>
                  </li>
                );
              })}
          </ul>
        ) : (
          <div className="layout-center empty-label">
            There are no clients to display.
          </div>
        )}
      </div>

      <DeletePane
        message={`Are you sure you want to remove "${org.title}" from your organization?`}
        onHide={() => toggleModal({}, false)}
        save={removeOrg}
        show={isModal}
      />
    </div>
  );
};

export default DashboardClients;
