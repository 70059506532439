const en = {
  add: 'add',
  additionalInformation: 'Additional Information',
  address: 'Address',
  age: 'age',
  annualTbAttestation: 'Annual TB Attestation',
  annualEducationAttestation:
    'Annual Attestation of Core Compentency Education',
  answer: 'answer',
  associateDegree: "Associate's Degree",
  attachment: 'Attachment',
  award: 'award',
  avgExamTime: 'Avg. Exam Time',
  avgChecklistTime: 'Avg. Skills Checklist Time',
  avgMandatoryTime: 'Avg. Mandatory Time',
  avgTime: 'avg time',

  bachelorDegree: "Bachelor's Degree",
  back: 'back',
  backToCredentials: 'back to credentials',
  backToUser: 'back to user',
  bio: 'Bio',

  cancel: 'Cancel',
  certification: 'certification',
  checklists: 'checklists',
  chooseFile: 'choose file',
  clearSignature: 'clear signature',
  clinicalCompetancy: 'clinical competency',
  city: 'city',
  communicationWithOthers: 'communication with others',
  continue: 'continue',
  courses: 'Mandatories',
  course: 'Mandatory',
  currentlySelected: '(Currently Selected)',

  date: 'date',
  dateRange: 'date range',
  dateReceived: 'date received',
  days: 'days',
  day: 'day',
  degree: 'degree',
  _degree: 'Degree',
  delete: 'delete',
  department: 'department',
  doctoralDegree: 'Doctoral Degree',
  document: 'document',
  documents: 'Documents',
  documentDeletePaneMessage: 'Are you sure you want to delete this document?',
  done: 'Done',
  doYouHaveATiter: 'Do you have a titer?',
  driversLicense: "Driver's License",

  edit: 'edit',
  education: 'Education',
  electromagneticRadiation: 'Electromagnetic Radiation',
  eligibleForRehire: 'eligible for rehire',
  email: 'email',
  employment: 'employment',
  employeeSignature: 'employee signature',
  employeeSignatureHint: 'Use your cursor or finger to sign below.',
  emptyReportingMessage:
    'There are no users to display for the given date range.',
  endDate: 'end date',
  enter: 'Enter',
  examDate: 'exam date',
  experience: 'experience',
  _experience: 'Experience',
  expired: 'Expired',
  expires: 'Expires',
  expirations: 'expirations',
  export: 'export',
  exportColorVision: 'export Color Vision',

  facilityName: 'facility name',
  facilityType: 'facility type',
  firstName: 'first name',
  form: 'form',
  freeOfActiveDisease: 'Free of Active Disease',
  front: 'front',

  graduationDate: 'Graduation Date',

  hepBDeclination: 'Hepatitis B Declination',
  hospital: 'hospital',

  iCurrentlyWorkHere: 'I currently work here',
  image: 'image',
  immunization: 'immunization',
  information: 'information',
  injection: 'injection',
  invitationNote: 'invitation note',
  issueDate: 'issue date',
  issueExpireDate: 'expiration date',
  issuerCity: 'City of Issuer',
  issuerLocation: 'Issuer Location',
  issuerState: 'State of Issuer',

  jobTitle: 'job title',

  kind: 'kind',

  lastName: 'last name',
  letterOfRecommendation: 'Letter of Recommendation',
  license: 'license',
  longestTime: 'longest time',

  masterDegree: "Master's Degree",
  months: 'months',
  month: 'month',

  name: 'name',
  newUsers: 'New User(s)',
  no: 'no',
  nonSupportedDocumentMessage: (kind) =>
    `Document type (${kind}) is currently not supported.`,
  noIssuingState: 'No location provided',
  noReferenceOrganization: 'No organization provided',
  noReferenceTitle: 'No title provided',
  notApplicable: 'N/A',
  notFoundMessage: '404: Page not found',
  notProvided: 'not provided',
  number: 'number',
  numberOfEmployees: 'Number of Employees:',

  of: 'of',
  organization: 'organization',
  orientation: 'orientation',
  other: 'Other',
  otherDetails: 'Other Information',

  passport: 'passport',
  pending: 'pending',
  phone: 'phone',
  phoneNumber: 'phone number',
  photo: 'photo',
  photoOfCertification: 'Photo of Certification',
  photoOfDiploma: 'Photo of Diploma',
  photoOfLicense: 'Photo of License',
  physicalHealthStatement: 'Physical Health Statement',
  positionTitle: 'position title',
  ppd: 'PPD',
  present: 'Present',
  primary: 'Primary',
  professionalism: 'professionalism',
  program: 'program',

  qualitative: 'qualitative',

  ratings: 'ratings',
  reference: 'reference',
  relationship: 'relationship',
  referenceDeletePaneMessage: 'Are you sure you want to delete this reference?',
  renew: 'renew',
  resume: 'resume',
  resume_cv: 'Resume / CV',
  result: 'result',
  results: 'results',
  roles: 'roles',
  runReport: 'Run Report',

  save: 'Save',
  schoolName: 'school name',
  select: 'Select',
  selectRole: 'select role',
  selectFile: 'Select file',
  selectDegreeOrProgram: 'Select degree/program',
  selectState: 'Select state',
  shortestTime: 'shortest time',
  skillsChecklists: 'Skills Checklists',
  specialties: 'Specialties',
  specialty: 'specialty',
  startedOn: 'started on',
  startDate: 'start date',
  state: 'state',

  tbSkinTest: 'TB Skin Test',
  technologyCompetancy: 'technology competency',
  attendancePunctuality: 'attendance & punctuality',
  tests: 'Tests',
  title: 'title',
  titer: 'titer',
  totalUsers: 'total users',
  twoStep: '2 Step',
  type: 'type',

  usersBreakdown: 'Users Breakdown',
  usersWithResult: 'Users with Result(s)',

  vaccination: 'vaccination',
  verified: 'verified',
  view: 'View',
  viewBreakdown: 'View Breakdown',

  with: 'with',
  workExperience: 'work experience',
  workExperienceDeletePaneMessage:
    'Are you sure you want to delete this work experience?',

  xRay: 'X-Ray',

  years: 'years',
  yearsOld: 'years old',
  _years: 'Years',
  year: 'year',
  yes: 'yes',
};

export default en;
