import React from 'react';

import FormInput from '../Shared/Form/Input';
import FormSelect from '../Shared/Form/Select';
import LoadingPane from '../Shared/LoadingPane';

import { roles, states } from '../../utils/Constants';

const User = (props) => {
  const { goToUsersRoute, handleChange, handleRole, save, state } = props;
  const { attachment, file, id, loading, role, orgRole } = state;

  const myRole = orgRole.name; 
  const userRole = role ? role.name : '';

  const actionableRoles =
    myRole === 'corporate'
      ? roles(myRole).map((o) => o.value)
      : roles(myRole)
          .map((o) => o.value)
          .slice(1); //slicing the array as actionable are lower only
  const canCreateOrEdit = state.create ? true : actionableRoles.includes(userRole); 
  const roleOptions = canCreateOrEdit ? roles(orgRole.name).slice(1) : roles(userRole);
  
  return (
    <div className="user-view full-screen-view">
      {loading ? (
        <LoadingPane style={{ background: 'rgba(0,0,0,0.1)' }} />
      ) : null}

      <div className="toolbar">
        <button className="cancel-button" onClick={goToUsersRoute}>
          Cancel
        </button>

        <label className="page-header">{`${
          id ? 'Edit' : 'Add New'
        } User`}</label>

        <div className="flex-frame">
          <button
            className={id ? 'hide' : 'save-button invite bg-teal'}
            onClick={() => save(true)}
          >
            {'Save & Invite'}
          </button>

          <button
            className="save-button"
            disabled={!canCreateOrEdit}
            onClick={() => save(false)}
          >
            Save
          </button>
        </div>
      </div>

      <div className="content-frame scroll">
        <div className="centered-frame">
          <div className="section-header">User Details</div>

          <div className="profile-picture-frame">
            <div className="profile-icon">
              <img
                src={
                  file
                    ? file.base64
                    : attachment
                    ? attachment.url
                    : require('../../theme/images/profile-icon-3x.png')
                }
                alt="profile-icon"
              />
            </div>

            <button className="add-photo-button red" disabled={!canCreateOrEdit}>{`${
              attachment ? 'Edit' : '+ Add'
            } Photo`}</button>

            <input
              className="profile-picture-input"
              disabled={!canCreateOrEdit}
              onChange={(e) => {
                const file = e.target.files[0];
                const reader = new FileReader();

                reader.onload = (result) => {
                  const supportedTypes = ['image/png', 'image/jpeg'];
                  const base64 = result.target.result;
                  const newFile = {
                    base64,
                    name: file.name,
                    type: file.type,
                  };

                  if (supportedTypes.includes(file.type)) {
                    handleChange('file', newFile);
                  } else {
                    window.alert(`${file.type} is not supported.`);
                  }
                };

                reader.readAsDataURL(file);
              }}
              type="file"
            />
          </div>

          <div className="form-frame">
            <FormSelect
              header="Role"
              defaultValue={role ? role.name : -1}
              placeholder="Select Role"
              onChange={handleRole}
              options={
                orgRole.name === 'corporate'
                  ? roles(orgRole.name)
                  : roleOptions
              }
              disableForm={!canCreateOrEdit}
            />

            <FormInput
              className="middle"
              defaultValue={state.firstName}
              header="First Name"
              placeholder="Enter First Name"
              onChange={(e) => handleChange('firstName', e.target.value)}
              disableForm={!canCreateOrEdit}
            />

            <FormInput
              defaultValue={state.lastName}
              header="Last Name"
              placeholder="Enter Last Name"
              onChange={(e) => handleChange('lastName', e.target.value)}
              disableForm={!canCreateOrEdit}
            />

            <FormInput
              defaultValue={state.email}
              header="Email"
              placeholder="Enter Email"
              onChange={(e) => handleChange('email', e.target.value)}
              disableForm={!canCreateOrEdit}
            />

            <FormInput
              className="middle"
              defaultValue={state.phone}
              header="Phone"
              placeholder="Enter Phone"
              disableForm={!canCreateOrEdit}
              onChange={(e) => {
                const formatNumber = (num) => {
                  const cleaned = `${num}`.replace(/\D/g, '');
                  const match = cleaned.match(/^(\d{3})(\d{3})(\d{4})$/);

                  if (match) {
                    return `(${match[1]}) ${match[2]}-${match[3]}`;
                  }

                  return null;
                };

                const value = formatNumber(e.target.value);

                if (value) {
                  e.target.value = value;
                }

                handleChange('phone', value || e.target.value);
              }}
            />

            <div
              className={role === 'healthcare_pro' ? 'subform-frame' : 'hide'}
            >
              <FormInput
                defaultValue={state.homeStreet1}
                header="Street 1"
                placeholder="Enter Street 1"
                onChange={(e) => handleChange('homeStreet1', e.target.value)}
              />

              <FormInput
                className="middle"
                defaultValue={state.homeStreet2}
                header="Street 2"
                placeholder="Enter Street 2"
                onChange={(e) => handleChange('homeStreet2', e.target.value)}
              />

              <FormInput
                defaultValue={state.homeCity}
                header="City"
                placeholder="Enter City"
                onChange={(e) => handleChange('homeCity', e.target.value)}
              />

              <FormSelect
                header="State"
                defaultValue={state.homeState}
                placeholder="Enter State"
                onChange={(e) => handleChange('homeState', e.target.value)}
                options={states.map(({ abbreviation, name }) => ({
                  value: abbreviation,
                  name,
                }))}
              />

              <FormInput
                className="middle"
                header="Zip Code"
                defaultValue={state.homeZip}
                placeholder="Enter Zip Code"
                onChange={(e) => handleChange('homeZip', e.target.value)}
              />

              <div className="form-input-frame bio">
                <div className="form-input-header">Full Bio</div>

                <textarea
                  className="form-input"
                  defaultValue={state.bio}
                  onChange={(e) => handleChange('bio', e.target.value)}
                  placeholder="Enter full bio..."
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default User;
