import { gql } from 'apollo-boost';

const ChecklistCategoryUpdateMutation = gql`
  mutation ChecklistCategoryUpdateMutation(
    $input: ChecklistCategoryUpdateInput
  ) {
    checklistCategoryUpdate(input: $input) {
      errors {
        message
      }
      result {
        id
        sections {
          id
          questions {
            id
            rank
            title
          }
          rank
          title
        }
        rank
        title
      }
    }
  }
`;

export default ChecklistCategoryUpdateMutation;
