import { gql } from 'apollo-boost';

const UserTestCreateMutation = gql`
  mutation UserTestCreateMutation($input: TestUserTestCreateCreateInput) {
    userTestCreate(input: $input) {
      errors {
        code
        message
      }
      success
    }
  }
`;

export default UserTestCreateMutation;
