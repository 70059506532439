import React from 'react';
import { OverlayTrigger, Popover } from 'react-bootstrap';
import Select from 'react-select';
import { uniqBy } from 'lodash';
// import moment from 'moment'
import LoadingPane from '../Shared/LoadingPane';
import DeletePane from './Panes/Delete';
import { useNavigate } from 'react-router-dom';

const DashboardTests = (props) => {
  const {
    contentRemove,
    ShowMapping,
    mappingHeader,
    onCloseAlert,
    Alert,
    testtype,
    onSubmitMap,
    updateMapArray,
    modelSubmitBtn,
    csvConvertedArray,
    duplicateTest,
    closeMapping,
    handleFileChange,
    goToTestRoute,
    state,
    toggleModal,
  } = props;
  const {
    admin,
    brandColor,
    isModal,
    loading,
    secondaryColor,
    tests,
    test,
    orgId,
  } = state;
  const navigate = useNavigate();

  const testArray = uniqBy(tests.nodes, 'id');
  let options = [];

  let mappingOptions = [
    { value: '1', label: 'Bulk' },
    { value: '2', label: 'Self Report Assessment ' },
  ];

  if (csvConvertedArray) {
    options = csvConvertedArray.meta.fields.map((item) => {
      return { value: item, label: item };
    });
  }
  const customStyles = {
    menu: (provided, state) => ({
      ...provided,
      // maxHeight: '130px', // Adjust the maximum height as needed
      overflow: 'visible', // Add a vertical scrollbar when options exceed maxHeight,
      fontSize: '12px',
      width: '130px',
      backgroundColor: 'white',

      // height: '20px'
    }),
    menuList: (provided) => ({
      ...provided,
      maxHeight: '80px', // Set the desired height in pixels,
      height: '80px',
      backgroundColor: 'white',
    }),
    control: (provided) => ({
      ...provided,
      width: '130px', // Set the desired width in pixels or any other valid CSS value
      fontSize: '12px',
      marginTop: '10px',
    }),
  };

  return (
    <div className="dashboard-test-view">
      {loading ? (
        <LoadingPane style={{ background: 'rgba(0,0,0,0.1)' }} />
      ) : null}
      {/* model code start */}
      {ShowMapping && csvConvertedArray && (
        <div className="modaloverlay">
          <div className="modal">
            <div>
              <h2>Header Mapping</h2>
              <button
                type="submit"
                className="model-cancel-button"
                onClick={closeMapping}
              >
                X
              </button>
            </div>

            <div
              className="newstyle"
              style={{ marginBottom: '30px', textAlign: 'center' }}
            >
              <h4 style={{ fontSize: 14, marginBottom: '0px' }}>
                Test Type <span style={{ color: 'red' }}>*</span>
              </h4>
              <div style={{ display: 'flex', justifyContent: 'center' }}>
                <Select
                  styles={customStyles}
                  options={mappingOptions}
                  placeholder="Test Type"
                  onChange={(e) => updateMapArray('', e, 'testtype')}
                >
                </Select>
              </div>
            </div>

            <div className="model-body">
              {csvConvertedArray.defaultMapHeaders.map((grandheader, index) => {
                const check = options.find((o) => {
                  return o.value === grandheader.default;
                });
                return (
                  <div key={index} className="newstyle">
                    <h4
                      style={
                        grandheader.name === 'Correct Answer'
                          ? { display: 'inline', fontSize: 14 }
                          : { display: 'inline' }
                      }
                    >
                      {grandheader.name}
                      {grandheader.name === 'SubCategory'
                        ? ''
                        : testtype === '2' &&
                          grandheader.name === 'Correct Answer'
                          ? ''
                          : grandheader.optional === 1 && (
                            <span style={{ color: 'red' }}>*</span>
                          )}
                    </h4>

                    <Select
                      value={check ? check : ''}
                      id={grandheader.name}
                      styles={customStyles}
                      placeholder="Select Map"
                      options={options}
                      onChange={(e) =>
                        grandheader.default === e.value
                          ? ''
                          : updateMapArray(
                            grandheader.name,
                            e.value,
                            'SelectMap',
                          )
                      }
                    >
                    </Select>
                  </div>
                );
              })}
            </div>

            <div>
              <button
                type="submit"
                className={
                  modelSubmitBtn
                    ? 'model-submit-button'
                    : 'model-submit-button-disabled'
                }
                disabled={!modelSubmitBtn}
                onClick={onSubmitMap}
              >
                Submit
              </button>
            </div>
          </div>
        </div>
      )}
      {/*  model code end  */}
      {/* Alert messages popup */}
      {Alert && (
        <div className="modaloverlay">
          <div className="modal" style={{ width: '20%' }}>
            <h2>Alert</h2>
            <div
              className="model-body"
              dangerouslySetInnerHTML={{ __html: Alert }}
              style={{ display: 'inline' }}
            >
              {/* {Alert} */}
            </div>
            <div>
              <button
                type="submit"
                style={{ width: '20%' }}
                className="model-submit-button"
                onClick={onCloseAlert}
              >
                OK
              </button>
            </div>
          </div>
        </div>
      )}
      {/* end Alert messages popup */}
      <div className="stats-frame">
        <div className="centered-frame">
          <div className="stat-frame">
            <div className="header">TOTAL</div>
            <div className="stat-label black">{testArray.length}</div>
          </div>

          <div className="stat-frame middle">
            <div className="header">ACTIVE</div>
            <div
              className="stat-label"
              style={{
                color:
                  testArray.length > 0
                    ? secondaryColor
                      ? secondaryColor
                      : '#008489'
                    : '#919191',
              }}
            >
              {testArray.filter((t) => t.status === 'active').length}
            </div>
          </div>

          <div className="stat-frame">
            <div className="header">INACTIVE</div>
            <div className="stat-label">
              {testArray.filter((t) => t.status === 'inactive').length}
            </div>
          </div>
        </div>
      </div>

      <div className="filter-frame">
        <div className="centered-frame">
          <button
            className="create-test-button"
            style={{ color: '#FF5A5F' }}
            onClick={mappingHeader}
          >
            + Upload bulk Data
          </button>

          <input
            className="create-test-button"
            type="file"
            accept=".csv" // Only allow CSV files
            id="fileInput"
            onChange={handleFileChange}
          />
        </div>
        <div className="centered-frame">
          <button
            className="create-test-button"
            style={{ color: brandColor || '#FF5A5F', float: 'left' }}
            onClick={() => goToTestRoute()}
          >
            + Create New Test
          </button>
        </div>
      </div>
      <div className="tests-table-view">
        <div className="table-headers">
          <div className="centered-frame">
            <div className="table-header title small">{'Title'}</div>
            <div className="table-header list small">{'Test Type'}</div>
            {/* <div className='table-header duration'>{"Duration"}</div> */}
            <div className="table-header list">{'Passing Score'}</div>
            <div className="table-header list">{'Categories'}</div>
            <div className="table-header list">{'Questions'}</div>
            {/* <div className='table-header date'>{"Date Modified"}</div> */}
            <div className="table-header status">{'Status'}</div>
          </div>
        </div>

        {testArray.length > 0 ? (
          <ul className="tests-list-view scroll">
            {testArray.map((test) => {
              const {
                categories,
                id,
                passingScore,
                status,
                org,
                title /*durationMins, updatedAt*/,
                testtype,
              } = test;

              const questions = test.categories.map((c) => c.questions).flat();
              // updatedAtDate = moment(new Date(updatedAt * 1000)).format('MMM D, YYYY'),
              // days = Math.round(moment.duration(durationMins, 'minutes').asDays()),
              // months = Math.round(moment.duration(durationMins, 'minutes').asMonths()),
              const isOrg = orgId === org?.id;
              const hasPermission = admin || isOrg;

              return (
                <li
                  className={hasPermission ? 'test-cell' : 'test-cell disabled'}
                  key={id}
                >
                  <div className="centered-frame">
                    <div
                      className="cell-content-frame"
                      onClick={() => (hasPermission ? goToTestRoute(id) : null)}
                    >
                      <div className="cell-label title small">{title}</div>
                      <div className="cell-label list small">
                        {testtype === 0
                          ? 'Normal'
                          : testtype === 1
                            ? 'Bulk'
                            : 'Self Report Assessment'}
                      </div>
                      {/* <div className='cell-label duration'>{durationMins ? (days > 30) ? `${months} months` : `${days} days` : '-'}</div> */}
                      <div className="cell-label list">
                        {passingScore ? `${passingScore}%` : '-'}
                      </div>
                      <div className="cell-label list">{categories.length}</div>
                      <div className="cell-label list">{questions.length}</div>
                      {/* <div className='cell-label date'>{updatedAtDate}</div> */}
                      <div
                        className="cell-label status"
                        style={{
                          color:
                            status === 'active' ? secondaryColor : '#919191',
                        }}
                      >
                        {status}
                      </div>
                      {title === 'Color Vision' ? (
                        <span
                          style={{
                            color: '#ec3bec',
                            fontSize: 12,
                            top: '10px',
                            position: 'absolute',
                          }}
                          onClick={(e) => {
                            e.stopPropagation();
                            navigate(`/organizations/${props.params.id}/rosters`);
                          }}
                        >
                          &nbsp; + Purchase Color Vision Test
                        </span>
                      ) : (
                        ''
                      )}
                    </div>

                    <OverlayTrigger
                      trigger="click"
                      placement="bottom"
                      rootClose
                      overlay={
                        <Popover id="menu-pane" className="menu-pane">
                          <div
                            className="menu-content-view"
                            style={{ padding: '5px 0px' }}
                          >
                            <button
                              className="menu-button teal"
                              onClick={() => goToTestRoute(id)}
                            >
                              View Details
                            </button>
                            <button
                              className="menu-button teal"
                              onClick={() => duplicateTest(test)}
                            >
                              Duplicate
                            </button>
                            <button
                              className="menu-button red"
                              onClick={() => toggleModal(test, true)}
                            >
                              Delete
                            </button>
                          </div>
                        </Popover>
                      }
                    >
                      <div className={hasPermission ? 'expander-icon' : 'hide'}>
                        <img
                          src={require('../../theme/images/expander-icon-grey-2x.png')}
                          alt="expander-icon"
                        />
                      </div>
                    </OverlayTrigger>
                  </div>
                </li>
              );
            })}
          </ul>
        ) : (
          <div className="layout-center hint empty-label">
            There are no tests to display.
          </div>
        )}
      </div>

      <DeletePane
        message={`Are you sure you want to remove "${test.title}" from your organization?`}
        onHide={() => toggleModal({}, false)}
        save={contentRemove}
        show={isModal}
      />
    </div>
  );
};

export default DashboardTests;
