import React from 'react';
import { OverlayTrigger, Popover } from 'react-bootstrap';

import ColorPicker from '../Shared/ColorPicker';
import LoadingPane from '../Shared/LoadingPane';
import SelectMenu from '../Shared/SelectMenu';
import Toolbar from '../Shared/Toolbar';

import DragList from '../../containers/Shared/DragList';

const Test = (props) => {
  const {
    addCategory,
    back,
    cancelCategory,
    deleteCategory,
    duplicateCategory,
    saveCategory,
    goToCategoryRoute,
    handleChange,
    save,
    state,
  } = props;
  const {
    active,
    admin,
    categories,
    categoryOnFocus,
    color,
    days,
    desc,
    durationMins,
    kind,
    id,
    loading,
    passingScore,
    test_type,
    scores,
    mappingOptions,
    title,
  } = state;
  console.log("Mapping Options:", mappingOptions);


  return (
    <div className="test-view full-screen-view">
      {loading ? (
        <LoadingPane style={{ background: 'rgba(0,0,0,0.1)' }} />
      ) : null}

      <Toolbar
        active={active}
        cancel={back}
        handleChange={handleChange}
        isToggle={true}
        save={save}
        title={id ? 'Test Details' : 'Add New Test'}
      />

      <div className="content-frame">
        <div className="centered-frame">
          <div className="section-frame">
            <div className="section-header">UNA Test Details</div>

            <div className="form-frame" style={{ height: 84 * 2 + 150 }}>
              <div className="form-input-frame">
                <div className="input-title">Title</div>

                <input
                  className="form-input"
                  defaultValue={title}
                  onChange={(e) => handleChange('title', e.target.value)}
                  placeholder="Enter Title of Test"
                />
              </div>

              <div className="form-input-frame middle">
                <div className="input-title">Duration</div>

                <SelectMenu
                  className="form-menu"
                  isClearable={true}
                  onChange={(e) => handleChange('durationMins', e.value)}
                  options={days.map((d) => ({
                    value: d,
                    label: `${d} ${d > 1 ? 'days' : 'day'}`,
                  }))}
                  placeholder="Select Duration"
                  value={durationMins}
                />
              </div>

              <div className="form-input-frame">
                <div className="input-title">Passing Score</div>

                <SelectMenu
                  className="form-menu"
                  isClearable={true}
                  onChange={(e) => {
                    if (e) handleChange('passingScore', e.value);
                  }}
                  options={scores.map((s) => ({ value: s, label: `${s}%` }))}
                  placeholder="Select Passing Score"
                  value={passingScore}
                />
              </div>

              <div className="form-input-frame">
                <div className="input-title">Color</div>

                <ColorPicker
                  displayHex={true}
                  onChange={(color) => handleChange('color', color)}
                  value={color}
                />
              </div>

              {id ? (
                ''
              ) : (
                <div className="form-input-frame middle">
                  <div className="input-title">Test Type</div>

                  <SelectMenu
                    className="form-menu"
                    isClearable={true}
                    onChange={(e) => {
                      console.log("Selected Option:", e);
                      if (e) handleChange('test_type', e.value);
                    }}
                    options={mappingOptions}
                    placeholder="Select Test Type"
                    value={test_type}
                  />

                </div>
              )}

              {admin ? (
                <div className="form-input-frame middle">
                  <div className="input-title">Kind</div>

                  <input
                    className="form-input"
                    defaultValue={kind}
                    onChange={(e) => handleChange('kind', e.target.value)}
                    placeholder="Enter Kind of Test"
                  />
                </div>
              ) : null}

              <div className="form-input-frame" style={{ width: '100%' }}>
                <div className="input-title">Description</div>

                <textarea
                  className="form-area"
                  defaultValue={desc}
                  placeholder="Enter description for test..."
                  onChange={(e) => handleChange('desc', e.target.value)}
                />
              </div>
            </div>
          </div>

          <div className={id ? 'section-frame' : 'hide'}>
            <div
              className={
                categories.length === 0
                  ? 'header-frame padding'
                  : 'header-frame'
              }
            >
              <div className="section-header">{`Categories (${categories.length})`}</div>

              <button className="header-button" onClick={() => addCategory(categoryOnFocus.slice(-1))}>
                + Add Category
              </button>
            </div>

            {categories.length === 0 ? (
              <div className="empty-label">No categories have been added.</div>
            ) : (
              <div className="categories-table-view">
                <div className="table-headers">
                  <div className="table-header title">Title</div>
                  <div className="table-header questions">Questions</div>
                </div>

                <DragList
                  onChange={(arr) => handleChange('categories', arr)}
                  data={categories.map((c, i) => {
                    const { id, title } = c;
                    const last = i === categories.length - 1;
                    const categoryOnFocusIds = categoryOnFocus.map((category) => { return category['id'] });
                    const categoryFocusedIndex = categoryOnFocus.findIndex((c) => c.id === id);

                    return {
                      ...c,
                      content:
                        categoryOnFocusIds.includes(id) ? (
                          <div
                            className="category-cell edit"
                            key={i}
                            style={{ borderWidth: last ? 0 : 1 }}
                          >
                            <input
                              autoFocus
                              className="category-title"
                              defaultValue={title}
                              onChange={(e) =>
                                handleChange(`categoryOnFocus[${categoryFocusedIndex}].title`, e.target.value)
                              }
                              placeholder="Enter category title"
                            />

                            <button
                              className="category-button red"
                              onClick={() => cancelCategory(id)}

                            >
                              Cancel
                            </button>
                            <button
                              className="category-button teal"
                              onClick={() => saveCategory(i, id)}
                            >
                              Save
                            </button>
                          </div>
                        ) : (
                          <div
                            className="category-cell"
                            style={{ borderWidth: last ? 0 : 1 }}
                          >
                            <div className="cell-content-frame">
                              <div className="drag-icon">
                                <img
                                  draggable={false}
                                  src={require('../../theme/images/hashes.png')}
                                  alt="hashes-icon"
                                />
                              </div>

                              <div className="cell-label title">{c.title}</div>
                              <div className="cell-label questions">
                                {c.questions.length}
                              </div>
                            </div>

                            <OverlayTrigger
                              trigger="click"
                              placement="bottom"
                              rootClose
                              overlay={
                                <Popover id="menu-pane" className="menu-pane">
                                  <div
                                    className="menu-content-view"
                                    style={{ padding: '5px 0px' }}
                                  >
                                    <button
                                      className="menu-button teal"
                                      onClick={() => goToCategoryRoute(id)}
                                    >
                                      View Questions
                                    </button>
                                    <button
                                      className="menu-button teal"
                                      onClick={() =>
                                        handleChange('categoryOnFocus', [...categoryOnFocus, c])
                                      }
                                    >
                                      Edit Title
                                    </button>
                                    <button
                                      className="menu-button teal"
                                      onClick={() => duplicateCategory(c)}
                                    >
                                      Duplicate
                                    </button>
                                    <button
                                      className="menu-button red"
                                      onClick={() => deleteCategory(c)}
                                    >
                                      Delete
                                    </button>
                                  </div>
                                </Popover>
                              }
                            >
                              <div className="expander-icon">
                                <img
                                  src={require('../../theme/images/expander-icon-grey-2x.png')}
                                  alt="expander-icon"
                                />
                              </div>
                            </OverlayTrigger>
                          </div>
                        ),
                    };
                  })}
                />
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Test;
