import React from 'react';
import { graphql } from 'react-apollo';
import { flowRight as compose, set } from 'lodash';
import OrgQuery from '../../graphql/queries/Bundle';
import BundleCreateMutation from '../../graphql/mutations/Bundle/Create';
import BundleUpdateMutation from '../../graphql/mutations/Bundle/Update';
import OrgUpdateMutation from '../../graphql/mutations/Org/Update';

import Bundle from '../../components/Bundle';

import LoadingPane from '../../components/Shared/LoadingPane';
import { withRouter } from '../withRouter';

class BundleContainer extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      active: false,
      onload: true,
    };
  }

  componentDidMount() {
    const {
      orgQuery: { loading, refetch },
    } = this.props;
    const { onload } = this.state;

    if (onload && !loading) {
      refetch().then(() => this.setup());
    } else {
      this.setup();
    }
  }

  componentDidUpdate() {
    this.setup();
  }

  setup = () => {
    const {
      orgQuery: { loading, bundle, org, specialties },
    } = this.props;
    const { onload } = this.state;

    if (onload && !loading) {
      this.setState({
        ...org,
        bundle,
        onload: false,
        selectedTests:
          bundle && bundle.tests ? bundle.tests.map((o) => o.id) : [], // tests
        selectedChecklists:
          bundle && bundle.checklists ? bundle.checklists.map((o) => o.id) : [], // checklists
        selectedCourses:
          bundle && bundle.courses ? bundle.courses.map((o) => o.id) : [], // courses
        specialties,
      });
    }
  };

  goToBundlesRoute = () => {
    const { navigate, params } = this.props;

    navigate(`/organizations/${params.id}/bundles`);
  };

  handleChange = (key, value) => {
    this.setState(set(this.state, key, value));
  };

  handleSelection = (key, id) => {
    const arr = this.state[key];
    const i = arr.findIndex((result) => result === id);
    const value =
      i >= 0
        ? [...arr.slice(0, i), ...arr.slice(i + 1, arr.length)]
        : [...arr, id];

    this.handleChange(key, value);
  };

  save = () => {
    const { bundleCreateMutation } = this.props;
    const { bundle } = this.state;

    if (!bundle || !bundle.title) {
      window.alert('Title is required.');
    } else if (bundle.id) {
      this.updateBundle(bundle);
    } else {
      this.setState(
        {
          loading: true,
        },
        () => {
          bundleCreateMutation({
            variables: {
              input: {
                kind: bundle.kind,
                title: bundle.title,
              },
            },
          }).then((response) => {
            const { result, errors } = response.data.bundleCreate;

            if (result) {
              this.updateOrgWithBundle(result);
            } else {
              this.handleChange('loading', false);

              window.alert(errors[0].message);
            }
          });
        },
      );
    }
  };

  updateBundle = (bundle) => {
    const { bundleUpdateMutation } = this.props;
    const { loading, selectedTests, selectedChecklists, selectedCourses } =
      this.state;

    if (!loading) this.handleChange('loading', true);

    bundleUpdateMutation({
      variables: {
        input: {
          id: bundle.id,
          testIds: selectedTests,
          checklistIds: selectedChecklists,
          courseIds: selectedCourses,
          title: bundle.title,
        },
      },
    }).then((response) => {
      const { errors, result } = response.data.bundleUpdate;

      if (result) {
        this.goToBundlesRoute();
      } else {
        this.handleChange('loading', false);

        window.alert(errors[0].message);
      }
    });
  };

  updateOrgWithBundle = (bundle) => {
    const { orgUpdateMutation } = this.props;
    const { bundles, id } = this.state;

    orgUpdateMutation({
      variables: {
        input: {
          id,
          bundleIds: [...bundles.map((b) => b.id), bundle.id],
        },
      },
    }).then((response) => {
      const { errors, success } = response.data.orgUpdate;

      if (success) {
        this.updateBundle(bundle);
      } else {
        this.handleChange('loading', false);

        window.alert(errors[0].message);
      }
    });
  };

  render() {
    return this.state.onload ? (
      <LoadingPane />
    ) : (
      <Bundle
        {...this.props}
        goToBundlesRoute={this.goToBundlesRoute}
        handleChange={this.handleChange}
        handleSelection={this.handleSelection}
        save={this.save}
        state={this.state}
      />
    );
  }
}

export default compose(
  withRouter,
  graphql(OrgQuery, {
    name: 'orgQuery',
    options: ({ params: { bundle, id } }) => ({
      variables: {
        id,
        bundle: bundle === 'create' ? null : bundle,
        status: ['active'],
      },
    }),
  }),
  graphql(BundleCreateMutation, { name: 'bundleCreateMutation' }),
  graphql(BundleUpdateMutation, { name: 'bundleUpdateMutation' }),
  graphql(OrgUpdateMutation, { name: 'orgUpdateMutation' }),
)(BundleContainer);
