import { gql } from 'apollo-boost';

const UserRoleRemoveMutation = gql`
  mutation UserRoleRemoveMutation($input: UserRoleRemoveInput) {
    userRoleRemove(input: $input) {
      errors {
        code
        message
      }
      success
    }
  }
`;

export default UserRoleRemoveMutation;
