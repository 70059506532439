import React from 'react';
import { BlockPicker } from 'react-color';

class SketchExample extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      displayColorPicker: false,
      color: this.hexToRgbA(props.value),
      hex: props.value,
    };
  }

  handleClick = () => {
    this.setState({ displayColorPicker: !this.state.displayColorPicker });
  };

  handleClose = () => {
    this.setState({ displayColorPicker: false });
  };

  handleChange = (color) => {
    this.props.onChange(color.hex);

    this.setState({
      color: color.rgb,
      hex: color.hex,
    });
  };

  hexToRgbA = (hex) => {
    let c;

    if (/^#([A-Fa-f0-9]{3}){1,2}$/.test(hex)) {
      c = hex.substring(1).split('');

      if (c.length === 3) {
        c = [c[0], c[0], c[1], c[1], c[2], c[2]];
      }

      c = `0x${c.join('')}`;

      return { r: (c >> 16) & 255, g: (c >> 8) & 255, b: c & 255, a: '1' };
    } else {
      return { r: '255', g: '255', b: '255', a: '1' };
    }
  };

  render() {
    const { displayHex, value } = this.props;
    const { color, displayColorPicker, hex } = this.state;

    return (
      <div className="color-picker">
        <div className="swatch" onClick={this.handleClick}>
          {value ? (
            displayHex ? (
              <div className="hex-frame">
                <div className="hex">{hex}</div>
                <div
                  className="color"
                  style={{
                    background: `rgba(${color.r}, ${color.g}, ${color.b}, ${color.a})`,
                  }}
                />
              </div>
            ) : (
              <div
                className="color"
                style={{
                  background: `rgba(${color.r}, ${color.g}, ${color.b}, ${color.a})`,
                }}
              />
            )
          ) : (
            <div className="color-label">Select Color</div>
          )}
        </div>

        {displayColorPicker ? (
          <div className="color-popover">
            <div className="color-cover" onClick={this.handleClose} />

            <BlockPicker
              color={color}
              colors={[
                '#D060B8',
                '#9E56D8',
                '#D14265',
                '#57BAA7',
                '#6C96D6',
                '#D09B5D',
                '#E36B45',
                '#42AFD1',
                '#FF5A5F',
                '#1882BD',
              ]}
              onChange={this.handleChange}
              triangle="hide"
            />
          </div>
        ) : null}
      </div>
    );
  }
}

export default SketchExample;
