import React from 'react';
import { graphql } from 'react-apollo';
import compose from 'lodash.flowright';

import _ from 'lodash';

import OrgQuery from '../../graphql/queries/Org';
import AttachmentCreateMutation from '../../graphql/mutations/Attachment/Create';
import AttachmentUpdateMutation from '../../graphql/mutations/Attachment/Update';
import OrgActivateMutation from '../../graphql/mutations/Org/Activate';
import OrgContentAddMutation from '../../graphql/mutations/Org/Content/Add';
import OrgContentRemoveMutation from '../../graphql/mutations/Org/Content/Remove';
import OrgCreateMutation from '../../graphql/mutations/Org/Create';
import OrgDeactivateMutation from '../../graphql/mutations/Org/Deactivate';
import OrgUpdateMutation from '../../graphql/mutations/Org/Update';

import Org from '../../components/Org';

import LoadingPane from '../../components/Shared/LoadingPane';
import { withRouter } from '../withRouter';

class OrgContainer extends React.Component {
  constructor(props) {
    super(props);

    const searchParams = new URLSearchParams(props.location.search);

    this.state = {
      attachments: [],
      annualReminders: [],
      modules: ['checklists', 'mandatories', 'tests'],
      expirationNotificationDays: ['30', '15', '7', '1'],
      onload: true,
      status: 'inactive',
      tab: searchParams.has('tab') ? searchParams.get('tab') : 'info',
      tabs: [
        { route: 'info', name: 'Client Info' },
        { route: 'content', name: 'Content' },
        { route: 'reporting', name: 'Reporting' },
      ],
    };
  }

  componentDidMount() {
    const {
      orgQuery: { loading, refetch },
    } = this.props;
    const { onload } = this.state;

    if (onload && !loading) {
      refetch().then(() => this.setup());
    } else {
      this.setup();
    }
  }

  componentDidUpdate() {
    this.setup();
  }

  setup = () => {
    const {
      navigate,
      orgQuery: { loading, org },
      context: { user },
    } = this.props;
    const { onload } = this.state;

    const hasPermission = user.roles.map((r) => r.name).includes('corporate');

    if (!hasPermission) {
      navigate('/error');
    } else if (onload && !loading) {
      const address = org && org.csz ? org.csz.split(' ') : null;

      this.setState({
        ...org,
        attachment: org ? org.attachments[0] : null,
        city: address ? _.startCase(address[0]) : '',
        defaultStatus: org ? org.status : 'inactive',
        onload: false,
        selectedTests:
          org && org.tests.nodes.length > 0
            ? org.tests.nodes.map((o) => o.id)
            : [],
        selectedChecklists:
          org && org.checklists.nodes.length > 0
            ? org.checklists.nodes.map((o) => o.id)
            : [],
        selectedCourses:
          org && org.courses.nodes.length > 0
            ? org.courses.nodes.map((o) => o.id)
            : [],
        selectedDocuments:
          org &&
          org.signatureDocuments &&
          org.signatureDocuments.nodes.length > 0
            ? org.signatureDocuments.nodes.map((o) => o.id)
            : [],
        state: address ? address[1].split(',')[0] : '',
        zip: address ? address[2] : '',
        expirationNotificationDays: org ? org.expirationNotificationDays : [],
        annualReminders: org ? org.annualReminders : [],
      });
    }
  };

  contentAdd = (arr) => {
    const { params, orgContentAddMutation } = this.props;
    const {
      checklists,
      courses,
      selectedTests,
      selectedChecklists,
      selectedCourses,
      selectedDocuments,
      signatureDocuments,
      tests,
    } = this.state;

    if (arr.length === 0) {
      const remove = [
        ...(tests
          ? tests.nodes
              .filter((t) => !selectedTests.includes(t.id))
              .map((t) => ({ contentId: t.id, contentType: 'test' }))
          : []),
        ...(checklists
          ? checklists.nodes
              .filter((t) => !selectedChecklists.includes(t.id))
              .map((t) => ({ contentId: t.id, contentType: 'checklist' }))
          : []),
        ...(courses && courses.nodes
          ? courses.nodes
              .filter((t) => !selectedCourses.includes(t.id))
              .map((t) => ({ contentId: t.id, contentType: 'course' }))
          : []),
        ...(signatureDocuments
          ? signatureDocuments.nodes
              .filter((t) => !selectedDocuments.includes(t.id))
              .map((t) => ({
                contentId: t.id,
                contentType: 'signatureDocument',
              }))
          : []),
      ];

      this.contentRemove(remove);
    } else {
      const { contentId, contentType } = arr[0];

      orgContentAddMutation({
        variables: {
          input: {
            org: params.facility,
            contentType,
            contentId,
          },
          refetchQueries: [OrgQuery],
        },
      }).then((response) => {
        const {
          data: {
            orgContentAdd: { errors, success },
          },
        } = response;

        if (success) {
          this.contentAdd(arr.slice(1, arr.length));
        } else {
          window.alert(
            `There was an error adding test ${contentId}.\n\n${errors[0].message}`,
          );
        }
      });
    }
  };

  contentRemove = (arr) => {
    const { orgContentRemoveMutation, params } = this.props;

    if (arr.length === 0) {
      this.goToOrgsRoute();
    } else {
      const { contentId, contentType } = arr[0];

      orgContentRemoveMutation({
        variables: {
          input: {
            org: params.facility,
            contentType,
            contentId,
          },
        },
      }).then((response) => {
        const {
          data: {
            orgContentRemove: { errors, success },
          },
        } = response;

        if (success) {
          this.contentRemove(arr.slice(1, arr.length));
        } else {
          window.alert(
            `There was an error removing test ${contentId}.\n\n${errors[0].message}`,
          );
        }
      });
    }
  };

  goToOrgsRoute = () => {
    const { navigate, params } = this.props;

    navigate(`/organizations/${params.id}/clients`);
  };

  goToTabRoute = (tab) => {
    const { navigate, location } = this.props;

    this.handleChange('tab', tab);

    navigate(`${location.pathname}?tab=${tab}`);
  };

  handleChange = (key, value) => {
    this.setState({
      [key]: value,
    });
  };

  handleCheckboxChange = (value, enabled, type) => {
    const { modules, expirationNotificationDays, annualReminders } = this.state;
    let filterArray = [];

    switch (type) {
      case 'expirationNotificationDays':
        filterArray = expirationNotificationDays;
        break;
      case 'modules':
        filterArray = modules;
        break;
      default:
        filterArray = annualReminders;
        break;
    }

    const arr = enabled
      ? [...filterArray, value]
      : filterArray.filter((m) => m !== value);

    this.handleChange(type, arr);
  };

  save = () => {
    const { orgCreateMutation, orgUpdateMutation } = this.props;
    const {
      brandColor,
      city,
      desc,
      kind,
      id,
      modules,
      annualReminders,
      expirationNotificationDays,
      secondaryColor,
      streetAddress,
      state,
      title,
      zip,
      autoResend,
    } = this.state;

    const mutation = id ? orgUpdateMutation : orgCreateMutation;
    const data = {
      brandColor,
      csz: `${_.snakeCase(city)} ${state}, ${zip}`,
      desc,
      kind,
      modules,
      annualReminders,
      expirationNotificationDays,
      secondaryColor,
      streetAddress,
      title,
      autoResend,
    };

    if (id) data.id = id;

    if (!kind) {
      window.alert('Type of entity is required.');
    } else if (!title) {
      window.alert('Company name is required.');
    } else {
      this.setState(
        {
          loading: true,
        },
        () => {
          mutation({
            variables: {
              input: data,
            },
          }).then((response) => {
            const key = Object.keys(response.data)[0];
            const { errors, result, success } = response.data[key];

            if (success) {
              this.saveStatus(result);
            } else {
              this.handleChange('loading', false);

              window.alert(errors[0].message);
            }
          });
        },
      );
    }
  };

  saveContent = () => {
    const {
      checklists,
      courses,
      selectedTests,
      selectedChecklists,
      selectedCourses,
      selectedDocuments,
      signatureDocuments,
      tests,
    } = this.state;

    const add = [
      ...selectedTests
        .filter((id) => !tests.nodes.map((t) => t.id).includes(id))
        .map((t) => ({ contentId: t, contentType: 'test' })),
      ...selectedChecklists
        .filter((id) => !checklists.nodes.map((t) => t.id).includes(id))
        .map((t) => ({ contentId: t, contentType: 'checklist' })),
      ...selectedCourses
        .filter((id) => !courses.nodes.map((t) => t.id).includes(id))
        .map((t) => ({ contentId: t, contentType: 'course' })),
      ...selectedDocuments
        .filter((id) => !signatureDocuments.nodes.map((t) => t.id).includes(id))
        .map((t) => ({ contentId: t, contentType: 'signatureDocument' })),
    ];

    this.contentAdd(add);
  };

  saveStatus = (result) => {
    const { orgActivateMutation, orgDeactivateMutation } = this.props;
    const { defaultStatus, status } = this.state;

    if (defaultStatus !== status) {
      const mutation =
        status === 'active' ? orgActivateMutation : orgDeactivateMutation;

      mutation({
        variables: {
          input: {
            id: result.id,
          },
        },
      }).then((response) => {
        const key = Object.keys(response.data)[0];
        const { errors, success } = response.data[key];

        if (success) {
          this.uploadFile(result);
        } else {
          window.alert(errors[0].message);
        }
      });
    } else {
      this.uploadFile(result);
    }
  };

  uploadFile = async (result) => {
    const { file } = this.state;

    if (file) {
      const that = this;
      const xhr = new XMLHttpRequest();
      const formData = new FormData();
      const url = process.env.REACT_APP_ATTACHMENT_URL;

      formData.append('file', file);

      xhr.open('POST', url);

      xhr.onreadystatechange = () => {
        if (xhr.readyState === 4) {
          const response = JSON.parse(xhr.response);

          that.uploadAttachment(response, result);
        }
      };

      xhr.send(formData);
    } else {
      this.saveContent();
    }
  };

  uploadAttachment = (attachment, org) => {
    const { attachmentCreateMutation, attachmentUpdateMutation } = this.props;
    const { attachments } = this.state;

    const a = attachments.find((file) => file.label === 'pdf_logo');
    const mutation = a ? attachmentUpdateMutation : attachmentCreateMutation;
    const data = {
      attachment,
      label: 'pdf_logo',
    };

    if (a) data.id = a.id;
    else data.orgId = org.id;

    mutation({
      variables: {
        input: data,
      },
    }).then((response) => {
      const key = Object.keys(response.data)[0];
      const { errors, success } = response.data[key];

      if (success) {
        this.saveContent();
      } else {
        window.alert(errors[0].message);
      }
    });
  };

  render() {
    return this.state.onload ? (
      <LoadingPane />
    ) : (
      <Org
        {...this.props}
        goToOrgsRoute={this.goToOrgsRoute}
        goToTabRoute={this.goToTabRoute}
        handleChange={this.handleChange}
        handleCheckboxChange={this.handleCheckboxChange}
        save={this.save}
        state={this.state}
      />
    );
  }
}

export default compose(
  withRouter,
  graphql(OrgQuery, {
    name: 'orgQuery',
    options: (props) => ({
      variables: {
        id: props.params.facility,
      },
    }),
  }),
  graphql(AttachmentCreateMutation, { name: 'attachmentCreateMutation' }),
  graphql(AttachmentUpdateMutation, { name: 'attachmentUpdateMutation' }),
  graphql(OrgActivateMutation, { name: 'orgActivateMutation' }),
  graphql(OrgContentAddMutation, { name: 'orgContentAddMutation' }),
  graphql(OrgContentRemoveMutation, { name: 'orgContentRemoveMutation' }),
  graphql(OrgCreateMutation, { name: 'orgCreateMutation' }),
  graphql(OrgDeactivateMutation, { name: 'orgDeactivateMutation' }),
  graphql(OrgUpdateMutation, { name: 'orgUpdateMutation' }),
)(OrgContainer);
