import { gql } from 'apollo-boost';

const UserTestRemoveMutation = gql`
  mutation UserTestRemoveMutation($input: IDInput) {
    userTestRemove(input: $input) {
      errors {
        code
        message
      }
      success
    }
  }
`;

export default UserTestRemoveMutation;
