import { gql } from 'apollo-boost';

const OrgCreateMutation = gql`
  mutation OrgCreateMutation($input: OrgCreateInput) {
    orgCreate(input: $input) {
      errors {
        code
        message
      }
      success
      result {
        id
      }
    }
  }
`;

export default OrgCreateMutation;
