import React from 'react';
import { Image, Document, Page, Text, View, pdf } from '@react-pdf/renderer';

import _ from 'lodash';
import html2canvas from 'html2canvas';
import moment from 'moment';

import Signature from '../../../../components/Documents/Document/Signature';

import STRINGS from '../../../../localization';

const refs = {};

export default class SignatureContainer extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      ...props.state,
      edit: props.params.document === 'create',
      isModal: false,
      issuedOn: moment().format('YYYY-MM-DD'),
      loading: false,
      user: props.context.user,
    };
  }

  componentDidUpdate() {
    if (!_.isEqual(this.props.mobile, this.state.mobile)) {
      this.handleChange('mobile', this.props.state.mobile);
    }
  }

  documentRemove = (hide) => {
    this.setState(
      {
        loading: true,
      },
      () => this.props.documentRemove(hide),
    );
  };

  handleChange = (key, value) => {
    this.setState(_.set(this.state, key, value));
  };

  save = () => {
    const { save } = this.props;
    const { kind, issuedOn, signature } = this.state;

    if (issuedOn && signature) {
      this.setState(
        {
          loading: true,
        },
        () => {
          const data = {
            documentNumber: 'n/a',
            kind,
            issuedOn,
            issuingState: 'n/a',
            title: STRINGS[_.camelCase(kind)],
          };

          html2canvas(refs.pdf).then(async (screenshot) => {
            const formData = await screenshot.toDataURL();

            const DocumentPdf = (
              <Document>
                <Page
                  style={{
                    padding: 30,
                  }}
                >
                  <Image src={{ uri: formData }} />

                  <View
                    style={{
                      display: 'flex',
                      flexDirection: 'column',
                    }}
                  >
                    <View
                      style={{
                        width: 200,
                        display: 'flex',
                        alignItems: 'center',
                        flexDirection: 'column',
                      }}
                    >
                      <Image
                        style={{
                          position: 'relative',
                          top: 10,
                          width: '75%',
                          height: 40,
                        }}
                        src={{ uri: signature }}
                      />

                      <Text
                        style={{
                          width: 200,
                          fontSize: 6,
                          color: '#2A2A2A',
                          borderTop: '1pt solid black',
                          margin: '5 0 20 0',
                          padding: 2.5,
                        }}
                      >
                        Signature
                      </Text>
                    </View>

                    <View
                      style={{
                        width: 200,
                        display: 'flex',
                        flexDirection: 'column',
                      }}
                    >
                      <Text
                        style={{
                          fontSize: 6,
                          color: '#2A2A2A',
                          paddingLeft: 2.5,
                        }}
                      >
                        {moment(issuedOn).format('MMM D, YYYY')}
                      </Text>

                      <Text
                        style={{
                          fontSize: 6,
                          padding: 2.5,
                          marginTop: 2.5,
                          color: '#2A2A2A',
                          borderTop: '1pt solid black',
                        }}
                      >
                        Date
                      </Text>
                    </View>
                  </View>
                </Page>
              </Document>
            );

            pdf(DocumentPdf)
              .toBlob()
              .then(async (result) => {
                const attachment = await this.props.saveAttachment({
                  name: 'test',
                  base64: result,
                });

                data.image = attachment;

                save(data);
              });
          });
        },
      );
    }
  };

  render() {
    return (
      <Signature
        {...this.props}
        documentRemove={this.documentRemove}
        handleChange={this.handleChange}
        refs={refs}
        save={this.save}
        state={this.state}
      />
    );
  }
}
