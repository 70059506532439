import { gql } from 'apollo-boost';

const TestSummaryRemoveMutation = gql`
  mutation ($input: IDInput) {
    testSummaryRemove(input: $input) {
      errors {
        code
        message
      }
    }
  }
`;

export default TestSummaryRemoveMutation;
