import { gql } from 'apollo-boost';

const AttachmentCreateMutation = gql`
  mutation AttachmentCreateMutation($input: AttachmentCreateCreateInput) {
    attachmentCreate(input: $input) {
      errors {
        code
        message
      }
      success
    }
  }
`;

export default AttachmentCreateMutation;
