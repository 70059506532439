import React from 'react';
import FeedbackRequest from './FeedbackRequest';

const ModalView = (props) => {
  const { index, feedbackForm, closeModal, onSaveFeedback } = props;

  return (
    <div className="modaloverlay">
      <div className="modal" style={{ height: '540px', width: '500px' }}>
        <FeedbackRequest
          {...props.props}
          closeModal={closeModal}
          feedbackForm={feedbackForm}
          index={index}
          onSaveFeedback={onSaveFeedback}
        />
        ;
      </div>
    </div>
  );
};

export default ModalView;
