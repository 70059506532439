import React from 'react';

const SearchField = (props) => {
  const { onChange, placeholder } = props;

  return (
    <div className="search-field">
      <div className="search-icon">
        <img
          src={require('../../../theme/images/search-icon-2x.png')}
          alt="search-icon"
        />
      </div>

      <input
        className="search-field-input"
        placeholder={placeholder}
        onChange={onChange}
      />
    </div>
  );
};

export default SearchField;
