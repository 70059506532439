import React from 'react';

import LoadingPane from '../Shared/LoadingPane';
import Switch from '../Shared/Switch';

import Content from '../../components/Org/tabs/Content';
import Reporting from '../../containers/Org/tabs/Reporting';
import Info from './tabs/Info';

const Org = (props) => {
  const { goToOrgsRoute, goToTabRoute, handleChange, location, save, state } =
    props;

  const searchParams = new URLSearchParams(location.search);

  return (
    <div className="org-view full-screen-view">
      {state.loading ? (
        <LoadingPane style={{ background: 'rgba(0,0,0,0.1)' }} />
      ) : null}

      <div className="toolbar-view">
        <div className="cancel-button" onClick={goToOrgsRoute}>
          Cancel
        </div>

        <div className="page-header">{`${
          state.id ? 'View' : 'Add'
        } Client`}</div>

        <div className="right-frame">
          <div className={state.tab === 'info' ? 'switch-frame' : 'hide'}>
              <Switch
                defaultValue={state.autoResend}
                onChange={(enabled) =>
                  handleChange('autoResend', enabled)
                }
              />

              <div className="switch-label">{`Auto Resend`}</div>
            </div>
          <div className={state.tab === 'info' ? 'switch-frame' : 'hide'}>
            <Switch
              defaultValue={state.status === 'active'}
              onChange={(enabled) =>
                handleChange('status', enabled ? 'active' : 'inactive')
              }
            />

            <div className="switch-label">{`Client is ${state.status}`}</div>
          </div>

          <div
            className={
              searchParams.get('tab') === 'reporting' ? 'hide' : 'save-button'
            }
            onClick={save}
          >
            Save
          </div>
        </div>
      </div>

      {state.id ? (
        <ul className="tabs-view">
          {state.tabs.map((tab, i) => {
            return (
              <li
                className={
                  state.tab === tab.route ? 'tab-cell selected' : 'tab-cell'
                }
                key={i}
                onClick={() => goToTabRoute(tab.route)}
              >
                <div className="tab-name">{tab.name}</div>
              </li>
            );
          })}
        </ul>
      ) : null}

      <div className="content-frame scroll">{renderTabView(props)}</div>
    </div>
  );
};

function renderTabView(props) {
  const { tab } = props.state;

  if (tab === 'info') {
    return <Info {...props} />;
  } else if (tab === 'content') {
    return <Content {...props} />;
  } else if (tab === 'reporting') {
    return <Reporting {...props} />;
  }
}

export default Org;
