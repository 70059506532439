import React from 'react';

import DatePicker from '../../Shared/DatePicker';
import ModalPane from '../../Shared/ModalPane';

import STRINGS from '../../../localization';
import FileInput from '../../Control/FileInput';

const RenewDocumentPane = (props) => {
  const { documentRenew, handleChange, resetDocumentRenewal, state } = props;
  const { isModal2 } = state;

  return (
    <ModalPane
      className="document-renew-pane"
      content={(hide) => (
        <div className="full-screen-view">
          <div className="modal-toolbar">
            <button className="modal-toolbar-button bg-grey-2" onClick={hide}>
              {STRINGS.cancel}
            </button>

            <div className="modal-toolbar-header capitalize">{`${STRINGS.renew} ${STRINGS.document}`}</div>

            <button
              className={`modal-toolbar-button ${
                state.renewIssuedOn && state.renewExpiresOn
                  ? 'bg-aqua'
                  : 'bg-disabled'
              }`}
              onClick={() => documentRenew(hide)}
            >
              {STRINGS.save}
            </button>
          </div>

          <div className="form-frame">
            <div className="key-value-frame">
              <div className="value-label capitalize">{STRINGS.issueDate}</div>

              <DatePicker
                onChange={(date) => handleChange('renewIssuedOn', date)}
              />
            </div>

            <div className="key-value-frame">
              <div className="value-label capitalize">
                {STRINGS.issueExpireDate}
              </div>

              <DatePicker
                future={true}
                futureAmt={5}
                onChange={(date) => handleChange('renewExpiresOn', date)}
              />
            </div>

            <div className="key-value-frame last">
              <div className="value-label">{STRINGS.photoOfLicense}</div>
              <FileInput handleChange={handleChange} fileType={'renewFile'} image={state.renewFile} imageUrl={state.renewFile.name} strings={STRINGS} />
            </div>
          </div>
        </div>
      )}
      show={isModal2}
      hide={resetDocumentRenewal}
    />
  );
};

export default RenewDocumentPane;
