import React from 'react';

import DatePicker from '../../../Shared/DatePicker';
import SelectMenu from '../../../Shared/SelectMenu';
import FileInput from '../../../Control/FileInput';

import STRINGS from '../../../../localization';

const TBPPD = (props) => {
  const { handleChange, state } = props;
  const {
    expiresOn,
    image,
    imageBack,
    imageUrl,
    imageBackUrl,
    issuedOn,
    title,
    verificationData,
  } = state;

  return (
    <div className={title ? 'flex-frame' : 'hidden'}>
      <div className="key-value-frame">
        <div className="value-label capitalize">{STRINGS.result}</div>

        <SelectMenu
          className="key-value-menu"
          onChange={(e) =>
            handleChange('verificationData.result1', e.target.value)
          }
          options={[
            { id: 'positive', value: 'Positive' },
            { id: 'negative', value: 'Negative' },
          ]}
          placeholder={`${STRINGS.select} ${STRINGS.answer}`}
          value={verificationData.result1 || 'hint'}
        />
      </div>

      <div className="key-value-frame">
        <div className="value-label capitalize">{STRINGS.dateReceived}</div>

        <DatePicker
          defaultValue={issuedOn}
          onChange={(date) => handleChange('issuedOn', date)}
        />
      </div>

      <div className="key-value-frame">
        <div className="value-label capitalize">{STRINGS.issueExpireDate}</div>

        <DatePicker
          defaultValue={expiresOn}
          onChange={(date) => handleChange('expiresOn', date)}
        />
      </div>
      
      <div className="key-value-frame">
        <div className="value-label capitalize">{`${STRINGS.image} 1`}</div>
        <FileInput handleChange={handleChange} fileType={'image'} image={image} imageUrl={imageUrl} strings={STRINGS} />
      </div>

      <div className="key-value-frame">
        <div className="value-label capitalize">{`${STRINGS.image} 2`}</div>
        <FileInput handleChange={handleChange} fileType={'imageBack'} image={imageBack} imageUrl={imageBackUrl} strings={STRINGS} />
      </div>

      <div className="key-value-frame empty" />
    </div>
  );
};

export default TBPPD;
