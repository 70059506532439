import { gql } from 'apollo-boost';

const BundleCreateMutation = gql`
  mutation BundleCreateMutation($input: BundleCreateInput) {
    bundleCreate(input: $input) {
      errors {
        code
        message
      }
      result {
        id
      }
    }
  }
`;

export default BundleCreateMutation;
