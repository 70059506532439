import React from 'react';
import { graphql } from 'react-apollo';
import compose from 'lodash.flowright';

import Cookie from 'js-cookie';
import moment from 'moment';

import SessionLoginMutation from '../../graphql/mutations/Session/Login';
import UserUpdateMutation from '../../graphql/mutations/User/Update';

import PasswordReset from '../../components/PasswordReset';

import LoadingPane from '../../components/Shared/LoadingPane';
import { withRouter } from '../withRouter';

class PasswordResetContainer extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      loading: false,
      onload: true,
      password1: '',
      password2: '',
    };
  }

  componentDidMount() {
    const { location, navigate } = this.props;

    const searchParams = new URLSearchParams(location.search);
    const email = searchParams.get('e');
    const password = searchParams.get('p');

    if (password) {
      this.setState(
        {
          email,
          onload: false,
          password,
        },
        () => navigate(`${location.pathname}?e=${encodeURIComponent(email)}`),
      );
    } else {
      this.setState({
        email,
        onload: false,
      });
    }
  }

  goToBaseRoute = () => {
    this.props.navigate('/');
  };

  handleChange = (key, value) => {
    this.setState({
      [key]: value,
    });
  };

  resetPassword = () => {
    const { sessionLoginMutation, userUpdateMutation } = this.props;
    const { email, password, password1 } = this.state;

    sessionLoginMutation({
      variables: {
        input: {
          email,
          password,
        },
      },
    }).then((response) => {
      const { token, user } = response.data.sessionLogin;

      const allowedRoles = ['corporate', 'super_admin', 'admin', 'recruiter'];

      if (user) {
        Cookie.set(`una-${global.environment}`, user.id);
        Cookie.set(`una-${global.environment}-token`, token);
        Cookie.set(
          `una-exp-${global.environment}`,
          moment().add('7', 'day').toDate().toISOString(),
        );

        if (user.roles.length === 0) {
          this.setState({
            loading: false,
            error: 'User has no roles. Contact support.',
          });
        } else if (!user.roles.find((r) => allowedRoles.includes(r.name))) {
          this.setState({
            loading: false,
            error: 'User does not have admin privileges.',
          });
        } else {
          userUpdateMutation({
            variables: {
              input: {
                id: user.id,
                password: password1,
              },
            },
          }).then((response2) => {
            const {
              data: {
                userUpdate: { errors, success },
              },
            } = response2;

            if (success) {
              window.location.href = '/';
            } else {
              this.setState({
                error: errors[0].message,
                loading: false,
              });
            }
          });
        }
      } else {
        this.setState({
          error: 'Current password is incorrect.',
          loading: false,
        });
      }
    });
  };

  save = () => {
    const { email, password, password1, password2 } = this.state;

    if (!email) {
      this.props.navigate('/error');
    }
    if (!password || !password1 || !password2) {
      this.handleChange('error', 'All fields are required.');
    } else if (password1 !== password2) {
      this.handleChange('error', 'Passwords do not match.');
    } else if (password1.length < 6) {
      this.handleChange(
        'error',
        'Password does not meet minimum requirements.',
      );
    } else {
      this.setState(
        {
          loading: true,
        },
        () => this.resetPassword(),
      );
    }
  };

  render() {
    return this.state.onload ? (
      <LoadingPane />
    ) : (
      <PasswordReset
        goToBaseRoute={this.goToBaseRoute}
        handleChange={this.handleChange}
        save={this.save}
        state={this.state}
      />
    );
  }
}

export default compose(
  withRouter,
  graphql(SessionLoginMutation, {
    name: 'sessionLoginMutation',
  }),
  graphql(UserUpdateMutation, { name: 'userUpdateMutation' }),
)(PasswordResetContainer);
