import React from 'react';
import { graphql } from 'react-apollo';
import { pdfjs } from 'react-pdf';

import { camelCase, flowRight as compose } from 'lodash';

import UserQuery from '../../graphql/queries/Roster/Packet';
import AttendanceQuery from '../../graphql/queries/Document/Reference/Attendance';

import RosterPacket from '../../components/Roster/Packet';
import ChecklistScore from '../../components/Shared/Checklist/ChecklistScore';
import LoadingPane from '../../components/Shared/LoadingPane';
import { withRouter } from '../withRouter';

import { tokenList } from '../../utils/Constants';

class RosterPacketContainer extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      certifications: [],
      checklists: [],
      courses: [],
      credentials: {
        certification: [],
        education: [],
        license: [],
        misc: [],
      },
      education: [],
      experiences: [],
      licenses: [],
      loading: false,
      onload: true,
      pdf: false,
      summary: false,
      tests: [],
    };
  }

  componentDidMount() {
    this.setup();

    pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;
  }

  componentDidUpdate() {
    this.setup();
  }

  setup = () => {
    const {
      userQuery: { loading, org, user },
      attendanceQuery: {
        loading: attLoading,
        referenceAttendancePunctualities,
      },
    } = this.props;
    const { onload } = this.state;

    if (onload && !loading && !attLoading) {
      const { homeCity, homeState, homeStreet1, homeStreet2, homeZip } = user;

      const isAddressValid = homeStreet1 && homeCity && homeStreet1 && homeZip;
      const attendanceOptions = referenceAttendancePunctualities.reduce(
        (prev, val) => {
          const { optionValue, optionText } = val;
          return {
            ...prev,
            [optionValue]: optionText,
          };
        },
        {},
      );
      const filterByOrg = (contentArr) => {
        return contentArr.filter((item) => {
          const displayedOrg = parseInt(org.id, 10);
          if (
            !item.orgId ||
            displayedOrg === 1 ||
            displayedOrg === parseInt(item.orgId, 10)
          ) {
            return true;
          }
          return false;
        });
      };

      this.setState({
        ...user,
        address: isAddressValid
          ? `${homeStreet1}${
              homeStreet2 ? ` ${homeStreet2}` : ''
            } ${homeCity}, ${homeState} ${homeZip}`
          : null,
        testsTaken: filterByOrg(user.testsTaken),
        checklistsTaken: filterByOrg(user.checklistsTaken),
        coursesTaken: filterByOrg(user.coursesTaken),
        credentials: this.setupDocuments(user.documents),
        documents: [],
        org,
        onload: false,
        references: [],
        referencesTaken: user.references,
        specialty: user.specialties.find((s) => s.isPrimary),
        attendanceOptions,
      });
    }
  };

  setupDocuments = (documents) => {
    const credentials = this.state.credentials;

    documents.nodes.forEach((document) => {
      const kind = camelCase(document.kind);
      const collection = credentials[kind];

      if (collection) collection.push(document);
      else credentials.misc.push(document);
    });

    return credentials;
  };

  formatPhone = (str) => {
    //Filter only numbers from the input
    const cleaned = `${str}`.replace(/\D/g, '');

    //Check if the input is of correct
    const match = cleaned.match(/^(1|)?(\d{3})(\d{3})(\d{4})$/);

    if (match) {
      //Remove the matched extension code
      //Change this to format for any country code.
      const intlCode = match[1] ? '+1 ' : '';

      return [intlCode, '(', match[2], ') ', match[3], '-', match[4]].join('');
    }

    return '-';
  };

  generateContentUrl = (result) => {
    const getRandomIndexToken = (tokenList) => {
      const randomIndex = Math.floor(Math.random() * tokenList.length);
      return tokenList[randomIndex]
    }

    const token = getRandomIndexToken(tokenList);

    const {
      params: { id, roster },
    } = this.props;

    const type = result.__typename;
    const types = {
      ChecklistUserChecklist: 'checklists',
      Document: 'documents',
    };

    return `/organizations/${id}/roster/${roster}/${types[type]}/${result.id}/pdf?token=${token}`;
  };

  handleChange = (key, value) => {
    this.setState({
      [key]: value,
    });
  };

  handleContactSelection = (result) => {
    const arr = this.state.contactDisplay || [];
    const i = arr.findIndex((r) => r === result);

    this.handleChange(
      'contactDisplay',
      i >= 0
        ? [arr.slice(0, i), arr.slice(i + 1, arr.length)].flat()
        : [...arr, result],
    );
  };
  handleSelection = (key, result) => {
    const arr = this.state[key];
    const i = arr.findIndex((r) => r.id === result.id);

    this.handleChange(
      key,
      i >= 0
        ? [arr.slice(0, i), arr.slice(i + 1, arr.length)].flat()
        : [...arr, result],
    );
  };

  parseChecklist = (checklist, answers) => {
    const returnCL = ChecklistScore.calculateAvgChecklistScore(
      checklist,
      answers,
    );
    returnCL.checklistPct = (returnCL.avgQuestionScore / 4) * 100;
    return returnCL;
  };

  render() {
    return this.state.onload ? (
      <LoadingPane />
    ) : (
      <RosterPacket
        {...this.props}
        formatPhone={this.formatPhone}
        generateContentUrl={this.generateContentUrl}
        handleChange={this.handleChange}
        handleSelection={this.handleSelection}
        handleContactSelection={this.handleContactSelection}
        parseChecklist={this.parseChecklist}
        state={this.state}
      />
    );
  }
}

export default compose(
  withRouter,
  graphql(UserQuery, {
    name: 'userQuery',
    options: ({ params: { id, roster } }) => ({
      variables: {
        id: roster,
        org: id,
      },
    }),
  }),
  graphql(AttendanceQuery, { name: 'attendanceQuery' }),
)(RosterPacketContainer);
