import { gql } from 'apollo-boost';

const RosterUploadUsersQuery = gql`
  query RosterUploadUsers($search: String) {
    users(search: $search) {
      nodes {
        id
        email
        roles {
          id
          role
          orgId
        }
      }
    }
  }
`;

export default RosterUploadUsersQuery;
