import { gql } from 'apollo-boost';

const BundleUpdateMutation = gql`
  mutation BundleUpdateMutation($input: BundleUpdateInput) {
    bundleUpdate(input: $input) {
      errors {
        code
        message
      }
      result {
        id
      }
    }
  }
`;

export default BundleUpdateMutation;
