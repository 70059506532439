import React from 'react';

import _ from 'lodash';

import PPD from '../../../../components/Documents/Document/PPD';

import STRINGS from '../../../../localization';

const types = [
  { id: 'two_step', value: STRINGS.twoStep },
  { id: 'tb_skin_test', value: STRINGS.tbSkinTest },
];
export default class PPDContainer extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      ...props.state,
      edit: props.params.document === 'create',
      isModal: false,
      loading: false,
      verificationData: {},
      user: props.context.user,
    };
  }

  componentDidMount() {
    const { params, state } = this.props;

    if (params.document !== 'create') {
      const { title, verificationData } = state;

      this.setState({
        title: types.find((o) => title.includes(o.value)).id,
        verificationData,
      });
    }
  }

  componentDidUpdate() {
    if (!_.isEqual(this.props.mobile, this.state.mobile)) {
      this.handleChange('mobile', this.props.state.mobile);
    }
  }

  documentRemove = (hide) => {
    this.setState(
      {
        loading: true,
      },
      () => this.props.documentRemove(hide),
    );
  };

  handleChange = (key, value) => {
    this.setState(_.set(this.state, key, value));
  };

  save = () => {
    const { save, saveAttachment } = this.props;
    const {
      expiresOn,
      kind,
      image,
      imageBack,
      issuedOn,
      title,
      verificationData,
    } = this.state;
    const { date2, result1, result2 } = verificationData;

    const valid =
      title === 'tb_skin_test'
        ? title && result1 && issuedOn && expiresOn
        : title && result1 && result2 && date2 && issuedOn && expiresOn;
    const data = {
      documentNumber: 'n/a',
      expiresOn,
      kind,
      issuedOn,
      issuingState: 'n/a',
      title: `${types.find((o) => o.id === title).value} ${STRINGS.ppd}`,
      verificationData,
    };

    if (valid) {
      this.setState(
        {
          loading: true,
        },
        async () => {
          if (image) data.image = await saveAttachment(image);

          if (imageBack) data.imageBack = await saveAttachment(imageBack);

          save(data);
        },
      );
    }
  };

  render() {
    return (
      <PPD
        {...this.props}
        documentRemove={this.documentRemove}
        handleChange={this.handleChange}
        save={this.save}
        state={this.state}
        types={types}
      />
    );
  }
}
