import { gql } from 'apollo-boost';

const UsersQuery = gql`
  query UsersQuery($search: String) {
    users(search: $search) {
      nodes {
        id
        email
        roles {
          id
          name
          org {
            id
          }
        }
      }
    }
  }
`;

export default UsersQuery;
