import React, { useState, useEffect } from 'react';
import OptionalFactoryQuery from '../../graphql/queries/Org/Feedback/FeedbackFactors';
import { graphql } from 'react-apollo';
import compose from 'lodash.flowright';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrash } from '@fortawesome/free-solid-svg-icons';
import ModalView from './ModalView.js'; // Import your ModalView component
import FeedbackRequestCreateMutation from '../../graphql/mutations/360Feedback/Category/create';
import FeedbackSeekerDetails, {
  UpdateFeedbackSeekerRole,
} from '../../graphql/mutations/360Feedback/Category/FeedbackSeekerdetails';
import axios from 'axios';
import Cookie from 'js-cookie';
import { withRouter } from '../../containers/withRouter';


const DashboardFeedback = (props) => {
  const [Alert, setAlert] = useState('');
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [FeedbackSeekerID, setFeedbackSeekerID] = useState();

  const [FormarArr, setFormarArr] = useState([
    {
      fname: '',
      lname: '',
      email: '',
      company: '',
      report: '',
      qualitywork: '',
      ftype: '',
      ofactory: [],
      orgId: Number(props.state.role.org.id),
      requestingUserId: '',
    },
  ]);

  const [FeedbackSeekerdetails, setFeedbackSeekerdetails] = useState([
    {
      email: '',
      firstName: '',
      lastName: '',
      phoneNumber: '',
      password: 'Welcome2020',
      company: '',
      startDate: '',
      endDate: '',
    },
  ]);

  useEffect(() => { }, [FormarArr, FeedbackSeekerID]);

  const options = [
    { value: 'formar', label: 'Former' },
    { value: 'current', label: 'Current' },
  ];

  const addRow = () => {
    var update = {
      fname: '',
      lname: '',
      email: '',
      company: '',
      report: '',
      qualitywork: '',
      ftype: '',
      ofactory: [],
      orgId: Number(props.state.role.org.id),
    };
    setFormarArr([...FormarArr, update]);
  };

  const deleteRow = (index) => {
    FormarArr.splice(index, 1);
    setFormarArr([...FormarArr]);
  };

  const onViewfeedback = () => {
    const { navigate, state } = props;
    navigate(`/viewfeedbacks`, {
      state: {
        org: state.role.org.id,
      },
    });
  };

  const onSave = () => {
    const {
      feedbackRequestCreateMutation,
      FeedbackSeekerDetails,
      UpdateFeedbackSeekerRole,
    } = props;
    const isAllValid = FormarArr.every(
      (obj) =>
        obj.fname &&
        obj.lname &&
        obj.email &&
        obj.ftype &&
        Array.isArray(obj.ofactory) &&
        obj.ofactory.length > 0,
    );
    const isAllValidSeeker = FeedbackSeekerdetails.every(
      (obj) =>
        obj.firstName &&
        obj.lastName &&
        obj.email &&
        obj.phoneNumber &&
        obj.company &&
        obj.endDate &&
        obj.startDate,
    );
    if (!isAllValidSeeker) {
      setAlert('Please fill Mandatory Fields');
      return;
    }
    if (!isAllValid) {
      setAlert('Please fill Mandatory Fields');
      return;
    }
    const isEmailValid = FormarArr.every((obj) =>
      /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(obj.email),
    );
    const isEmailValidforSeeker = FeedbackSeekerdetails.every((obj) =>
      /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(obj.email),
    );
    if (!isEmailValidforSeeker) {
      setAlert('Check the email');
      return;
    }
    if (!isEmailValid) {
      setAlert('Check the email');
      return;
    }

    {
      FeedbackSeekerdetails.map((row) => {
        FeedbackSeekerDetails({
          variables: {
            input: {
              email: row.email,
              firstName: row.firstName,
              lastName: row.lastName,
              phoneNumber: row.phoneNumber,
              password: row.password,
              feedbackSeekerDetails: {
                company: row.company,
                startDate: row.startDate,
                endDate: row.endDate,
              },
            },
          },
        })
          .then((response) => {
            const { errors: err, result, success } = response.data.userFindOrCreate
            if (success) {

              setFeedbackSeekerID(response.data.userFindOrCreate.result.id);
              UpdateFeedbackSeekerRole({
                variables: {
                  input: {
                    id: response.data.userFindOrCreate.result.id,
                    roles: [
                      {
                        role: 'feedback_seeker',
                        orgId: props.state.role.org.id,
                      },
                    ],
                  },
                },

              })
                .then((response) => {
                  const updatedFormarArr = FormarArr.map((item) => ({
                    ...item,
                    requestingUserId: response.data.userUpdate.result.id,
                  }));

                  feedbackRequestCreateMutation({
                    variables: {
                      input: {
                        items: updatedFormarArr,
                      },
                    },
                  })
                    .then((response) => {
                      console.log('res from feedback request', response)
                      const { result } = response.data.feedbackRequestCreate
                      sendEmail(FormarArr, result);
                    })
                    .catch((error) => {
                      console.error('Error creating feedback requests:', error);
                    });
                })
                .catch((error) => {
                  console.error('Error creating feedback requests:', error);
                });
            } else {
              setAlert(err[0].message)
            }
          })
          .catch((error) => {
            console.error('Error creating feedback requests:', error);
          });
      });
    }
  };

  const onSaveFeedback = (feedbackInputArray) => {
    setFormarArr(feedbackInputArray);
  };

  const onCloseAlert = () => {
    setAlert('');
  };
  const updateField = (index, fieldName, input) => {
    setFormarArr((prevUpdate) => {
      const updatedArray = [...prevUpdate];
      updatedArray[index][fieldName] = input;
      return updatedArray;
    });
  };

  const updateFieldforSeeker = (index, fieldName, input) => {
    setFeedbackSeekerdetails((prevUpdate) => {
      const updatedArray = [...prevUpdate];
      updatedArray[index][fieldName] = input;
      return updatedArray;
    });
  };

  const sendEmail = (FormarArr, result) => {
    FormarArr.forEach((formar) => {
      const { attachment } = props.state.role.org.attachment.url;
      const { brandColor } = props.state.role.org.brandColor;
      const defaultColor = '#FF5A5F';
      const url = `${process.env.REACT_APP_PROXY_URL}/send`;
      const defaultLogo =
        'https://res.cloudinary.com/inewton/image/upload/v1/una-app/cache/e80cad79f0f6e17fd3c8254c763c2b8d.png';

      const emailData = {
        dynamicTemplateData: {
          email: formar.email,
          password: 'Welcome2020',
          name: 'UNA Admin',
          url: `${process.env.REACT_APP_UNA_ADMIN_URL}/feedback/${result[0].ulid}`,
          color: attachment ? brandColor : defaultColor,
          logo: attachment ? attachment.url : defaultLogo,
        },
        environment: 'production',
        recipient: formar.email,
        templateId: 'd-62dcb32d7374487a8aadc811cc4e14d7',
      };
      const headers = {
        Authorization: Cookie.get(`${global.COOKIE_NAME}-token`),
      };

      axios.post(url, emailData, { headers }).then((response) => {
        if (response.status === 200) {
          setAlert('Feedback requests created');
          setFeedbackSeekerdetails([
            {
              email: '',
              firstName: '',
              lastName: '',
              phoneNumber: '',
              password: 'Welcome2020',
              company: '',
              startDate: '',
              endDate: '',
            },
          ]);
          setFormarArr([
            {
              fname: '',
              lname: '',
              email: '',
              company: '',
              report: '',
              qualitywork: '',
              ftype: '',
              ofactory: [],
              orgId: Number(props.state.role.org.id),
              requestingUserId: '',
            },
          ]);
        } else {
          setAlert('Something went wrong, Please Check Again!');
        }
      });
    });
  };

  const loadTablebody = (row, index) => {
    return (
      <React.Fragment key={`formar_${index}`}>
        <div
          key={`row_${index}`}
          className={'document-frame'}
          style={{ position: 'relative' }}
        >
          <div className="key-value-frame">
            <div className="value-label capitalize">
              {'First Name'}
              <span style={{ color: '#ce1818' }}>*</span>
            </div>

            <input
              className="key-value-input"
              onChange={(e) => updateField(index, 'fname', e.target.value)}
              placeholder={'First Name'}
              value={row.fname}
            />
          </div>

          <div className="key-value-frame">
            <div className="value-label capitalize">
              {'Last Name'}
              <span style={{ color: '#ce1818' }}>*</span>
            </div>

            <input
              className="key-value-input"
              onChange={(e) => updateField(index, 'lname', e.target.value)}
              placeholder={'Last Name'}
              value={row.lname}
            />
          </div>

          <div className="key-value-frame">
            <div className="value-label capitalize">
              {'Email'}
              <span style={{ color: '#ce1818' }}>*</span>
            </div>

            <input
              className="key-value-input"
              onChange={(e) => updateField(index, 'email', e.target.value)}
              placeholder={'Email'}
              value={row.email}
            />
          </div>

          {index === 0 ? (
            ' '
          ) : (
            <div
              className="key-value-input"
              style={{ position: 'absolute', top: '-20px', right: '-10px' }}
            >
              <button
                style={{ background: 'transparent' }}
                onClick={(e) => {
                  deleteRow(index);
                }}
              >
                <FontAwesomeIcon icon={faTrash} size={'sm'} />
              </button>
            </div>
          )}
          <div className="key-value-frame">
            <div className="value-label capitalize">
              {'Type'}
              <span style={{ color: '#ce1818' }}>*</span>
            </div>
            <SelectMenu
              className="key-value-menu"
              onChange={(e) => updateField(index, 'ftype', e.target.value)}
              placeholder={'Select'}
              options={options}
              value={row.ftype || 'Select'}
            />
          </div>

          <div className="key-value-frame">
            <div className="value-label capitalize">{'Company'}</div>

            <input
              className="key-value-input"
              onChange={(e) => updateField(index, 'company', e.target.value)}
              placeholder={'Company'}
              value={row.company}
            />
          </div>

          <div className="key-value-frame">
            <div className="value-label capitalize">{'Reported Timeline'}</div>

            <input
              className="key-value-input"
              onChange={(e) => updateField(index, 'report', e.target.value)}
              placeholder={'Reported Timeline'}
              value={row.report}
            />
          </div>
          <div className="key-value-frame">
            <div className="value-label capitalize">
              {'Work Quality Knowledge'}
            </div>

            <input
              className="key-value-input"
              onChange={(e) =>
                updateField(index, 'qualitywork', e.target.value)
              }
              placeholder={'Work Quality Knowledge'}
              value={row.qualitywork}
            />
          </div>

          <div className="key-value-frame">
            <div className="value-label capitalize">
              {'Option Factory '}
              <span style={{ color: '#ce1818' }}>*</span>
            </div>
            <button
              className="key-value-input"
              style={{ background: 'transparent', width: 100 }}
              onClick={openModal}
            >
              Add +
            </button>
            {isModalOpen && (
              <ModalView
                props={props}
                closeModal={closeModal}
                feedbackForm={[...FormarArr]}
                index={index}
                onSaveFeedback={onSaveFeedback}
              />
            )}
          </div>
          <div className="key-value-frame empty" />
        </div>
        <hr
          style={{
            width: '1000px',
            border: 'none',
            height: '1px',
            backgroundColor: 'rgba(0, 0, 0, 0.1)',
          }}
        />
      </React.Fragment>
    );
  };

  const loadTablebodyforSeeker = (row, index) => {
    return (
      <React.Fragment key={`seeker_${index}`}>
        <h4 className={'document-frame'}>User Details</h4>
        <div className={'document-frame'} style={{ position: 'relative' }}>
          <div className="key-value-frame">
            <div className="value-label capitalize">
              {'First Name'}
              <span style={{ color: '#ce1818' }}>*</span>
            </div>

            <input
              className="key-value-input"
              onChange={(e) =>
                updateFieldforSeeker(index, 'firstName', e.target.value)
              }
              placeholder={'First Name'}
              value={row.firstName}
            />
          </div>

          <div className="key-value-frame">
            <div className="value-label capitalize">
              {'Last Name'}
              <span style={{ color: '#ce1818' }}>*</span>
            </div>

            <input
              className="key-value-input"
              onChange={(e) =>
                updateFieldforSeeker(index, 'lastName', e.target.value)
              }
              placeholder={'Last Name'}
              value={row.lastName}
            />
          </div>

          <div className="key-value-frame">
            <div className="value-label capitalize">
              {'Email'}
              <span style={{ color: '#ce1818' }}>*</span>
            </div>

            <input
              className="key-value-input"
              onChange={(e) =>
                updateFieldforSeeker(index, 'email', e.target.value)
              }
              placeholder={'Email'}
              value={row.email}
              type="email"
            />
          </div>

          <div className="key-value-frame">
            <div className="value-label capitalize">
              {'Phone Number'}
              <span style={{ color: '#ce1818' }}>*</span>
            </div>
            <input
              className="key-value-input"
              onChange={(e) =>
                updateFieldforSeeker(index, 'phoneNumber', e.target.value)
              }
              type="number"
              placeholder={'Phone Number'}
              value={row.phoneNumber}
            />
          </div>

          <div className="key-value-frame">
            <div className="value-label capitalize">
              {'Company'}
              <span style={{ color: '#ce1818' }}>*</span>
            </div>
            <input
              className="key-value-input"
              onChange={(e) =>
                updateFieldforSeeker(index, 'company', e.target.value)
              }
              placeholder={'Company'}
              value={row.company}
            />
          </div>

          <div className="key-value-frame">
            <div className="value-label capitalize">
              {'Starting Date'}
              <span style={{ color: '#ce1818' }}>*</span>
            </div>
            <input
              className="key-value-input"
              type="date"
              onChange={(e) =>
                updateFieldforSeeker(index, 'startDate', e.target.value)
              }
              placeholder={'Starting Date'}
              value={row.startDate}
            />
          </div>
          <div className="key-value-frame">
            <div className="value-label capitalize">
              {'End Date'}
              <span style={{ color: '#ce1818' }}>*</span>
            </div>
            <input
              className="key-value-input"
              type="date"
              onChange={(e) =>
                updateFieldforSeeker(index, 'endDate', e.target.value)
              }
              placeholder={'End Date'}
              value={row.endDate}
            />
          </div>
          <div className="key-value-frame empty" />
        </div>
        <hr
          style={{
            width: '1000px',
            border: 'none',
            height: '1px',
            backgroundColor: 'rgba(0, 0, 0, 0.1)',
          }}
        />
      </React.Fragment>
    );
  };

  const SelectMenu = (props) => {
    const { className, onChange, options, placeholder, value } = props;

    let hint = { value: 'Select', label: placeholder },
      arr = placeholder ? [hint, ...options] : options;

    return (
      <div
        className={`select-menu-frame ${className} ${value === 'Select' ? 'bg-placeholder' : ''
          }`}
      >
        <select className="select-menu" onChange={onChange} value={value}>
          {arr.map((o) => {
            const { value, label } = o;

            return (
              <option key={label} disabled={label === 'Select'} value={value}>
                {label}
              </option>
            );
          })}
        </select>
      </div>
    );
  };

  const openModal = () => {
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
  };

  return (
    <div className="dashboard-feedback-view">
      {Alert && (
        <div className="modaloverlay">
          <div className="modal" style={{ width: '20%' }}>
            <h2>Alert</h2>
            <div
              className="model-body"
              dangerouslySetInnerHTML={{ __html: Alert }}
              style={{ display: 'inline' }}
            >
              {/* {Alert} */}
            </div>
            <div>
              <button
                type="submit"
                style={{ width: '20%' }}
                className="model-submit-button"
                onClick={onCloseAlert}
              >
                OK
              </button>
            </div>
          </div>
        </div>
      )}

      <div className="FeedbackFormerboss">
        <div className="filter-frame">
          <div className="centered-frame">
            <button
              className="create-test-button"
              style={{ color: '#FF5A5F' }}
              onClick={(e) => {
                addRow();
              }}
            >
              Add Managers +
            </button>
          </div>
          <div className="centered-frame">
            <button
              className="create-test-button"
              onClick={(e) => onViewfeedback()}
              style={{ color: '#FF5A5F', float: 'left' }}
            >
              View Feedback
            </button>
          </div>
          <div className="centered-frame"></div>
        </div>

        <div className="feedback-table-view">
          <div className="tests-list-view scroll">
            {FeedbackSeekerdetails.map((row, index) => {
              return loadTablebodyforSeeker(row, index, `seeker_${index}`);
            })}
            <h4 key="managers_details" className={'document-frame'}>
              Managers Details
            </h4>
            {FormarArr.map((row, index) => {
              return loadTablebody(row, index, `formar_${index}`);
            })}
          </div>
        </div>
      </div>

      <div style={{ marginTop: 50 }}>
        <div className="centered-frame">
          <button
            type="button"
            className="submit-btn"
            style={{
              height: 35,
              width: '12%',
              color: 'white',
              background: '#FF5A5F',
              borderRadius: 4,
            }}
            onClick={(e) => {
              onSave();
            }}
          >
            Continue
          </button>
        </div>
      </div>
    </div>
  );
};
export default compose(
  withRouter,
  graphql(OptionalFactoryQuery, {
    name: 'optionalFactoryQuery',
    options: (props) => ({
      variables: {
        id: props.state.role.org.id,
      },
    }),
  }),
  graphql(FeedbackRequestCreateMutation, {
    name: 'feedbackRequestCreateMutation',
  }),
  graphql(FeedbackSeekerDetails, {
    name: 'FeedbackSeekerDetails',
  }),
  graphql(UpdateFeedbackSeekerRole, {
    name: 'UpdateFeedbackSeekerRole',
  }),
)(DashboardFeedback);
