import { gql } from 'apollo-boost';

const RosterChecklistQuery = gql`
  query RosterChecklistQuery($id: ID, $checklist: ID, $org: ID!) {
    user(id: $id) {
      id
      firstName
      lastName
      specialties {
        id
        isPrimary
        name
      }
      checklistTaken(id: $checklist) {
        id
        answers {
          id
          question {
            id
          }
          value
        }
        endedAt
        status
        checklist {
          id
          categories {
            id
            rank
            sections {
              id
              rank
              questions {
                id
                rank
                scoreValues
                title
              }
              scoreValues
              scoreLabels
              title
            }
            title
          }
          title
        }
      }
    }
    org(id: $org) {
      id
      attachments(labels: ["pdf_logo"]) {
        id
        label
        url
      }
    }
  }
`;

export default RosterChecklistQuery;
