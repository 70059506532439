import React, { useState } from 'react';
import _ from 'lodash';
import { useQuery } from 'react-apollo';
import UsersQuery from '../../graphql/queries/Users/Users';
import LoadingPane from '../Shared/LoadingPane';
import SearchField from '../Shared/SearchField';

const DashboardUsers = () => {
  const [variable, setVariable] = useState({ first: 50, search: null });

  let users = [];
  const { loading, data, fetchMore } = useQuery(UsersQuery, {
    variables: variable,
  });

  const handleScroll = (e) => {
    const node = e.target;
    const isBottom =
      node.scrollTop >= node.scrollHeight - node.offsetHeight - 800;

    if (isBottom && data.users.pageInfo.hasNextPage) {
      _.throttle(async () => {
        await fetchMore(
          {
            variables: { after: data.users.pageInfo.endCursor },
          },
          100,
        );
      })();
    }
  };

  const setupUsers = (inputUsers) => {
    return inputUsers.map((u) => {
      const newUser = { ...u.node };
      const roles = [];
      if (newUser.roles) {
        newUser.roles.forEach((role) => roles.push(role.org?.title));
      }
      newUser.organization = roles.join(', ');
      return newUser;
    });
  };

  if (data) {
    users = setupUsers(data?.users.edges);
  }

  const handleSearch = (e) => {
    const search = e.target.value.toLowerCase() || null;
    const newVar = { ...variable };
    newVar.search = search;
    setVariable(newVar);
  };

  return (
    <div className="dashboard-roster-view full-screen-view">
      {loading ? (
        <LoadingPane style={{ background: 'rgba(0,0,0,0.1)' }} />
      ) : null}
      <>
        <div className="toolbar-frame">
          <div className="centered-frame">
            <div className="left-section">
              <SearchField
                onChange={handleSearch}
                placeholder={variable.search ? variable.search : 'Search Users'}
              />
            </div>
          </div>
        </div>

        <div className="table-headers">
          <div className="centered-frame">
            <div className="table-header name">Name</div>
            <div className="table-header type">Organization</div>
            <div className="table-header email">Email</div>
          </div>
        </div>

        {users.length > 0 ? (
          <ul className="roster-list-view scroll" onScroll={handleScroll}>
            {users.map((user, index) => {
              const {
                id,
                attachment,
                firstName,
                lastName,
                organization,
                email,
              } = user;

              return (
                <li className="roster-cell" key={`${index}-${id}`}>
                  <div className="centered-frame">
                    <div className="name">
                      <div className="profile-picture">
                        {attachment ? (
                          <img src={attachment.url} alt="profile-icon" />
                        ) : (
                          <img
                            src={require('../../theme/images/profile-icon-2x.png')}
                            alt="profile-icon"
                          />
                        )}
                      </div>

                      <div className="cell-label bold">{`${firstName} ${lastName}`}</div>
                    </div>
                    <div className="cell-label type hover" title={organization}>
                      {organization}
                    </div>
                    <div className="cell-label email">{email}</div>
                    <div className="cell-label status"></div>
                  </div>
                </li>
              );
            })}
          </ul>
        ) : (
          <div className="layout-center empty-label">
            There are no users to display.
          </div>
        )}
      </>
      )
    </div>
  );
};

export default DashboardUsers;
