import { gql } from 'apollo-boost';

const RosterCourseQuery = gql`
  query RosterCourseQuery($id: ID, $course: ID, $org: ID!) {
    user(id: $id) {
      id
      firstName
      lastName
      specialties {
        id
        isPrimary
        name
      }
      courseTaken(id: $course) {
        id
        answers {
          id
          isCorrect
          question {
            id
            rank
            title
          }
          value
        }
        endedAt
        startedAt
        timeSpentSecs
        passed
        score
        status
        course {
          id
          title
        }
      }
    }
    org(id: $org) {
      id
      attachments(labels: ["pdf_logo"]) {
        id
        label
        url
      }
    }
  }
`;

export default RosterCourseQuery;
