import { gql } from 'apollo-boost';

const ChecklistSectionRemoveMutation = gql`
  mutation ChecklistSectionRemoveMutation($input: IDInput) {
    checklistSectionRemove(input: $input) {
      errors {
        message
      }
      result {
        id
        questions {
          id
          rank
          title
        }
        rank
        title
      }
    }
  }
`;

export default ChecklistSectionRemoveMutation;
