/*
import React from 'react';
import { graphql } from 'react-apollo';
import compose from 'lodash.flowright';
import moment from 'moment';
import TestSummaryQuery from '../../graphql/queries/Org/Tests/Summary';
import OrgContentRemoveMutation from '../../graphql/mutations/Org/Content/Remove';
import TestCategoryCreateMutation from '../../graphql/mutations/Tests/Category/Create';
import TestQuestionCreateMutation from '../../graphql/mutations/Tests/Question/Create';
import TestSummaryCreateMutation from '../../graphql/mutations/Tests/Summary/Create';
import TestSummaryRemoveMutation from '../../graphql/mutations/Tests/Summary/Remove';
import DashboardTests from '../../components/Dashboard/Tests';
import Papa from 'papaparse';
import LoadingPane from '../../components/Shared/LoadingPane';

class DashboardTestsContainer extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      isModal: false,
      onload: true,
      test: {},
      selectedFile: null,
      ShowMapping: false,
      csvConvertedArray: null,
      defaultMapHeaders: [],
      duplicatebulkQuestions: 0,
      alert: '',
      testtype: '',
      modelSubmitBtn: false,
    };
  }

  componentDidMount = () => {
    const {
      testSummaryQuery: { loading, refetch },
    } = this.props;
    const { onload } = this.state;

    if (onload && !loading) {
      refetch().then(() => this.setup());
    } else {
      this.setup();
    }
  };

  componentDidUpdate = () => {
    this.setup();
  };

  setup = () => {
    const {
      params,
      testSummaryQuery: { loading, org },
      user,
    } = this.props;

    if (this.state.onload && !loading) {
      const role = user.roles.find((r) => r.org.id === params.id);
      this.setState({
        ...org,
        admin: role ? role.name === 'corporate' : false,
        loading: false,
        onload: false,
        orgId: org.id,
      });
    }
  };

  refetch = (reroute) => {
    const { navigate, location, testSummaryQuery } = this.props;

    testSummaryQuery.refetch().then((response) => {
      this.setState(
        {
          loading: false,
          onload: false,
          tests: response.data.org.tests,
        },
        () => {
          if (reroute) navigate(location.pathname);
        },
      );
    });
  };

  contentRemove = (hide) => {
    const { params, orgContentRemoveMutation, testSummaryQuery } = this.props;
    const { test } = this.state;

    this.setState(
      {
        loading: true,
      },
      () => {
        orgContentRemoveMutation({
          variables: {
            input: {
              org: params.id,
              contentType: 'test',
              contentId: test.id,
            },
          },
        })
          .then((response) => {
            const {
              data: {
                orgContentRemove: { errors, success },
              },
            } = response;

            if (success) {
              testSummaryQuery.refetch().then((response) => {
                this.handleChange('loading', false);

                hide();

                setTimeout(() => {
                  this.setState({
                    isModal: false,
                    test: {},
                    tests: response.data.org.tests,
                  });
                }, 300);
              });
            } else {
              window.alert(errors[0].message);
            }
          })
          .catch((error) => {
            console.log(error);
            this.handleChange('loading', false);
          });
      },
    );
  };

  deleteTest = async (id, i) => {
    const { testSummaryRemoveMutation } = this.props;
    const { tests } = this.state;

    testSummaryRemoveMutation({
      variables: {
        input: {
          id,
        },
      },
    }).then((response) => {
      const {
        data: {
          testSummaryRemove: { errors },
        },
      } = response;

      if (errors) {
        window.alert(errors[0].message);
      } else {
        this.setState({
          tests: {
            ...tests,
            nodes: [
              ...tests.nodes.slice(0, i),
              ...tests.nodes.slice(i + 1, tests.length),
            ],
          },
        });
      }
    });
  };

  duplicateCategories = (arr, i, test) => {
    if (i === arr.length) {
      this.refetch();
    } else {
      const { testCategoryCreateMutation } = this.props;
      const { questions, rank, title } = arr[i];

      testCategoryCreateMutation({
        variables: {
          input: {
            summaryId: test.id,
            rank,
            title,
          },
        },
      }).then((response) => {
        const {
          data: {
            testCategoryCreate: { result },
          },
        } = response;

        if (questions.length > 0) {
          this.duplicateQuestions(questions, 0, result, arr, i + 1, test);
        } else {
          this.duplicateCategories(arr, i + 1, test);
        }
      });
    }
  };

  duplicateQuestions = (arr, i, category, cArr, cI, test) => {
    if (i === arr.length) {
      this.duplicateCategories(cArr, cI, test);
    } else {
      const { testQuestionCreateMutation } = this.props;
      const { answers, correctAnswerIndex, isRandomized, rank, title } = arr[i];

      testQuestionCreateMutation({
        variables: {
          input: {
            categoryId: category.id,
            correctAnswerIndex,
            answers,
            isRandomized,
            rank,
            title,
          },
        },
      }).then(() => {
        this.duplicateQuestions(arr, i + 1, category, cArr, cI, test);
      });
    }
  };

  duplicateTest = (test) => {
    const { testSummaryCreateMutation, params } = this.props;
    const { desc, durationMins, passingScore, title } = test;

    document.body.click();

    this.setState(
      {
        loading: true,
      },
      () => {
        testSummaryCreateMutation({
          variables: {
            input: {
              title: `${title} Copy (${moment().format(
                'MMM DD, YYYY hh:mma',
              )})`,
              desc,
              durationMins,
              passingScore,
              orgId: params.id,
            },
          },
        }).then((response) => {
          const {
            data: {
              testSummaryCreate: { errors, result },
            },
          } = response;

          if (errors) {
            window.alert(errors[0].message);
          } else {
            this.duplicateCategories(test.categories, 0, result);
          }
        });
      },
    );
  };

  goToTestRoute = (id) => {
    const { navigate, location, params } = this.props;

    navigate(`/tests/${id || 'create'}`, {
      state: {
        previous: location.pathname,
        org: params.id,
      },
    });
  };

  handleChange = (key, value) => {
    this.setState({
      [key]: value,
    });
  };

  toggleModal = (test, isModal) => {
    document.body.click();

    this.setState({ isModal, test });
  };

  handleFileChange = (event) => {
    this.setState(
      {
        loading: true,
        selectedFile: event.target.files[0],
      },
      () => {
        this.setState({
          loading: false,
          modelSubmitBtn: false,
        });
      },
    );
  };

  mappingHeader = () => {
    const maxSizeInBytes = 5 * 1024 * 1024; // 5 MB
    if (
      this.state.selectedFile &&
      this.state.selectedFile.size < maxSizeInBytes
    ) {
      this.setState({ ShowMapping: true });
      Papa.parse(this.state.selectedFile, {
        header: true, // Treat the first row as a header row
        dynamicTyping: true, // Automatically convert numbers and booleans
        skipEmptyLines: true, // Skip empty lines
        complete: (result) => {
          let CSVResult = result;
          const data = result.data;
          if (data.length > 0) {
            const defaultMapHeaders = [
              // {name:'Field', default:'', optional: 0}, // optional is 1 then validate otherwise not validate
              // {name:'Sub-Field', default:'', optional: 0},
              // {name:'Job type', default:'', optional: 0},
              // {name:'Specialization', default:'', optional: 0},
              { name: 'Category', default: '', optional: 1 },
              { name: 'SubCategory', default: '', optional: 1 },
              { name: 'Question', default: '', optional: 1 },
              { name: 'Response A', default: '', optional: 1 },
              { name: 'Response B', default: '', optional: 1 },
              { name: 'Response C', default: '', optional: 1 },
              { name: 'Response D', default: '', optional: 1 },
              { name: 'Response E', default: '', optional: 1 },
              { name: 'Correct Answer', default: '', optional: 1 },
            ];
            CSVResult.defaultMapHeaders = defaultMapHeaders;
            this.setState(
              {
                ShowMapping: true,
                csvConvertedArray: CSVResult,
                defaultMapHeaders: defaultMapHeaders,
              },
              () => {
                // state udpateding asyncronously
              },
            );
          }
        },
        error: (error) => {
          console.error('Error parsing CSV:', error);
        },
      });
    } else if (
      this.state.selectedFile &&
      this.state.selectedFile.size > maxSizeInBytes
    ) {
      this.setState({
        ShowMapping: false,
        alert:
          '<p>File size exceeds the maximum allowed size (5MB). Please choose a smaller file.</p>',
      });
    } else {
      this.setState({
        ShowMapping: false,
        alert: '<p>Please select a file to upload.<p/>',
      });
    }
  };

  handleSubmitButton = () => {
    const AlreadyMappeValue =
      this.state.csvConvertedArray.defaultMapHeaders.filter((item) => {
        return item.default !== '' && item.optional === 1;
      });

    const ExceptOneFactor = AlreadyMappeValue.filter((item) => {
      return item.name !== 'SubCategory';
    });

    const ExceptTwoFactors = AlreadyMappeValue.filter((item) => {
      return item.name !== 'SubCategory' && item.name !== 'Correct Answer';
    });

    const ifConditionOne =
      this.state.testtype !== '2' &&
      ExceptOneFactor.length === 8 &&
      this.state.testtype !== '' &&
      this.state.testtype !== undefined;

    const ifConditionTwo =
      this.state.testtype === '2' &&
      ExceptTwoFactors.length === 7 &&
      this.state.testtype !== '' &&
      this.state.testtype !== undefined;

    if (ifConditionOne) {
      this.setState({
        modelSubmitBtn: true,
      });
    } else if (ifConditionTwo) {
      this.setState({
        modelSubmitBtn: true,
      });
    } else {
      this.setState({
        modelSubmitBtn: false,
      });
    }
  };

  updateArray = (grandheader, event, e) => {
    if (e === 'testtype') {
      this.setState({ testtype: event.value }, () => {
        this.handleSubmitButton();
      });
    } else {
      var defaultArr = this.state.csvConvertedArray;
      const AlreadyMappeColumn = defaultArr.defaultMapHeaders.findIndex(
        (item) => {
          return item.default === event;
        },
      );
      if (AlreadyMappeColumn === -1) {
        defaultArr.defaultMapHeaders.map((item) => {
          if (item.name === grandheader) {
            item.default = event;
          }
        });

        const updatedMap = defaultArr.data.map((item) => {
          return { ...item, [grandheader]: item[event] };
        });
        defaultArr.data = updatedMap;
      } else {
        this.setState({
          alert:
            '<p>You Already Mapped This Column. Please Check And Assign Another Column</p>',
        });
        defaultArr.defaultMapHeaders.map((item) => {
          if (item.name === grandheader) {
            item.default = '';
          }
        });
      }
      this.setState({
        csvConvertedArray: defaultArr,
      });
      this.handleSubmitButton();
    }
  };

  onSubmitMap = () => {
    const AlreadyMappeValue =
      this.state.csvConvertedArray.defaultMapHeaders.filter((item) => {
        return item.default !== '' && item.optional === 1;
      });

    const ExceptOneFactor = AlreadyMappeValue.filter((item) => {
      return item.name !== 'SubCategory';
    });

    const ExceptTwoFactors = AlreadyMappeValue.filter((item) => {
      return item.name !== 'SubCategory' && item.name !== 'Correct Answer';
    });

    const ifConditionOne =
      this.state.testtype !== '2' &&
      ExceptOneFactor.length === 8 &&
      this.state.testtype !== '' &&
      this.state.testtype !== undefined;

    const ifConditionTwo =
      this.state.testtype === '2' &&
      ExceptTwoFactors.length === 7 &&
      this.state.testtype !== '' &&
      this.state.testtype !== undefined;

    if (ifConditionOne) {
      this.handleUpload();
    } else if (ifConditionTwo) {
      this.handleUpload();
    } else {
      this.setState({ alert: '<p>Please Map Mandatory Fields</p>' });
    }
  };

  closeMapping = () => {
    this.setState(
      {
        ShowMapping: false,
        csvConvertedArray: null,
        selectedFile: null,
        modelSubmitBtn: false,
      },
      () => {
        if (document.getElementById('fileInput')) {
          document.getElementById('fileInput').value = '';
        }
      },
    );
  };

  handleUpload = async () => {
    this.setState({ loading: true, ShowMapping: false }, () => {
      this.onSaveTest(this.state.csvConvertedArray.data);
    });
  };

  onSaveQuestions = async (
    question,
    categoryID,
    numberOfCategoriesEntries,
    CategoryIndex,
    questionsLength,
    questionIndex,
  ) => {
    const { testQuestionCreateMutation, testSummaryQuery } = this.props;
    const answers = [
      question['Response A'],
      question['Response B'],
      question['Response C'],
      question['Response D'],
      question['Response E'],
    ];
    const Correct_Answer = question['Correct Answer']; // Response B

    testQuestionCreateMutation({
      variables: {
        input: {
          categoryId: Number(categoryID),
          correctAnswerIndex: answers.indexOf(Correct_Answer),
          answers: answers,
          isRandomized: true,
          rank: Number(0),
          title: question['Item Stem'],
        },
      },
    })
      .then((response) => {
        if (!response.data.testQuestionCreate.result) {
          var questionsCount = this.state.duplicatebulkQuestions + 1;
          // var duplicateQuestions =this.state.duplicatebulkQuestions.concat(questionsString);
          this.setState({ duplicatebulkQuestions: questionsCount }, () => {});
        }
        if (
          numberOfCategoriesEntries - 1 === CategoryIndex &&
          questionsLength - 1 === questionIndex
        ) {
          var duplicatebulkQuestions = this.state.duplicatebulkQuestions;
          testSummaryQuery.refetch().then((response) => {
            this.setState(
              {
                loading: false,
                modelSubmitBtn: false,
                tests: response.data.org.tests,
                selectedFile: null,
                alert:
                  duplicatebulkQuestions === 0
                    ? ''
                    : `<p>Found <b style='color:red;'> ${
                        duplicatebulkQuestions === 0
                          ? ''
                          : duplicatebulkQuestions
                      } </b> duplicate questions on Mapped Field</p>`,
              },
              () => {
                // state udpateding asyncronously
                if (document.getElementById('fileInput')) {
                  document.getElementById('fileInput').value = '';
                }
              },
            );
          });
        }
      })
      .catch((error) => {});
  };

  onSaveCategory = async (
    categoryTitle,
    values,
    testID,
    numberOfCategoriesEntries,
    CategoryIndex,
  ) => {
    const { testCategoryCreateMutation } = this.props;
    testCategoryCreateMutation({
      variables: {
        input: {
          rank: 1,
          summaryId: Number(testID),
          title: categoryTitle,
          subtopic: values[0]['Subtopic'],
        },
      },
    })
      .then((response) => {
        if (response.data.testCategoryCreate.result) {
          const categoryId = response.data.testCategoryCreate.result.id;
          // save questions
          values.map((question, questionIndex) => {
            this.onSaveQuestions(
              question,
              categoryId,
              numberOfCategoriesEntries,
              CategoryIndex,
              values.length,
              questionIndex,
            );
          });
        }
      })
      .catch((error) => {});
  };

  onCloseAlert = () => {
    this.setState({ alert: '', duplicatebulkQuestions: 0 });
  };

  onSaveTest = async (csvFileData) => {
    const { testSummaryCreateMutation, testSummaryQuery, params } = this.props;
    this.setState(
      {
        loading: true,
      },
      () => {
        testSummaryCreateMutation({
          variables: {
            input: {
              title: String(this.state.selectedFile.name.replace('.csv', '')),
              desc: '',
              durationMins: Number(0),
              passingScore: Number(0),
              color: '',
              kind: '',
              orgId: String(params.id),
              testtype: Number(this.state.testtype),
            },
          },
        })
          .then((response) => {
            if (response.data.testSummaryCreate.result) {
              const testID = response.data.testSummaryCreate.result.id;
              const groupedData = csvFileData.reduce((acc, item) => {
                if (!acc[item.Topic]) {
                  acc[item.Topic] = [];
                }
                acc[item.Topic].push(item);
                return acc;
              }, {});
              const entries = Object.entries(groupedData);
              const numberOfCategoriesEntries = entries.length;
              entries.forEach(([key, value], CategoryIndex) => {
                this.onSaveCategory(
                  key,
                  value,
                  testID,
                  numberOfCategoriesEntries,
                  CategoryIndex,
                );
              });
            } else {
              if (document.getElementById('fileInput')) {
                document.getElementById('fileInput').value = '';
              }
              this.setState({
                loading: false,
                alert: 'Duplicate Test Name found',
              });
            }
          })
          .catch((error) => {
            console.log('error', error);
          });
      },
    );
  };

  render() {
    return this.state.onload ? (
      <LoadingPane />
    ) : (
      <DashboardTests
        {...this.props}
        contentRemove={this.contentRemove}
        mappingHeader={this.mappingHeader}
        updateMapArray={this.updateArray}
        onSubmitMap={this.onSubmitMap}
        onTestTypeSelect={this.onTestTypeSelect}
        closeMapping={this.closeMapping}
        ShowMapping={this.state.ShowMapping}
        modelSubmitBtn={this.state.modelSubmitBtn}
        Alert={this.state.alert}
        testtype={this.state.testtype}
        onCloseAlert={this.onCloseAlert}
        csvConvertedArray={this.state.csvConvertedArray}
        defaultMapHeaders={this.state.defaultMapHeaders}
        goToTestRoute={this.goToTestRoute}
        duplicateTest={this.duplicateTest}
        handleFileChange={this.handleFileChange}
        deleteTest={this.deleteTest}
        state={this.state}
        toggleModal={this.toggleModal}
      />
    );
  }
}

export default compose(
  graphql(TestSummaryQuery, {
    name: 'testSummaryQuery',
    options: (props) => ({
      variables: {
        id: props.params.id,
      },
    }),
  }),
  graphql(OrgContentRemoveMutation, { name: 'orgContentRemoveMutation' }),
  graphql(TestCategoryCreateMutation, { name: 'testCategoryCreateMutation' }),
  graphql(TestQuestionCreateMutation, { name: 'testQuestionCreateMutation' }),
  graphql(TestSummaryCreateMutation, { name: 'testSummaryCreateMutation' }),
  graphql(TestSummaryRemoveMutation, { name: 'testSummaryRemoveMutation' }),
)(DashboardTestsContainer);

*/
import React from 'react';
import { graphql } from 'react-apollo';
import compose from 'lodash.flowright';
import moment from 'moment';
import TestSummaryQuery from '../../graphql/queries/Org/Tests/Summary';
import OrgContentRemoveMutation from '../../graphql/mutations/Org/Content/Remove';
import TestCategoryCreateMutation from '../../graphql/mutations/Tests/Category/Create';
import TestQuestionCreateMutation from '../../graphql/mutations/Tests/Question/Create';
import TestSummaryCreateMutation from '../../graphql/mutations/Tests/Summary/Create';
import TestSummaryRemoveMutation from '../../graphql/mutations/Tests/Summary/Remove';
import DashboardTests from '../../components/Dashboard/Tests';
import Papa from 'papaparse';
import LoadingPane from '../../components/Shared/LoadingPane';

class DashboardTestsContainer extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      isModal: false,
      onload: true,
      test: {},
      selectedFile: null,
      ShowMapping: false,
      csvConvertedArray: null,
      defaultMapHeaders: [],
      duplicatebulkQuestions: 0,
      alert: '',
      testtype: '',
      modelSubmitBtn: false,
      originalarray: null,
    };
  }

  componentDidMount = () => {
    const {
      testSummaryQuery: { loading, refetch },
    } = this.props;

    const { onload } = this.state;

    if (onload && !loading) {
      refetch().then(() => this.setup());
    } else {
      this.setup();
    }
  };

  componentDidUpdate = () => {
    this.setup();
  };

  setup = () => {
    const {
      params,
      testSummaryQuery: { loading, org },
      user,
    } = this.props;

    if (this.state.onload && !loading) {
      const role = user.roles.find((r) => r.org.id === params.id);
      this.setState({
        ...org,
        admin: role ? role.name === 'corporate' : false,
        loading: false,
        onload: false,
        orgId: org.id,
      });
    }
  };

  refetch = (reroute) => {
    const { navigate, location, testSummaryQuery } = this.props;

    testSummaryQuery.refetch().then((response) => {
      this.setState(
        {
          loading: false,
          onload: false,
          tests: response.data.org.tests,
        },
        () => {
          if (reroute) navigate(location.pathname);
        },
      );
    });
  };

  contentRemove = (hide) => {
    const { params, orgContentRemoveMutation, testSummaryQuery } = this.props;
    const { test } = this.state;

    this.setState(
      {
        loading: true,
      },
      () => {
        orgContentRemoveMutation({
          variables: {
            input: {
              org: params.id,
              contentType: 'test',
              contentId: test.id,
            },
          },
        })
          .then((response) => {
            const {
              data: {
                orgContentRemove: { errors, success },
              },
            } = response;

            if (success) {
              testSummaryQuery.refetch().then((response) => {
                this.handleChange('loading', false);

                hide();

                setTimeout(() => {
                  this.setState({
                    isModal: false,
                    test: {},
                    tests: response.data.org.tests,
                  });
                }, 300);
              });
            } else {
              window.alert(errors[0].message);
            }
          })
          .catch((error) => {
            console.log(error);
            this.handleChange('loading', false);
          });
      },
    );
  };

  deleteTest = async (id, i) => {
    const { testSummaryRemoveMutation } = this.props;
    const { tests } = this.state;

    testSummaryRemoveMutation({
      variables: {
        input: {
          id,
        },
      },
    }).then((response) => {
      const {
        data: {
          testSummaryRemove: { errors },
        },
      } = response;

      if (errors) {
        window.alert(errors[0].message);
      } else {
        this.setState({
          tests: {
            ...tests,
            nodes: [
              ...tests.nodes.slice(0, i),
              ...tests.nodes.slice(i + 1, tests.length),
            ],
          },
        });
      }
    });
  };

  duplicateCategories = (arr, i, test) => {
    if (i === arr.length) {
      this.refetch();
    } else {
      const { testCategoryCreateMutation } = this.props;
      const { questions, rank, title } = arr[i];

      testCategoryCreateMutation({
        variables: {
          input: {
            summaryId: test.id,
            rank,
            title,
          },
        },
      }).then((response) => {
        const {
          data: {
            testCategoryCreate: { result },
          },
        } = response;

        if (questions.length > 0) {
          this.duplicateQuestions(questions, 0, result, arr, i + 1, test);
        } else {
          this.duplicateCategories(arr, i + 1, test);
        }
      });
    }
  };

  duplicateQuestions = (arr, i, category, cArr, cI, test) => {
    if (i === arr.length) {
      this.duplicateCategories(cArr, cI, test);
    } else {
      const { testQuestionCreateMutation } = this.props;
      const { answers, correctAnswerIndex, isRandomized, rank, title } = arr[i];

      testQuestionCreateMutation({
        variables: {
          input: {
            categoryId: category.id,
            correctAnswerIndex,
            answers,
            isRandomized,
            rank,
            title,
          },
        },
      }).then(() => {
        this.duplicateQuestions(arr, i + 1, category, cArr, cI, test);
      });
    }
  };

  duplicateTest = (test) => {
    const { testSummaryCreateMutation, params } = this.props;
    const { desc, durationMins, passingScore, title } = test;

    document.body.click();

    this.setState(
      {
        loading: true,
      },
      () => {
        testSummaryCreateMutation({
          variables: {
            input: {
              title: `${title} Copy (${moment().format(
                'MMM DD, YYYY hh:mma',
              )})`,
              desc,
              durationMins,
              passingScore,
              orgId: params.id,
            },
          },
        }).then((response) => {
          const {
            data: {
              testSummaryCreate: { errors, result },
            },
          } = response;

          if (errors) {
            window.alert(errors[0].message);
          } else {
            this.duplicateCategories(test.categories, 0, result);
          }
        });
      },
    );
  };

  goToTestRoute = (id) => {
    const { navigate, location, params } = this.props;

    navigate(`/tests/${id || 'create'}`, {
      state: {
        previous: location.pathname,
        org: params.id,
      },
    });
  };

  handleChange = (key, value) => {
    this.setState({
      [key]: value,
    });
  };

  toggleModal = (test, isModal) => {
    document.body.click();

    this.setState({ isModal, test });
  };

  handleFileChange = (event) => {
    const file = event.target.files[0];
    if (file && ['text/csv', 'application/vnd.ms-excel'].includes(file.type) ){  // application/vnd.ms-excel is found in firefox browser for csv files on windows
      this.setState(
        {
          loading: true,
          selectedFile: event.target.files[0],
        },
        () => {
          this.setState({
            loading: false,
            modelSubmitBtn: false,
          });
        },
      );
    } else {
      this.setState(
        {
          loading: false,
          selectedFile: null,
          alert: '<p> Please choose a valid CSV file.<p/>',
        },
        () => {
          this.setState({
            modelSubmitBtn: false,
          });
          this.removeFile();
        },
      );
     
    }
  };

  mappingHeader = () => {
    const maxSizeInBytes = 5 * 1024 * 1024; // 5 MB
    if (
      this.state.selectedFile &&
      this.state.selectedFile.size < maxSizeInBytes
    ) {
      this.setState({ ShowMapping: true });
      Papa.parse(this.state.selectedFile, {
        header: true, // Treat the first row as a header row
        dynamicTyping: true, // Automatically convert numbers and booleans
        skipEmptyLines: 'greedy', // Skip empty lines and lines with only whitespaces content
        complete: (result) => {
          let CSVResult = result;
          const data = result.data;
          if (data.length > 0) {
            const defaultMapHeaders = [
              // {name:'Field', default:'', optional: 0}, // optional is 1 then validate otherwise not validate
              // {name:'Sub-Field', default:'', optional: 0},
              // {name:'Job type', default:'', optional: 0},
              // {name:'Specialization', default:'', optional: 0},
              { name: 'Category', default: '', optional: 1 },
              { name: 'SubCategory', default: '', optional: 1 },
              { name: 'Question', default: '', optional: 1 },
              { name: 'Response A', default: '', optional: 1 },
              { name: 'Response B', default: '', optional: 1 },
              { name: 'Response C', default: '', optional: 1 },
              { name: 'Response D', default: '', optional: 1 },
              { name: 'Response E', default: '', optional: 1 },
              { name: 'Correct Answer', default: '', optional: 1 },
            ];
            CSVResult.defaultMapHeaders = defaultMapHeaders;
            this.setState({
              originalarray: CSVResult,
            });
            this.setState(
              {
                ShowMapping: true,
                defaultMapHeaders: defaultMapHeaders,
                csvConvertedArray: {
                  ...CSVResult,
                  data: CSVResult.data.map((item) => ({
                    ...item,
                    'Correct Answer': null,
                    SubCategory: null,
                  })),
                },
              },
              () => {
                // state updating asyncronously
              },
            );
          } else {
            this.setState({
              ShowMapping: false,
              selectedFile: null,
              alert:
                '<p>File contains zero records. Please choose a valid file.</p>',
            }, () => {
              this.removeFile();
            });
          }
        },
        error: (error) => {
          console.error('Error parsing CSV:', error);
        },
      });
    } else if (
      this.state.selectedFile &&
      this.state.selectedFile.size > maxSizeInBytes
    ) {
      this.setState({
        ShowMapping: false,
        alert:
          '<p>File size exceeds the maximum allowed size (5MB). Please choose a smaller file.</p>',
      });
    } else {
      this.setState({
        ShowMapping: false,
        alert: '<p>Please select a file to upload.<p/>',
      });
    }
  };

  handleSubmitButton = () => {
    const AlreadyMappeValue =
      this.state.csvConvertedArray.defaultMapHeaders.filter((item) => {
        return item.default !== '' && item.optional === 1;
      });

    const ExceptOneFactor = AlreadyMappeValue.filter((item) => {
      return item.name !== 'SubCategory';
    });

    const ExceptTwoFactors = AlreadyMappeValue.filter((item) => {
      return item.name !== 'SubCategory' && item.name !== 'Correct Answer';
    });

    const isTesttypeValid =  this.state.testtype !== '' && this.state.testtype !== undefined;

    const ifConditionOne =
      isTesttypeValid && 
      this.state.testtype !== '2' && // not self assesment test type
      ExceptOneFactor.length === 8     

    const ifConditionTwo =
      isTesttypeValid &&
      this.state.testtype === '2' &&  // self assesment test type
      ExceptTwoFactors.length === 7

    if (ifConditionOne) {
      this.setState({
        modelSubmitBtn: true,
      });
    } else if (ifConditionTwo) {
      this.setState({
        modelSubmitBtn: true,
      });
    } else {
      this.setState({
        modelSubmitBtn: false,
      });
    }
  };

  //**************************************************************** */ New code
  updateArray = (grandheader, event, e) => {
    if (e === 'testtype') {
      this.setState({ testtype: event.value }, () => {
        this.handleSubmitButton();
      });
    } else {
      var originalArray = this.state.originalarray;
      var defaultArr = this.state.csvConvertedArray;
      const AlreadyMappeColumn = defaultArr.defaultMapHeaders.findIndex(
        (item) => {
          return item.default === event;
        },
      );
      if (AlreadyMappeColumn === -1) {
        // todo: what is the use of below map fn
        defaultArr.defaultMapHeaders.map((item) => {
          if (item.name === grandheader) {
            item.default = event;
          }
        });

        const updatedArray1 = defaultArr.data.map((obj, index) => {
          return { ...obj, [grandheader]: originalArray.data[index][event] };
        });

        defaultArr.data = updatedArray1;
      } else {
        this.setState({
          alert:
            '<p>You Already Mapped This Column. Please Check And Assign Another Column</p>',
        });
        // todo: what is the use of below map fn
        defaultArr.defaultMapHeaders.map((item) => {
          if (item.name === grandheader) {
            item.default = '';
          }
        });
        const updatedArray1 = defaultArr.data.map((obj, index) => {
          return { ...obj, [grandheader]: null };
        });

        defaultArr.data = updatedArray1;
      }
      this.setState({
        csvConvertedArray: defaultArr,
      });
      this.handleSubmitButton();
    }
  };

  onSubmitMap = () => {
    const AlreadyMappeValue =
      this.state.csvConvertedArray.defaultMapHeaders.filter((item) => {
        return item.default !== '' && item.optional === 1;
      });
    
    const ExceptOneFactor = AlreadyMappeValue.filter((item) => {
      return item.name !== 'SubCategory';
    });

    const ExceptTwoFactors = AlreadyMappeValue.filter((item) => {
      return item.name !== 'SubCategory' && item.name !== 'Correct Answer';
    });

    const isTesttypeValid =  this.state.testtype !== '' && this.state.testtype !== undefined;

    const ifConditionOne =
      isTesttypeValid && 
      this.state.testtype !== '2' && // not self assesment test type
      ExceptOneFactor.length === 8     

    const ifConditionTwo =
      isTesttypeValid &&
      this.state.testtype === '2' &&  // self assesment test type
      ExceptTwoFactors.length === 7

    if (ifConditionOne) {
      this.handleUpload();
    } else if (ifConditionTwo) {
      this.handleUpload();
    } else {
      this.setState({ alert: '<p>Please Map Mandatory Fields</p>' });
    }
  };

  closeMapping = () => {
    this.setState(
      {
        ShowMapping: false,
        csvConvertedArray: null,
        selectedFile: null,
        modelSubmitBtn: false,
      },
      () => {
        this.removeFile();
      },
    );
    
  };

  validateDuplicateQuestions = (csvFileData) => {
    const defaultHeaderMapping =  this.state.defaultMapHeaders
    
    const QuestionMap = defaultHeaderMapping.find((col) => { return col["name"] === "Question" })
    
    const allQuestions = csvFileData.map((data) => {
      return data[QuestionMap.default]
    })
    const uniqueQuestions = new Set(allQuestions);

    const duplicateQuestionsCount = allQuestions.length - uniqueQuestions.size

    return duplicateQuestionsCount
  }

  removeFile = () => {
    if (document.getElementById('fileInput')) {
      document.getElementById('fileInput').value = '';
    }
  }

  handleUpload = async () => {
    const originalArray = this.state.csvConvertedArray;
    originalArray.data.forEach((item, index) => {
      const updatedItem = {};
      Object.keys(item).forEach((key) => {
        if (key === 'Correct Answer' && item[key] && item[key].startsWith('Response')) {
          updatedItem[key] = item[item[key].replace('_', " ")];
        } else {
          updatedItem[key] = item[key];
        }
      });

      originalArray.data[index] = updatedItem;
      this.setState(
        {
          csvConvertedArray: {
            ...originalArray,
            data: [...originalArray.data],
          },
        },
        () => {},
      );
    });

    const duplicateQuestionsCount = this.validateDuplicateQuestions(this.state.csvConvertedArray.data)

    if(duplicateQuestionsCount > 0){
      this.setState({
        ShowMapping: false,
        selectedFile: null,
        alert: `Found ${duplicateQuestionsCount} duplicate questions on Mapped fields. Please choose a valid file.`,
      }, () => {
        this.removeFile();
      });
    } else {
      this.setState({ loading: true, ShowMapping: false }, () => {
        this.onSaveTest(this.state.csvConvertedArray.data);
      });
    }
  };

  onSaveQuestions = async (
    question,
    categoryID,
    numberOfCategoriesEntries,
    CategoryIndex,
    questionsLength,
    questionIndex,
  ) => {
    const { testQuestionCreateMutation, testSummaryQuery } = this.props;
    const answers = [
      question['Response A']?.toString(),
      question['Response B']?.toString(),
      question['Response C']?.toString(),
      question['Response D']?.toString(),
      question['Response E']?.toString(),
    ];
    const Correct_Answer = question['Correct Answer']?.toString(); // Response B

    testQuestionCreateMutation({
      variables: {
        input: {
          categoryId: Number(categoryID),
          correctAnswerIndex: answers.indexOf(Correct_Answer),
          answers: answers,
          isRandomized: true,
          rank: Number(0),
          title: question['Question'],
        },
      },
    })
      .then((response) => {
        if (!response.data.testQuestionCreate.result) {
          var questionsCount = this.state.duplicatebulkQuestions + 1;
          // var duplicateQuestions =this.state.duplicatebulkQuestions.concat(questionsString);
          this.setState({ duplicatebulkQuestions: questionsCount }, () => {});
        }
        if (
          numberOfCategoriesEntries - 1 === CategoryIndex &&
          questionsLength - 1 === questionIndex
        ) {
          var duplicatebulkQuestions = this.state.duplicatebulkQuestions;
          testSummaryQuery.refetch().then((response) => {
            this.setState(
              {
                loading: false,
                modelSubmitBtn: false,
                tests: response.data.org.tests,
                selectedFile: null,
                alert:
                  duplicatebulkQuestions === 0
                    ? ''
                    : `<p>Found <b style='color:red;'> ${
                        duplicatebulkQuestions === 0
                          ? ''
                          : duplicatebulkQuestions
                      } </b> duplicate questions on Mapped Field</p>`,
              },
              () => {
                // state udpateding asyncronously
                this.removeFile()
              },
            );
          });
        }
      })
      .catch((error) => {});
  };

  onSaveCategory = async (
    categoryTitle,
    values,
    testID,
    numberOfCategoriesEntries,
    CategoryIndex,
  ) => {
    const { testCategoryCreateMutation } = this.props;
    testCategoryCreateMutation({
      variables: {
        input: {
          rank: 1,
          summaryId: Number(testID),
          title: categoryTitle,
          subtopic: values[0]['Subtopic'],
        },
      },
    })
      .then((response) => {
        if (response.data.testCategoryCreate.result) {
          const categoryId = response.data.testCategoryCreate.result.id;
          // save questions
          values.map((question, questionIndex) => {
            this.onSaveQuestions(
              question,
              categoryId,
              numberOfCategoriesEntries,
              CategoryIndex,
              values.length,
              questionIndex,
            );
          });
        }
      })
      .catch((error) => {});
  };

  onCloseAlert = () => {
    this.setState({ alert: '', duplicatebulkQuestions: 0 });
  };

  onSaveTest = async (csvFileData) => {
    const { testSummaryCreateMutation, testSummaryQuery, params } = this.props;
    this.setState(
      {
        loading: true,
      },
      () => {
        testSummaryCreateMutation({
          variables: {
            input: {
              title: String(this.state.selectedFile.name.replace('.csv', '')),
              desc: '',
              durationMins: Number(0),
              passingScore: Number(0),
              color: '',
              kind: '',
              orgId: String(params.id),
              testtype: Number(this.state.testtype),
            },
          },
        })
          .then((response) => {
            if (response.data.testSummaryCreate.result) {
              const testID = response.data.testSummaryCreate.result.id;
              const groupedData = csvFileData.reduce((acc, item) => {
                if (!acc[item.Topic]) {
                  acc[item.Topic] = [];
                }
                acc[item.Topic].push(item);
                return acc;
              }, {});
              const entries = Object.entries(groupedData);
              const numberOfCategoriesEntries = entries.length;
              entries.forEach(([key, value], CategoryIndex) => {
                this.onSaveCategory(
                  key,
                  value,
                  testID,
                  numberOfCategoriesEntries,
                  CategoryIndex,
                );
              });
            } else {
              this.removeFile()
              this.setState({
                loading: false,
                alert: 'Duplicate Test Name found',
              });
            }
          })
          .catch((error) => {
            console.log('error', error);
          });
      },
    );
  };

  render() {
    return this.state.onload ? (
      <LoadingPane />
    ) : (
      <DashboardTests
        {...this.props}
        contentRemove={this.contentRemove}
        mappingHeader={this.mappingHeader}
        updateMapArray={this.updateArray}
        onSubmitMap={this.onSubmitMap}
        onTestTypeSelect={this.onTestTypeSelect}
        closeMapping={this.closeMapping}
        ShowMapping={this.state.ShowMapping}
        modelSubmitBtn={this.state.modelSubmitBtn}
        Alert={this.state.alert}
        testtype={this.state.testtype}
        onCloseAlert={this.onCloseAlert}
        csvConvertedArray={this.state.csvConvertedArray}
        defaultMapHeaders={this.state.defaultMapHeaders}
        goToTestRoute={this.goToTestRoute}
        duplicateTest={this.duplicateTest}
        handleFileChange={this.handleFileChange}
        deleteTest={this.deleteTest}
        state={this.state}
        toggleModal={this.toggleModal}
        selectedFile={this.state.selectedFile}
      />
    );
  }
}

export default compose(
  graphql(TestSummaryQuery, {
    name: 'testSummaryQuery',
    options: (props) => ({
      variables: {
        id: props.params.id,
      },
    }),
  }),
  graphql(OrgContentRemoveMutation, { name: 'orgContentRemoveMutation' }),
  graphql(TestCategoryCreateMutation, { name: 'testCategoryCreateMutation' }),
  graphql(TestQuestionCreateMutation, { name: 'testQuestionCreateMutation' }),
  graphql(TestSummaryCreateMutation, { name: 'testSummaryCreateMutation' }),
  graphql(TestSummaryRemoveMutation, { name: 'testSummaryRemoveMutation' }),
)(DashboardTestsContainer);
