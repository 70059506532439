import React from 'react';
import { graphql } from 'react-apollo';
import { flowRight as compose, set } from 'lodash';

import UserQuery from '../../../graphql/queries/Document/WorkExperience';

import WorkExperience from '../../../components/Documents/WorkExperience';

import LoadingPane from '../../../components/Shared/LoadingPane';

class WorkExperienceDocumentContainer extends React.Component {
  constructor(props) {
    super(props);

    const {
      params: { document },
      mobile,
    } = props;

    this.state = {
      current: false,
      edit: document === 'create',
      isModal: false,
      loading: false,
      mobile,
      onload: true,
    };
  }

  componentDidMount() {
    this.setup();
  }

  componentDidUpdate(prevProps) {
    const { mobile } = this.props;

    this.setup(prevProps.mobile !== mobile);
  }

  setup = (mobileDidChange) => {
    const {
      params,
      mobile,
      userQuery: { loading, specialties, user },
    } = this.props;
    const { onload } = this.state;

    if (onload && !loading) {
      const workExperience = user.workExperiences.find(
        (o) => o.id === params.item,
      );
      const specialty = workExperience
        ? specialties.find((s) => s.name === workExperience.kind)
        : null;

      this.setState({
        ...workExperience,
        current: !(workExperience && workExperience.endedOn),
        kind: specialty ? specialty.id : null,
        mobile: mobileDidChange ? mobile : this.state.mobile,
        onload: false,
        specialties,
      });
    } else if (mobileDidChange) {
      this.handleChange('mobile', mobile);
    }
  };

  back = () => {
    const { params, navigate, location } = this.props;

    if (!location.state || location.state?.previous === '/login') {
      navigate(`/organizations/${params.id}/roster/${params.roster}`);
      return;
    }
    navigate(-1);
  };

  handleChange = (key, value) => {
    this.setState(set(this.state, key, value));
  };

  render() {
    return this.state.onload ? (
      <LoadingPane />
    ) : (
      <WorkExperience
        {...this.props}
        back={this.back}
        handleChange={this.handleChange}
        state={this.state}
      />
    );
  }
}

export default compose(
  graphql(UserQuery, {
    name: 'userQuery',
    options: ({ params: { roster, item } }) => ({
      variables: {
        id: roster,
        document: item,
      },
    }),
  }),
)(WorkExperienceDocumentContainer);
