import { gql } from 'apollo-boost';

const OrgActivateMutation = gql`
  mutation OrgActivateMutation($input: IDInput) {
    orgActivate(input: $input) {
      errors {
        code
        message
      }
      success
    }
  }
`;

export default OrgActivateMutation;
