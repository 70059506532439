import React from 'react';
import { graphql } from 'react-apollo';
import compose from 'lodash.flowright';

import CourseQuery from '../../graphql/queries/Course';

import CourseQuestionCreateMutation from '../../graphql/mutations/Courses/Question/Create';
import CourseQuestionUpdateMutation from '../../graphql/mutations/Courses/Question/Update';

import Question from '../../components/Question/Chapter';

import LoadingPane from '../../components/Shared/LoadingPane';
import { withRouter } from '../withRouter';

class QuestionChapterContainer extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      isRandomized: true,
      loading: false,
      onload: true,
      options: [],
      title: null,
    };
  }

  componentDidMount() {
    const {
      courseQuery: { loading, refetch },
    } = this.props;

    if (this.state.onload && !loading) {
      refetch().then(() => this.setup());
    } else {
      this.setup();
    }

    window.addEventListener('keydown', (e) => {
      // shift + enter
      if (e.shiftKey && e.keyCode === 13) {
        this.setState(
          {
            options: this.state.options
              .filter((o) => o.title)
              .map((o) => ({ ...o, edit: false })),
          },
          () => this.addOption(),
        );
      }
    });
  }

  componentDidUpdate() {
    this.setup();
  }

  componentWillUnmount() {
    window.removeEventListener('keydown', (e) => {
      // shift + enter
      if (e.shiftKey && e.keyCode === 13) {
        this.setState(
          {
            options: this.state.options
              .filter((o) => o.title)
              .map((o) => ({ ...o, edit: false })),
          },
          () => this.addOption(),
        );
      }
    });
  }

  setup = () => {
    const {
      location,
      navigate,
      params: { chapter, question },

      courseQuery: { loading, course },
      context: { user },
    } = this.props;
    const { onload } = this.state;

    if (onload && !loading) {
      const c = course.chapters.find((c) => c.id === chapter);
      const questions = c.questions.map((q) => ({ ...q, chapter: c }));
      const q = questions.find((q) => q.id === question);
      const org = location.state ? location.state.org : null;
      const role = user.roles.find((r) => r.org.id === org);
      const admin = role ? role.name === 'corporate' : false;

      if (course && course.kind === 'global' && !admin) {
        navigate(`/error${org ? `?org=${org}` : ''}`);
      } else {
        this.setState({
          ...q,
          answer: q && q.answers.length > 0 ? q.answers[0] : null,
          chapter: c,
          course,
          options: q ? q.answers.map((a) => ({ title: a, edit: false })) : [],
          onload: false,
        });
      }
    }
  };

  addOption = () => {
    const arr = this.state.options;

    this.handleChange('options', [
      ...arr,
      { title: '', edit: true, create: true },
    ]);
  };

  deleteOption = (index) => {
    const arr = this.state.options.filter((o, i) => index !== i);

    document.body.click();

    this.handleChange('options', arr);
  };

  editOption = (index) => {
    const options = this.state.options.map((o, i) => {
      const isOption = index === i;

      return {
        ...o,
        edit: !!isOption,
      };
    });

    this.handleChange('edit', options[index].title);
    this.handleChange('options', options);
  };

  goToChapterRoute = () => {
    const {
      navigate,
      location,
      params: { id, chapter },
    } = this.props;

    navigate(`/courses/${id}/chapters/${chapter}`, {
      state: {
        previous: `/courses/${id}`,
        org: location.state.org,
      },
    });
  };

  handleChange = (key, value) => {
    this.setState({
      [key]: value,
    });
  };

  handleOption = (key, value, i) => {
    const arr = this.state.options;
    const option = arr[i];

    option[key] = value;

    this.handleChange('options', [
      ...arr.slice(0, i),
      option,
      ...arr.slice(i + 1, arr.length),
    ]);
  };

  handleOptionAction = (save, i) => {
    if (save) {
      const options = this.state.options.map((o, index) => {
        return {
          ...o,
          edit: false,
          create: false,
        };
      });

      this.handleChange('options', options);
    } else {
      const option = { ...this.state.options[i] };
      if (option.create) {
        this.handleChange(
          'options',
          this.state.options.filter((value, index) => index !== i),
        );
      } else {
        option.edit = false;
        option.create = false;
        option.title = this.state.edit;
        this.handleChange('options', [
          ...this.state.options.slice(0, i),
          option,
          ...this.state.options.slice(i + 1, this.state.options.length),
        ]);
      }
    }
  };

  save = () => {
    const { courseQuestionCreateMutation, courseQuestionUpdateMutation } =
      this.props;
    const { answer, chapter, course, id, isRandomized, options, title } =
      this.state;

    const mutation = id
      ? courseQuestionUpdateMutation
      : courseQuestionCreateMutation;
    const data = {
      answers: [
        answer,
        ...options.filter((a) => a.title !== answer).map((a) => a.title),
      ],
      isRandomized,
      rank: course.chapters.map((c) => c.questions).flat().length + 1,
      title,
    };

    if (id) {
      // existing
      data.id = id;
    } else {
      // new
      data.chapterId = chapter.id;
    }

    if (!title) {
      window.alert('Question title is required.');
    } else if (options.length < 2) {
      window.alert('More than one answer option is required.');
    } else if (!answer) {
      window.alert('A correct answer is required.');
    } else {
      this.setState(
        {
          loading: true,
        },
        () => {
          mutation({
            variables: {
              input: data,
            },
          }).then((response) => {
            const key = Object.keys(response.data)[0];
            const errors = response.data[key].errors;

            if (errors) {
              this.handleChange('loading', false);

              window.alert(errors[0].message);
            } else {
              this.goToChapterRoute();
            }
          });
        },
      );
    }
  };

  render() {
    return this.state.onload ? (
      <LoadingPane />
    ) : (
      <Question
        {...this.props}
        addOption={this.addOption}
        deleteOption={this.deleteOption}
        editOption={this.editOption}
        goToChapterRoute={this.goToChapterRoute}
        handleChange={this.handleChange}
        handleOption={this.handleOption}
        handleOptionAction={this.handleOptionAction}
        save={this.save}
        state={this.state}
      />
    );
  }
}

export default compose(
  withRouter,
  graphql(CourseQuery, {
    name: 'courseQuery',
    options: (props) => ({
      variables: {
        id: props.params.id,
      },
    }),
  }),
  graphql(CourseQuestionCreateMutation, {
    name: 'courseQuestionCreateMutation',
  }),
  graphql(CourseQuestionUpdateMutation, {
    name: 'courseQuestionUpdateMutation',
  }),
)(QuestionChapterContainer);
