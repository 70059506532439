import React from 'react';
import { graphql } from 'react-apollo';
import compose from 'lodash.flowright';

import _ from 'lodash';

import TestQuery from '../../graphql/queries/Category';

import TestQuestionCreateMutation from '../../graphql/mutations/Tests/Question/Create';
import TestQuestionRemoveMutation from '../../graphql/mutations/Tests/Question/Remove';
import TestQuestionUpdateMutation from '../../graphql/mutations/Tests/Question/Update';

import Category from '../../components/Category';

import LoadingPane from '../../components/Shared/LoadingPane';
import { withRouter } from '../withRouter';

class CategoryContainer extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      loading: false,
      onload: true,
    };
  }

  componentDidMount() {
    const {
      testQuery: { loading, refetch },
    } = this.props;

    if (this.state.onload && !loading) {
      refetch().then(() => this.setup());
    } else {
      this.setup();
    }

    window.addEventListener('keydown', this.handleKeyPress);
  }

  componentDidUpdate() {
    this.setup();
  }

  componentWillUnmount() {
    window.removeEventListener('keydown', this.handleKeyPress);
  }

  handleKeyPress = (e) => {
    // shift + enter
    if (e.shiftKey && e.keyCode === 13) {
      this.goToQuestionRoute();
    }
  };

  setup = () => {
    const {
      location,
      navigate,
      params,
      testQuery: { loading, test },
      context,
    } = this.props;
    const { user } = context;
    const { onload } = this.state;

    if (onload && !loading) {
      const category = test.categories.find((c) => c.id === params.category);
      const org = location.state ? location.state.org : null;
      const role = user.roles.find((r) => r.org.id === org);
      const admin = role ? role.name === 'corporate' : false;

      if (test && test.kind === 'global' && !admin) {
        navigate(`/error${org ? `?org=${org}` : ''}`);
      }
      if (category) {
        this.setState({
          ...category,
          onload: false,
          questions: _.sortBy(category.questions, ['rank']),
          testTitle: test.title,
        });
      } else {
        this.goToTestRoute();
      }
    }
  };

  deleteQuestion = (id, i) => {
    const { testQuestionRemoveMutation } = this.props;
    const { questions } = this.state;

    testQuestionRemoveMutation({
      variables: {
        input: {
          id,
        },
      },
    }).then((response) => {
      const {
        data: {
          testQuestionRemove: { errors },
        },
      } = response;

      if (errors) {
        window.alert(errors[0].message);
      } else {
        this.handleChange('questions', [
          ...questions.slice(0, i),
          ...questions.slice(i + 1, questions.length),
        ]);
      }
    });
  };

  duplicateQuestion = (question) => {
    const { params, testQuestionCreateMutation } = this.props;
    const { answers, isRandomized, title, correctAnswerIndex } = question;

    document.body.click();

    testQuestionCreateMutation({
      variables: {
        input: {
          categoryId: params.category,
          title: `${title} Copy`,
          answers,
          rank: this.state.questions.length + 1,
          isRandomized,
          correctAnswerIndex
        },
      },
    }).then((response) => {
      const {
        data: {
          testQuestionCreate: { errors, result: question },
        },
      } = response;

      if (errors) {
        window.alert(errors[0].message);
      } else {
        this.handleChange('questions', [...this.state.questions, question]);
      }
    });
  };

  goToQuestionRoute = (questionId) => {
    const {
      location,
      navigate,

      params: { id, category },
    } = this.props;
    const org = location.state.org;

    navigate(
      `/tests/${id}/categories/${category}/questions/${questionId || 'create'}`,
      { state: { org } },
    );
  };

  goToTestRoute = () => {
    const { location, navigate, params } = this.props;
    const org = location.state.org;

    navigate(`/tests/${params.id}`, {
      state: {
        previous: `/organizations/${org}/tests`,
        org,
      },
    });
  };

  handleChange = (key, value) => {
    this.setState({
      [key]: value,
    });
  };

  save = (arr, rank) => {
    const { testQuestionUpdateMutation } = this.props;

    this.handleChange('loading', true);

    if (rank < arr.length) {
      testQuestionUpdateMutation({
        variables: {
          input: {
            id: arr[rank].id,
            rank: rank + 1,
          },
        },
      }).then(() => {
        this.save(arr, rank + 1);
      });
    } else {
      this.goToTestRoute();
    }
  };

  render() {
    return this.state.onload ? (
      <LoadingPane />
    ) : (
      <Category
        {...this.props}
        deleteQuestion={this.deleteQuestion}
        duplicateQuestion={this.duplicateQuestion}
        goToQuestionRoute={this.goToQuestionRoute}
        goToTestRoute={this.goToTestRoute}
        handleChange={this.handleChange}
        save={this.save}
        state={this.state}
      />
    );
  }
}

export default compose(
  withRouter,
  graphql(TestQuery, {
    name: 'testQuery',
    options: (props) => ({
      variables: {
        id: props.params.id,
      },
    }),
  }),
  graphql(TestQuestionCreateMutation, { name: 'testQuestionCreateMutation' }),
  graphql(TestQuestionRemoveMutation, { name: 'testQuestionRemoveMutation' }),
  graphql(TestQuestionUpdateMutation, { name: 'testQuestionUpdateMutation' }),
)(CategoryContainer);
