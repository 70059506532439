import { gql } from 'apollo-boost';

const DocumentRemoveMutation = gql`
  mutation DocumentRemoveMutation($input: IDInput) {
    documentRemove(input: $input) {
      errors {
        message
      }
      success
    }
  }
`;

export default DocumentRemoveMutation;
