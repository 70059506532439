import React from 'react';
import { graphql } from 'react-apollo';
import compose from 'lodash.flowright';
import moment from 'moment';
import ChecklistsQuery from '../../graphql/queries/Org/Checklists';
import { withRouter } from '../withRouter';

import OrgContentRemoveMutation from '../../graphql/mutations/Org/Content/Remove';
import ChecklistCategoryCreateMutation from '../../graphql/mutations/Checklists/Category/Create';
import ChecklistCategoryUpdateMutation from '../../graphql/mutations/Checklists/Category/Update';
import ChecklistQuestionCreateMutation from '../../graphql/mutations/Checklists/Question/Create';
import ChecklistSectionCreateMutation from '../../graphql/mutations/Checklists/Sections/Create';
import ChecklistSummaryCreateMutation from '../../graphql/mutations/Checklists/Summary/Create';
import ChecklistSummaryRemoveMutation from '../../graphql/mutations/Checklists/Summary/Remove';

import DashboardChecklists from '../../components/Dashboard/Checklists';

import LoadingPane from '../../components/Shared/LoadingPane';

class DashboardChecklistsContainer extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      checklist: {},
      isModal: false,
      loading: false,
      onload: true,
    };
  }

  componentDidMount() {
    const {
      checklistsQuery: { loading, refetch },
    } = this.props;
    const { onload } = this.state;

    if (onload && !loading) {
      refetch().then(() => this.setup());
    } else {
      this.setup();
    }
  }

  componentDidUpdate() {
    this.setup();
  }

  setup = () => {
    const {
      checklistsQuery: { loading, org },
      params,
      user,
    } = this.props;
    const { onload } = this.state;

    const role = user.roles.find((r) => r.org.id === params.id);
    if (onload && !loading) {
      this.setState({
        ...org,
        admin: role ? role.name === 'corporate' : false,
        onload: false,
        orgId: org.id,
      });
    }
  };

  contentRemove = (hide) => {
    const { checklistsQuery, params, orgContentRemoveMutation } = this.props;
    const { checklist } = this.state;

    this.setState(
      {
        loading: true,
      },
      () => {
        orgContentRemoveMutation({
          variables: {
            input: {
              org: params.id,
              contentType: 'checklist',
              contentId: checklist.id,
            },
          },
        })
          .then((response) => {
            const {
              data: {
                orgContentRemove: { errors, success },
              },
            } = response;

            if (success) {
              checklistsQuery.refetch().then((response) => {
                this.handleChange('loading', false);

                hide();

                setTimeout(() => {
                  this.setState({
                    checklist: {},
                    isModal: false,
                    checklists: response.data.org.checklists,
                  });
                }, 300);
              });
            } else {
              window.alert(errors[0].message);
            }
          })
          .catch((error) => {
            console.log(error);
            this.handleChange('loading', false);
          });
      },
    );
  };

  deleteChecklist = (hide) => {
    const { checklistSummaryRemoveMutation } = this.props;
    const { checklist } = this.state;

    const arr = this.state.checklists.nodes;
    const i = arr.findIndex((c) => c.id === checklist.id);

    this.setState(
      {
        loading: true,
      },
      () => {
        checklistSummaryRemoveMutation({
          variables: {
            input: {
              id: checklist.id,
            },
          },
        }).then((response) => {
          const {
            data: {
              checklistSummaryRemove: { errors },
            },
          } = response;

          this.handleChange('loading', false);

          if (errors) {
            window.alert(errors[0].message);
          } else {
            hide();

            setTimeout(() => {
              this.setState({
                checklists: {
                  nodes: [...arr.slice(0, i), ...arr.slice(i + 1, arr.length)],
                },
              });
            }, 300);
          }
        });
      },
    );
  };

  duplicateCategories = (arr, i, checklist) => {
    const {
      checklistsQuery,
      checklistCategoryCreateMutation,
      checklistCategoryUpdateMutation,
    } = this.props;

    if (i === arr.length) {
      checklistsQuery.refetch().then((response) => {
        const checklists = response.data.org.checklists;
        this.setState({
          loading: false,
          checklists: {
            ...checklists,
            nodes: checklists.nodes,
          },
        });
      });
    } else {
      const { scoreLabels, scoreValues, sections, rank, title } = arr[i];

      checklistCategoryCreateMutation({
        variables: {
          input: {
            checklistId: checklist.id,
            rank,
            title,
          },
        },
      }).then((response) => {
        const {
          data: {
            checklistCategoryCreate: { result },
          },
        } = response;

        checklistCategoryUpdateMutation({
          variables: {
            input: {
              id: result.id,
              scoreLabels,
              scoreValues,
            },
          },
        }).then((response) => {
          const {
            data: { checklistCategoryUpdate },
          } = response;

          if (sections.length > 0) {
            this.duplicateSections(
              sections,
              0,
              checklistCategoryUpdate.result,
              arr,
              i + 1,
              checklist,
            );
          } else {
            this.duplicateCategories(arr, i + 1, checklist);
          }
        });
      });
    }
  };

  duplicateChecklist = (checklist) => {
    document.body.click();

    this.setState(
      {
        loading: true,
      },
      () => {
        const { checklistSummaryCreateMutation, params } = this.props;
        const { desc, durationMins, title } = checklist;

        checklistSummaryCreateMutation({
          variables: {
            input: {
              desc,
              durationMins,
              title: `${title} Copy (${moment().format(
                'MMM DD, YYYY hh:mma',
              )})`,
              orgId: params.id,
            },
          },
        }).then((response) => {
          const {
            data: {
              checklistSummaryCreate: { errors, result },
            },
          } = response;

          if (errors) {
            this.handleChange('loading', false);

            window.alert(errors[0].message);
          } else {
            this.duplicateCategories(checklist.categories, 0, result);
          }
        });
      },
    );
  };

  duplicateQuestions = (
    arr,
    i,
    section,
    sArr,
    sI,
    category,
    cArr,
    cI,
    checklist,
  ) => {
    if (i === arr.length) {
      this.duplicateSections(sArr, sI, category, cArr, cI, checklist);
    } else {
      const { checklistQuestionCreateMutation } = this.props;
      const { rank, scoreLabels, scoreValues, title } = arr[i];

      checklistQuestionCreateMutation({
        variables: {
          input: {
            sectionId: section.id,
            rank,
            scoreLabels,
            scoreValues,
            title,
          },
        },
      }).then(() =>
        this.duplicateQuestions(
          arr,
          i + 1,
          section,
          sArr,
          sI,
          category,
          cArr,
          cI,
          checklist,
        ),
      );
    }
  };

  duplicateSections = (arr, i, category, cArr, cI, checklist) => {
    if (i === arr.length) {
      this.duplicateCategories(cArr, cI, checklist);
    } else {
      const { checklistSectionCreateMutation } = this.props;
      const { questions, rank, title } = arr[i];

      checklistSectionCreateMutation({
        variables: {
          input: {
            categoryId: category.id,
            rank,
            title,
          },
        },
      }).then((response) => {
        const {
          data: {
            checklistSectionCreate: { errors, result },
          },
        } = response;

        if (errors) {
          window.alert(errors[0].message);
        } else if (questions.length > 0) {
          this.duplicateQuestions(
            questions,
            0,
            result,
            arr,
            i + 1,
            category,
            cArr,
            cI,
            checklist,
          );
        } else {
          this.duplicateSections(arr, i + 1, category, cArr, cI, checklist);
        }
      });
    }
  };

  goToChecklistRoute = (id) => {
    const { navigate, location, params } = this.props;

    navigate(`/checklists/${id || 'create'}`, {
      state: {
        previous: location.pathname,
        org: params.id,
      },
    });
  };

  handleChange = (key, value) => {
    this.setState({
      [key]: value,
    });
  };

  handleDeleteChecklist = (checklist) => {
    document.body.click();

    this.handleChange('isModal', true);
    this.handleChange('checklist', checklist);
  };

  toggleModal = (checklist, isModal) => {
    document.body.click();

    this.setState({ checklist, isModal });
  };

  render() {
    return this.state.onload ? (
      <LoadingPane />
    ) : (
      <DashboardChecklists
        {...this.props}
        contentRemove={this.contentRemove}
        duplicateChecklist={this.duplicateChecklist}
        goToChecklistRoute={this.goToChecklistRoute}
        handleChange={this.handleChange}
        handleDeleteChecklist={this.handleDeleteChecklist}
        state={this.state}
        toggleModal={this.toggleModal}
      />
    );
  }
}

export default compose(
  withRouter,
  graphql(ChecklistsQuery, {
    name: 'checklistsQuery',
    options: (props) => ({
      variables: {
        id: props.params.id,
      },
    }),
  }),
  graphql(ChecklistCategoryCreateMutation, {
    name: 'checklistCategoryCreateMutation',
  }),
  graphql(ChecklistCategoryUpdateMutation, {
    name: 'checklistCategoryUpdateMutation',
  }),
  graphql(ChecklistQuestionCreateMutation, {
    name: 'checklistQuestionCreateMutation',
  }),
  graphql(ChecklistSectionCreateMutation, {
    name: 'checklistSectionCreateMutation',
  }),
  graphql(ChecklistSummaryCreateMutation, {
    name: 'checklistSummaryCreateMutation',
  }),
  graphql(ChecklistSummaryRemoveMutation, {
    name: 'checklistSummaryRemoveMutation',
  }),
  graphql(OrgContentRemoveMutation, { name: 'orgContentRemoveMutation' }),
)(DashboardChecklistsContainer);
