import gql from 'graphql-tag';

import TEST_SUMMARY_FRAGMENT from '../../../fragments/Test/Summary';

const OrgTestSummaryQuery = gql`
  query OrgTestSummaryQuery($id: ID!) {
    org(id: $id) {
      id
      brandColor
      secondaryColor
      tests(order: "title", status: ["active", "inactive"]) {
        nodes {
          ...TestSummary
          org {
            id
          }
        }
      }
    }
  }
  ${TEST_SUMMARY_FRAGMENT}
`;

export default OrgTestSummaryQuery;
