import React, { useEffect, useState } from 'react';
import OptionalFactoryQuery from '../../graphql/queries/Org/Feedback/FeedbackFactors';
import { graphql } from 'react-apollo';
import compose from 'lodash.flowright';
import LoadingPane from '../Shared/LoadingPane';
import { useLocation } from 'react-router';
import { withRouter } from '../../containers/withRouter';
import Toolbar from '../Shared/Toolbar';
import Select from 'react-select';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrash } from '@fortawesome/free-solid-svg-icons';


const FeedbackRequest = (props) => {
  const [loading, setLoading] = useState(false);
  const [Factors, setFactors] = useState([]);
  const [selectedFactor, setSelectedFactor] = useState([]);
  const [selectedData, setSelectedData] = useState([]);
  const [Alert, setAlert] = useState('');
  const [selectedValue, setSelectedValue] = useState('');

  const optionList = Factors.map((row) => {
    if (row.title && row.otype === '1') {
      return row.title;
    }
  })
    .filter((option) => typeof option === 'string')
    .map((option) => ({ value: option, label: option }));

  function handleSelect(data) {
    setSelectedValue(data);
    setSelectedFactor(data.map((item) => item.value));
    setSelectedData([
      ...new Set([...selectedData, data.map((item) => item.value)].flat()),
    ]);
  }

  const addRow = () => {
    if (selectedFactor) {
      const updateDataForSelectedFactors = (Factors, selectedFactors) => {
        return Factors.map((item) => {
          if (selectedFactors.includes(item.title)) {
            return { ...item, otype: '0' }; // Update the otype for the matching record
          }
          return item; // Keep other records as they are
        });
      };
      const updatedData = updateDataForSelectedFactors(Factors, selectedFactor);
      console.log(updatedData);
      if (updatedData) {
        setFactors(updatedData);
        setSelectedValue('');
        setSelectedFactor('');
      }
    } else {
      setAlert('Please Select Factors');
    }
  };

  const deleteRow = (title) => {
    const updatedData = Factors.map((item) => {
      if (title === item.title) {
        return { ...item, otype: '1' };
      }
      return item;
    });
    setFactors(updatedData);
  };

  const onCloseAlert = () => {
    const { navigate } = props;
    setAlert('');
    navigate(`/organizations/1/360Feedback`, {});
  };

  useEffect(() => {
    setLoading(true);
    const {
      optionalFactoryQuery: { refetch },
    } = props;
    refetch().then((res) => {
      const factors = res.data.org.optionalFactors.nodes;

      if (factors) {
        setFactors(factors);
        setLoading(false);
      }
    });
  }, []);

  const onSaveFeedback = () => {
    const { index, feedbackForm, closeModal, onSaveFeedback } = props;

    let factors = [];
    Factors.map((item) => {
      if (item.otype === '0') {
        factors.push(item.id);
      }
    });

    const feedbackInputArray = feedbackForm.map((feedback, i) => {
      if (i === index) {
        return {
          ...feedback,
          ofactory: factors.map(Number),
        };
      } else {
        return feedback;
      }
    });
    onSaveFeedback(feedbackInputArray);
    closeModal();
  };

  const loadTablebody = (row, index) => {
    return (
      <li className={'test-cell'} key={`table-row-${index}`}>
        <div
          className="centered-frame"
          key={index}
          style={{ marginLeft: '160px' }}
        >
          <div className="cell-content-frame">
            <div className="cell-label list long" style={{ width: '37%' }}>
              {row.title}
            </div>
            {selectedData.map((item, i) => {
              if (row.title === item) {
                return (
                  <div className="cell-label list" key={`selected-data-${i}`}>
                    <button
                      style={{ background: 'transparent', marginLeft: '37px' }}
                      onClick={(e) => {
                        deleteRow(row.title);
                      }}
                    >
                      <FontAwesomeIcon icon={faTrash} size={'sm'} />
                    </button>
                  </div>
                );
              }
            })}
          </div>
        </div>
      </li>
    );
  };
  const loadTableHeader = (
    <div className="table-headers">
      <div className="centered-frame" style={{ marginLeft: '160px' }}>
        <div className="table-header list long" style={{ width: '37%' }}>
          {'Factors'}
        </div>
      </div>
    </div>
  );
  const customStyles = {
    multiValue: (provided) => ({
      ...provided,
      maxHeight: '60px', // Set the max height as needed
      overflowY: 'auto',
    }),
    multiValueLabel: (provided) => ({
      ...provided,
      maxHeight: '50px', // Set the max height as needed
      overflowY: 'auto',
    }),
  };
  return (
    <div className="dashboard-feedback-view">
      {loading ? (
        <LoadingPane style={{ background: 'rgba(0,0,0,0.1)' }} />
      ) : null}

      {Alert && (
        <div className="modaloverlay">
          <div className="modal" style={{ width: '20%' }}>
            <h2>Alert</h2>
            <div
              className="model-body"
              dangerouslySetInnerHTML={{ __html: Alert }}
              style={{ display: 'inline' }}
            >
              {/* {Alert} */}
            </div>
            <div>
              <button
                type="submit"
                style={{ width: '20%' }}
                className="model-submit-button"
                onClick={onCloseAlert}
              >
                OK
              </button>
            </div>
          </div>
        </div>
      )}

      <Toolbar
        cancel={props.closeModal}
        title={'Choose Factors'}
        save={onSaveFeedback}
      />

      <div style={{ padding: 10, marginTop: 80 }}>
        <div>
          <div className="feedback-table-viewthree">
            <>{loadTableHeader}</>
            <ul className="tests-list-view scroll">
              {Factors.map((row, index) => {
                if (row.title && row.otype === '0') {
                  return loadTablebody(row, index);
                }
              })}
            </ul>
          </div>
        </div>
      </div>

      <div style={{ marginTop: 390 }}>
        <div className="centered-frame" style={{ marginLeft: '120px' }}>
          <div className="dropdown-container">
            <Select
              className="selectdropdown"
              options={optionList}
              placeholder="Select Factor"
              value={selectedValue}
              onChange={handleSelect}
              isSearchable={true}
              isMulti
              styles={customStyles} // Apply custom styles
              menuPlacement="top"
            />
            <button
              style={{
                height: 30,
                width: '3.5%',
                fontWeight: 'bold',
                color: 'rgb(255, 90, 95)',
              }}
              onClick={() => addRow()}
            >
              {' '}
              +{' '}
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};
export default compose(
  withRouter,
  graphql(OptionalFactoryQuery, {
    name: 'optionalFactoryQuery',
    options: (props) => ({
      variables: {
        id: props.state.role.org.id,
      },
    }),
  }),
)(FeedbackRequest);
