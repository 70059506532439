import { gql } from 'apollo-boost';

const WorkExperienceQuery = gql`
  query WorkExperienceQuery($id: ID) {
    specialties {
      id
      name
    }
    user(id: $id) {
      id
      workExperiences {
        id
        city
        endedOn
        kind
        name
        positionTitle
        startedOn
        state
      }
    }
  }
`;

export default WorkExperienceQuery;
