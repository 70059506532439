import React from 'react';

import _ from 'lodash';

import STRINGS from '../../../../../localization';

const AnnualTBAttestation = (props) => {
  const {
    state: { kind },
  } = props;

  const arr = [
    'chronic cough',
    'production of sputum',
    'blood streaked sputum',
    'unexplained weight loss',
    'fever',
    'fatigue/tiredness',
    'night sweats',
    'shortness of breath',
    'exposed to someone with active TB infection',
  ];

  return (
    <div className="key-value-frame full">
      <div className="form-frame">
        <div className="form-name">{STRINGS[_.camelCase(kind)]}</div>

        <div className="form-label">
          I attest that I have either had a positive TB skin test in the past,
          have had the BCG vaccine, or have an allergy to the TB skin test.
        </div>

        <div className="flex-frame">
          <div className="form-label underline">
            I have not had any of the following symptoms
          </div>

          <div className="form-label">
            in the past three (3) weeks or longer:
          </div>
        </div>

        <ul className="form-list-view">
          {arr.map((o, i) => {
            return (
              <li className="form-label" key={i}>
                {o}
              </li>
            );
          })}
        </ul>

        <div className="form-label">
          I understand that if I begin exhibiting any of these symptoms or
          become exposed to someone with active TB, I will notify my employer
          and seek medical advice from a licensed independent practitioner for
          further follow up.
        </div>
      </div>
    </div>
  );
};

export default AnnualTBAttestation;
