import React from 'react';
import { graphql } from 'react-apollo';

import { flowRight as compose, orderBy } from 'lodash';
import moment from 'moment';
import OrgQuery from '../../../graphql/queries/Org/tabs/Reporting';

import OrgReporting from '../../../components/Org/tabs/Reporting';

import LoadingPane from '../../../components/Shared/LoadingPane';

class OrgReportingContainer extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      csv: [['Name', 'Tests', 'Checklists', 'Mandatories', 'Date']],
      cvData: [['First Name', 'Last Name', 'Date']],
      data: [],
      isModal: false,
      loading: true,
      onload: true,
      startDate: moment().startOf('month').startOf('day').format('YYYY-MM-DD'),
      endDate: moment().endOf('month').format('YYYY-MM-DD'),
      _startDate: moment().startOf('month').startOf('day').format('YYYY-MM-DD'),
      _endDate: moment().endOf('month').format('YYYY-MM-DD'),
    };
  }

  componentDidMount() {
    this.setup();
  }

  componentDidUpdate() {
    this.setup();
  }

  setup = () => {
    const {
      orgQuery: { loading, org },
    } = this.props;
    const { onload } = this.state;

    if (onload && !loading) {
      this.setState(
        {
          ...org,
          onload: false,
        },
        () => this.setupUsers(this.state.users),
      );
    }
  };

  setupBreakdown = (data, key) => {
    if (data.length === 0) {
      return 0;
    } else {
      // let totalTime = data.reduce((total, o) => {
      //     let arr = o[key].filter(c => c.title.toLowerCase() !== 'color vision'),
      //         userTime = (arr.length > 0) ? (arr.reduce((total2, t) => total2 + parseInt(t.timeSpentSecs), 0) / arr.length) : 0

      //     return total + Math.round(userTime)
      // }, 0)

      // return moment.utc(Math.round(totalTime / data.length) * 1000).format('mm:ss');

      return this.calculateBreakdown(key);
    }
  };

  setupUsers = async (users) => {
    const { orgQuery } = this.props;

    if (users.pageInfo.hasNextPage) {
      const result = await orgQuery.refetch({
        after: users.pageInfo.endCursor,
      });

      if (result.data) {
        const newUsers = result.data.org.users;

        this.setupUsers({
          ...newUsers,
          nodes: [newUsers.nodes, users.nodes].flat(),
        });
      }
    } else {
      this.setState(
        {
          loading: false,
          users,
        },
        () => this.runReport(),
      );
    }
  };

  setupData = (arr) => {
    const { startDate, endDate } = this.state;

    return arr.filter((user) => {
      const { checklistsTaken, coursesTaken, documents, testsTaken } = user;

      const arr = [checklistsTaken, coursesTaken, testsTaken].flat();
      const results = arr.filter((result) => {
        const date = moment(result.endedAt);

        return (
          date.isSameOrAfter(moment(startDate)) &&
          date.isSameOrBefore(moment(endDate))
        );
      });
      const expirations = documents.nodes.filter((d) => {
        const date = moment(d.expiresOn);

        return (
          date.isSameOrAfter(moment().startOf('day')) &&
          date.isSameOrBefore(moment(endDate))
        );
      });

      return results.length > 0 || expirations.length > 0
        ? { ...user, results }
        : null;
    });
  };

  calculateBreakdown = (key) => {
    const { data, endDate, startDate } = this.state;

    const breakdown = {};
    const results = data.map((o) => o[key]).flat();

    results.forEach((result) => {
      const { endedAt, title } = result;

      const d = moment(endedAt);
      const existing = breakdown[title];

      if (
        d.isSameOrAfter(moment(startDate)) &&
        d.isSameOrBefore(moment(endDate))
      ) {
        if (existing) {
          breakdown[title] = [...existing, result];
        } else {
          breakdown[title] = [result];
        }
      }
    });

    const contentAvg = Object.keys(breakdown).map((contentName) => {
      const arr = breakdown[contentName];

      return arr.length > 0
        ? arr.reduce((total, o) => total + parseInt(o.timeSpentSecs, 10), 0) /
            arr.length
        : 0;
    });

    return contentAvg.length > 0
      ? moment
          .utc(
            Math.round(
              contentAvg.reduce((total, t) => total + t, 0) / contentAvg.length,
            ) * 1000,
          )
          .format('mm:ss')
      : '00:00';
  };

  handleChange = (key, value) => {
    this.setState({
      [key]: value,
    });
  };

  handleBreakdown = (key) => {
    const { data } = this.state;

    const breakdown = {};
    const results = data.map((o) => o[key]).flat();

    results.forEach((result) => {
      const { title } = result;

      const existing = breakdown[title];

      if (existing) {
        breakdown[title] = [...existing, result];
      } else {
        breakdown[title] = [result];
      }
    });

    this.setState({
      breakdown,
      breakdownAmt: data.filter((o) => o[key].length > 0).length,
      isModal: true,
    });
  };

  runReport = () => {
    this.setState(
      {
        endDate: this.state._endDate,
        startDate: this.state._startDate,
      },
      () => {
        const { endDate, startDate } = this.state;
        const amount = (arr) =>
          arr.filter(
            (o) =>
              moment(o.endedAt).isSameOrAfter(moment(startDate)) &&
              moment(o.endedAt).isSameOrBefore(moment(endDate)),
          );

        const data = this.setupData(this.state.users.nodes);
        const csvData = [];
        const cvData = [];

        data.forEach((obj) => {
          const {
            coursesTaken,
            checklistsTaken,
            documents,
            firstName,
            lastName,
            testsTaken,
          } = obj;

          const tests = amount(testsTaken).length;
          const checklists = amount(checklistsTaken).length;
          const courses = amount(coursesTaken).length;
          const arr = [
            amount(testsTaken),
            amount(checklistsTaken),
            amount(coursesTaken),
          ].flat();
          const date =
            arr.length > 0
              ? moment(orderBy(arr, ['endedAt'])[0].endedAt).format(
                  'MMM D, YYYY',
                )
              : null;
          const expirations = documents.nodes.filter((d) => {
            const date = moment(d.expiresOn);
            const today = moment();

            if (d.expiresOn) {
              const expiresAfter = date.isAfter(today);
              const numOfDays = expiresAfter ? date.diff(today, 'days') : null;

              return expiresAfter && numOfDays <= 60;
            } else {
              return null;
            }
          });

          csvData.push([
            firstName,
            lastName,
            tests,
            checklists,
            courses,
            date,
            expirations.length > 0 ? expirations[0].title : '',
            expirations.length > 0
              ? moment(expirations[0].expiresOn).format('MM/DD/YYYY')
              : '',
          ]);

          if (expirations.length > 1) {
            const arr = expirations.slice(1, expirations.length);

            arr.forEach((o) =>
              csvData.push([
                '',
                '',
                '',
                '',
                '',
                '',
                o.title,
                moment(o.expiresOn).format('MM/DD/YYYY'),
              ]),
            );
          }

          // build color vision csv data
          amount(testsTaken)
            .filter((o) => o.title === 'Color Vision')
            .forEach((r, i) => {
              if (i === 0) {
                cvData.push([
                  firstName,
                  lastName,
                  moment(r.endedAt).format('MM/DD/YYYY'),
                ]);
              } else {
                cvData.push(['', '', moment(r.endedAt).format('MM/DD/YYYY')]);
              }
            });
        });

        this.setState({
          cvData: [['First Name', 'Last Name', 'Date'], ...cvData],
          csv: [
            [
              'First Name',
              'Last Name',
              'Tests',
              'Checklists',
              'Mandatories',
              'Date',
              'Document Name',
              'Expiration Date',
            ],
            ...csvData,
          ],
          data,
        });
      },
    );
  };

  render() {
    return this.state.onload ? (
      <LoadingPane />
    ) : (
      <OrgReporting
        generateExportData={this.generateExportData}
        handleBreakdown={this.handleBreakdown}
        handleChange={this.handleChange}
        runReport={this.runReport}
        setupBreakdown={this.setupBreakdown}
        state={this.state}
      />
    );
  }
}

export default compose(
  graphql(OrgQuery, {
    name: 'orgQuery',
    options: (props) => ({
      variables: {
        id: props.state.id,
        first: 50,
      },
    }),
  }),
)(OrgReportingContainer);
