import { gql } from 'apollo-boost';

const BundleRemoveMutation = gql`
  mutation BundleRemoveMutation($input: IDInput) {
    bundleRemove(input: $input) {
      errors {
        code
        message
      }
      success
    }
  }
`;

export default BundleRemoveMutation;
