import { gql } from 'apollo-boost';

const UserRoleAddMutation = gql`
  mutation UserRoleAddMutation($input: UserRoleAddInput) {
    userRoleAdd(input: $input) {
      errors {
        code
        message
      }
      success
    }
  }
`;

export default UserRoleAddMutation;
