import React from 'react';
import { graphql } from 'react-apollo';
import compose from 'lodash.flowright';

import UserQuery from '../../graphql/queries/Roster/Checklist';

import RosterChecklist from '../../components/Roster/Checklist';

import LoadingPane from '../../components/Shared/LoadingPane';
import { withRouter } from '../withRouter';
import ChecklistScore from '../../components/Shared/Checklist/ChecklistScore';

const refs = {};

class RosterChecklistContainer extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      answer: {},
      expand: false,
      onload: true,
    };
  }

  componentDidMount() {
    this.setup();
  }

  componentDidUpdate() {
    this.setup();
  }

  setup = () => {
    const {
      userQuery: { loading, org, user },
    } = this.props;
    const { onload } = this.state;

    if (onload && !loading) {
      this.setState(
        {
          ...user,
          attachment: org ? org.attachments[0] : null,
        },
        () => this.setupAnswers(),
      );
    }
  };

  setupAnswers = () => {
    const { checklistTaken } = this.state;

    const hash = {};

    checklistTaken.answers.forEach(
      (answer) => (hash[answer.question.id] = answer.value),
    );

    this.setupChecklist(hash);
  };

  setupChecklist = (answers) => {
    const checklist = this.state.checklistTaken.checklist;

    this.setState({
      checklist: ChecklistScore.calculateAvgChecklistScore(checklist, answers),
      onload: false,
    });
  };

  back = () => {
    const {
      navigate,
      params: { id, roster },
    } = this.props;

    navigate(`/organizations/${id}/roster/${roster}`);
  };

  handleAnimation = (node, data, parent) => {
    const elem = refs[node];
    const icon = elem.querySelector('.chevron-icon');
    const cellHeight = 65;
    let height = 0;
    let i = 0;

    if (elem.className.includes('expanded')) {
      elem.classList.remove('expanded');

      if (elem.className.includes('scoring-key-frame')) {
        elem.style.height = '60px';
      } else {
        elem.style.height = `${cellHeight}px`;

        if (data.questions) {
          const parentElem = refs[parent];

          for (i; i < parentElem.children.length; i++) {
            const childNode = parentElem.children[i];

            if (childNode.className === 'sections-list-view') {
              for (let idx = 0; idx < childNode.children.length; idx++) {
                const sectionNode = childNode.children[idx];

                height +=
                  sectionNode.className === elem.className
                    ? cellHeight
                    : sectionNode.getBoundingClientRect().height;
              }
            } else {
              height += childNode.getBoundingClientRect().height;
            }
          }

          parentElem.style.height = `${height}px`;
        }
      }

      icon.classList.remove('rotate');
    } else {
      elem.classList.add('expanded');

      if (elem.className === 'scoring-key-frame') {
        elem.style.height = `${60 + 60 * data.length}px`;
      } else {
        for (i; i < elem.children.length; i++) {
          height += elem.children[i].getBoundingClientRect().height;
        }

        if (data.questions) {
          const parentElem = refs[parent];

          parentElem.style.height = `${
            parentElem.getBoundingClientRect().height - cellHeight + height
          }px`;
        }

        elem.style.height = `${height}px`;
      }

      icon.classList.add('rotate');
    }
  };

  handleExpand = (expand) => {
    const elem = refs.categories;

    for (let i = 0; i < elem.children.length; i++) {
      const c = elem.children[i]; // category
      let cH = 0; // category height
      const icon = c.querySelector('.chevron-icon');

      if (expand) {
        c.classList.add('expanded');
        icon.classList.add('rotate');
      } else {
        c.classList.remove('expanded');
        icon.classList.remove('rotate');
      }

      for (let j = 0; j < c.children.length; j++) {
        const cN = c.children[j]; // category child node

        if (cN.className === 'sections-list-view') {
          for (let k = 0; k < cN.children.length; k++) {
            const sN = cN.children[k]; // section child node
            let sH = 0; // section child height
            const sIcon = sN.querySelector('.chevron-icon');

            if (expand) {
              sN.classList.add('expanded');
              sIcon.classList.add('rotate');
            } else {
              sN.classList.remove('expanded');
              sIcon.classList.remove('rotate');
            }

            for (let m = 0; m < sN.children.length; m++) {
              const sCN = sN.children[m]; // section child child node

              cH += expand ? sCN.getBoundingClientRect().height : 0;
              sH += sCN.getBoundingClientRect().height;
            }

            sN.style.height = `${expand ? sH : 65}px`;
          }
        } else {
          cH += cN.getBoundingClientRect().height;
        }
      }

      c.style.height = `${cH}px`;
    }

    this.handleChange('expand', expand);
  };

  handleChange = (key, value) => {
    this.setState({
      [key]: value,
    });
  };

  render() {
    return this.state.onload ? (
      <LoadingPane />
    ) : (
      <RosterChecklist
        back={this.back}
        refs={refs}
        handleAnimation={this.handleAnimation}
        handleExpand={this.handleExpand}
        state={this.state}
        user={this.props.context.user}
      />
    );
  }
}

export default compose(
  withRouter,
  graphql(UserQuery, {
    name: 'userQuery',
    options: ({ params: { id, roster, item } }) => ({
      variables: {
        id: roster,
        checklist: item,
        org: id,
      },
    }),
  }),
)(RosterChecklistContainer);
