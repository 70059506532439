export const states = [
  {
    name: 'Alabama',
    abbreviation: 'AL',
  },
  {
    name: 'Alaska',
    abbreviation: 'AK',
  },
  {
    name: 'American Samoa',
    abbreviation: 'AS',
  },
  {
    name: 'Arizona',
    abbreviation: 'AZ',
  },
  {
    name: 'Arkansas',
    abbreviation: 'AR',
  },
  {
    name: 'California',
    abbreviation: 'CA',
  },
  {
    name: 'Colorado',
    abbreviation: 'CO',
  },
  {
    name: 'Connecticut',
    abbreviation: 'CT',
  },
  {
    name: 'Delaware',
    abbreviation: 'DE',
  },
  {
    name: 'District Of Columbia',
    abbreviation: 'DC',
  },
  {
    name: 'Federated States Of Micronesia',
    abbreviation: 'FM',
  },
  {
    name: 'Florida',
    abbreviation: 'FL',
  },
  {
    name: 'Georgia',
    abbreviation: 'GA',
  },
  {
    name: 'Guam',
    abbreviation: 'GU',
  },
  {
    name: 'Hawaii',
    abbreviation: 'HI',
  },
  {
    name: 'Idaho',
    abbreviation: 'ID',
  },
  {
    name: 'Illinois',
    abbreviation: 'IL',
  },
  {
    name: 'Indiana',
    abbreviation: 'IN',
  },
  {
    name: 'Iowa',
    abbreviation: 'IA',
  },
  {
    name: 'Kansas',
    abbreviation: 'KS',
  },
  {
    name: 'Kentucky',
    abbreviation: 'KY',
  },
  {
    name: 'Louisiana',
    abbreviation: 'LA',
  },
  {
    name: 'Maine',
    abbreviation: 'ME',
  },
  {
    name: 'Marshall Islands',
    abbreviation: 'MH',
  },
  {
    name: 'Maryland',
    abbreviation: 'MD',
  },
  {
    name: 'Massachusetts',
    abbreviation: 'MA',
  },
  {
    name: 'Michigan',
    abbreviation: 'MI',
  },
  {
    name: 'Minnesota',
    abbreviation: 'MN',
  },
  {
    name: 'Mississippi',
    abbreviation: 'MS',
  },
  {
    name: 'Missouri',
    abbreviation: 'MO',
  },
  {
    name: 'Montana',
    abbreviation: 'MT',
  },
  {
    name: 'Nebraska',
    abbreviation: 'NE',
  },
  {
    name: 'Nevada',
    abbreviation: 'NV',
  },
  {
    name: 'New Hampshire',
    abbreviation: 'NH',
  },
  {
    name: 'New Jersey',
    abbreviation: 'NJ',
  },
  {
    name: 'New Mexico',
    abbreviation: 'NM',
  },
  {
    name: 'New York',
    abbreviation: 'NY',
  },
  {
    name: 'North Carolina',
    abbreviation: 'NC',
  },
  {
    name: 'North Dakota',
    abbreviation: 'ND',
  },
  {
    name: 'Northern Mariana Islands',
    abbreviation: 'MP',
  },
  {
    name: 'Ohio',
    abbreviation: 'OH',
  },
  {
    name: 'Oklahoma',
    abbreviation: 'OK',
  },
  {
    name: 'Oregon',
    abbreviation: 'OR',
  },
  {
    name: 'Palau',
    abbreviation: 'PW',
  },
  {
    name: 'Pennsylvania',
    abbreviation: 'PA',
  },
  {
    name: 'Puerto Rico',
    abbreviation: 'PR',
  },
  {
    name: 'Rhode Island',
    abbreviation: 'RI',
  },
  {
    name: 'South Carolina',
    abbreviation: 'SC',
  },
  {
    name: 'South Dakota',
    abbreviation: 'SD',
  },
  {
    name: 'Tennessee',
    abbreviation: 'TN',
  },
  {
    name: 'Texas',
    abbreviation: 'TX',
  },
  {
    name: 'Utah',
    abbreviation: 'UT',
  },
  {
    name: 'Vermont',
    abbreviation: 'VT',
  },
  {
    name: 'Virgin Islands',
    abbreviation: 'VI',
  },
  {
    name: 'Virginia',
    abbreviation: 'VA',
  },
  {
    name: 'Washington',
    abbreviation: 'WA',
  },
  {
    name: 'West Virginia',
    abbreviation: 'WV',
  },
  {
    name: 'Wisconsin',
    abbreviation: 'WI',
  },
  {
    name: 'Wyoming',
    abbreviation: 'WY',
  },
];

export const nursysJurisdictions = [
  {
    name: 'Alabama',
    abbreviation: 'AL',
  },
  {
    name: 'Alaska',
    abbreviation: 'AK',
  },
  {
    name: 'American Samoa',
    abbreviation: 'AS',
  },
  {
    name: 'Arizona',
    abbreviation: 'AZ',
  },
  {
    name: 'Arkansas',
    abbreviation: 'AR',
  },
  {
    name: 'California - RN',
    abbreviation: 'CARN',
  },
  {
    name: 'California - VN',
    abbreviation: 'CAVN',
  },
  {
    name: 'Colorado',
    abbreviation: 'CO',
  },
  {
    name: 'Connecticut',
    abbreviation: 'CT',
  },
  {
    name: 'Delaware',
    abbreviation: 'DE',
  },
  {
    name: 'District Of Columbia',
    abbreviation: 'DC',
  },
  {
    name: 'Federated States Of Micronesia',
    abbreviation: 'FM',
  },
  {
    name: 'Florida',
    abbreviation: 'FL',
  },
  {
    name: 'Georgia',
    abbreviation: 'GA',
  },
  {
    name: 'Guam',
    abbreviation: 'GU',
  },
  {
    name: 'Hawaii',
    abbreviation: 'HI',
  },
  {
    name: 'Idaho',
    abbreviation: 'ID',
  },
  {
    name: 'Illinois',
    abbreviation: 'IL',
  },
  {
    name: 'Indiana',
    abbreviation: 'IN',
  },
  {
    name: 'Iowa',
    abbreviation: 'IA',
  },
  {
    name: 'Kansas',
    abbreviation: 'KS',
  },
  {
    name: 'Kentucky',
    abbreviation: 'KY',
  },
  {
    name: 'Louisiana - RN',
    abbreviation: 'LARN',
  },
  {
    name: 'Louisiana - PN',
    abbreviation: 'LAPN',
  },
  {
    name: 'Maine',
    abbreviation: 'ME',
  },
  {
    name: 'Maryland',
    abbreviation: 'MD',
  },
  {
    name: 'Massachusetts',
    abbreviation: 'MA',
  },
  {
    name: 'Michigan',
    abbreviation: 'MI',
  },
  {
    name: 'Minnesota',
    abbreviation: 'MN',
  },
  {
    name: 'Mississippi',
    abbreviation: 'MS',
  },
  {
    name: 'Missouri',
    abbreviation: 'MO',
  },
  {
    name: 'Montana',
    abbreviation: 'MT',
  },
  {
    name: 'Nebraska',
    abbreviation: 'NE',
  },
  {
    name: 'Nevada',
    abbreviation: 'NV',
  },
  {
    name: 'New Hampshire',
    abbreviation: 'NH',
  },
  {
    name: 'New Jersey',
    abbreviation: 'NJ',
  },
  {
    name: 'New Mexico',
    abbreviation: 'NM',
  },
  {
    name: 'New York',
    abbreviation: 'NY',
  },
  {
    name: 'North Carolina',
    abbreviation: 'NC',
  },
  {
    name: 'North Dakota',
    abbreviation: 'ND',
  },
  {
    name: 'Northern Mariana Islands',
    abbreviation: 'MP',
  },
  {
    name: 'Ohio',
    abbreviation: 'OH',
  },
  {
    name: 'Oklahoma',
    abbreviation: 'OK',
  },
  {
    name: 'Oregon',
    abbreviation: 'OR',
  },
  {
    name: 'Palau',
    abbreviation: 'PW',
  },
  {
    name: 'Pennsylvania',
    abbreviation: 'PA',
  },
  {
    name: 'Puerto Rico',
    abbreviation: 'PR',
  },
  {
    name: 'Rhode Island',
    abbreviation: 'RI',
  },
  {
    name: 'South Carolina',
    abbreviation: 'SC',
  },
  {
    name: 'South Dakota',
    abbreviation: 'SD',
  },
  {
    name: 'Tennessee',
    abbreviation: 'TN',
  },
  {
    name: 'Texas',
    abbreviation: 'TX',
  },
  {
    name: 'Utah',
    abbreviation: 'UT',
  },
  {
    name: 'Vermont',
    abbreviation: 'VT',
  },
  {
    name: 'Virgin Islands',
    abbreviation: 'VI',
  },
  {
    name: 'Virginia',
    abbreviation: 'VA',
  },
  {
    name: 'Washington',
    abbreviation: 'WA',
  },
  {
    name: 'West Virginia - RN',
    abbreviation: 'WVRN',
  },
  {
    name: 'West Virginia - PN',
    abbreviation: 'WVPN',
  },
  {
    name: 'Wisconsin',
    abbreviation: 'WI',
  },
  {
    name: 'Wyoming',
    abbreviation: 'WY',
  },
];

export const roles = (role) => {
  const arr = [{ value: 'nurse', name: 'Healthcare Professional' }];

  if (
    role === 'corporate' ||
    role === 'super_admin' ||
    role === 'admin' ||
    role === 'recruiter'
  ) {
    arr.push({ value: 'recruiter', name: 'Recruiter / QA' });
  }

  if (role === 'corporate' || role === 'super_admin' || role === 'admin') {
    arr.push({ value: 'admin', name: 'Admin' });
  }

  if (role === 'corporate' || role === 'super_admin') {
    arr.push({ value: 'super_admin', name: 'Super Admin' });
  }

  if (role === 'corporate') {
    arr.push({ value: 'corporate', name: 'Corporate' });
  }

  return arr.reverse();
};

export const roleNames = {
  admin: 'Admin',
  corporate: 'Corporate',
  nurse: 'Healthcare Professional',
  recruiter: 'Recruiter / QA',
  super_admin: 'Super Admin',
};

export const documents = [
  'certifications',
  'education',
  'licenses',
  'references',
  'work_experiences',
];

export const jobTitles = [
  { id: 'supervisor', value: 'Supervisor' },
  { id: 'nurse_manager', value: 'Nurse Manager' },
  { id: 'change_nurse', value: 'Charge Nurse' },
  { id: 'charge_nurse', value: 'Charge Nurse' },
  { id: 'educator', value: 'Educator' },
  { id: 'hr_manager_/_director', value: 'HR Manager/Director' },
  { id: 'peer_/_co-worker', value: 'Peer/Co-Worker' },
  { id: 'subordinate', value: 'Subordinate' },
  { id: 'other', value: 'Other' },
];

export const positionTitles = [
  { id: 'rn', value: 'RN' },
  { id: 'lpn_lvn', value: 'LPN/LVN' },
  { id: 'charge_nurse', value: 'Charge RN' },
  { id: 'nurse_manager', value: 'Nurse Manager' },
  { id: 'assistant_nurse_manager', value: 'Assistant Nurse Manager' },
  { id: 'house_supervisor', value: 'House Supervisor' },
  { id: 'don', value: 'DON' },
  { id: 'director', value: 'Director' },
  { id: 'therapist', value: 'Therapist' },
  { id: 'technologist', value: 'Technologist' },
];

export const licenseTypes = [
  { id: 'APRN', value: 'APRN' },
  { id: 'CNA', value: 'CNA' },
  { id: 'CNM', value: 'CNM' },
  { id: 'CRNA', value: 'CRNA' },
  { id: 'CNS', value: 'CNS' },
  { id: 'CNP', value: 'CNP' },
  { id: 'LPN/LVN', value: 'LPN/LVN' },
  { id: 'PN', value: 'PN' },
  { id: 'RN', value: 'RN' },
  { id: 'RRT', value: 'RRT' },
];

export const vaccinationTypes = [
  { id: 'hepB', value: 'Hep B' },
  { id: 'mmr', value: 'MMR' },
  { id: 'varicella', value: 'Varicella' },
  { id: 'COVID-19 Vaccination', value: 'COVID-19 Vaccination' },
  { id: 'varicella', value: 'Varicella' },
  { id: 'TDAP Vaccination', value: 'TDAP Vaccination' },
];

export const tokenList = [
  "a4003ed1785517465223e85f9b591fbd363f7f45181e40ce4bc6d63485044a02ea799e3916a9fcc64981a83a09794938b2477f6d0b4275ad204649e4d177d99e",
  "1621cdad4d5150647755b5d4b4f629daae257cdace730068f6c52d2b0a7fe5eef5ed1af05814c9358709a4e7c07d99bfe1ea9357df258ae08bea542ae906543b",
  "ef88a67a3929909a5ee85389d2a0eb905892d6b87e13ffa77393ef370bfeaf131e3b1bac653cf97c414edf59cd804ac3dae17393863c51cd5c8df94455346f1c",
  "68124643c38cb5a5e575837347eb3c474b9136a9b43a33ef9f31d5ea2c1a84ae5e36a5db77bb0e97adb16185cde57efef5e01cd21839b7621268260b057b92c3",
  "38c4701725ca34c657c2759e08c9a629fb57eb8091134b8daef3546c47a939ae29d8011acae5f8a6c961c3e58102593c44bfb50fc20689fa7a30ef8fe033e653",
  "07e215663956539142bc3ed7c28230c70d3978b500995f41e3cd25fa5fb9221d89440edc2025dcfcc5f4d9aff8ddc877855657ddb5d061043bab46acf17654ba",
  "299aceed950a10acee45a0f5607fbd1158c67a08a72a8b9270b5b8d5d0eaf989de6ca47871c0778211a29c91cd500a22d8024b7aea3c041ca605938139913c43",
  "551c971c939893ccc5289c34d7c2646c24270198d263701f4065670f347f5329c5c1ddf259151a1caa8a67d8a81b501220a92b861fe6d30073832ba1a515f85f",
  "d7cf03f32f6d43b3680f855021e8b5a335b0dbc826cbaf6a6720665a684535171da6563cc9604794b5b0940c54dabdadbb9fd32da6a3e7884ded235c704c674f",
  "237a733bdc5610177e8f98af26569354f02d7c053fc044f690cc6b4abcdc5fe98ed230c9eec81bad73ad8732e645869d9881fade12ee1fe5f1bfefa6dd5024bd",
  "6dcec3b02872a1f19ba55b27482f5de0185f9a5f6d47d343d8daf69209b897053e3c220d6c02f658db7bde22593df7028036fe0bdb65bc6efbb36797a791c845",
  "aa95a9b03f5821ff9d3ab7bd258e539cb73da3975bf1ab468c437122c902dd2a3d597cfa807c67b88db0188282ca41c54c703c768d8b642243e3a1fa84810201",
  "93c2d9974c2e24525fc8fa4461bfd1b54fd8e6e56585dc6f6ff58ffb848fdbfd7c4f3a2066423f7961556e6262c888d7ccaa4868d81cd4cebc17cbd5608c50b9",
  "71e9788a68e1af20cf71c4d7cb70d960f71314bf7e925cf5195c9ff29d8e5d350c650900b29eaa098945a40bbddf3329e7f4ae3ac0798305c733e7f9d5f70c9e",
  "7bcc6ebad81cdfdf3fb120d6ebeaaaf8dc5df4c7788ed3c9aa36a63dfa846d774669d4a667c067f37443bc83f85fda86701dc2840bf75026c35db32c65296025"
]
